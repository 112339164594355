export default [
  "AIGuestChatActionSuggestion",
  "AIGuestChatMessageSuggestion",
  "AboutCuratedRequirement",
  "AbsoluteUrlRedirectPathNodeFlowViewModelSuccess",
  "AcceptCuratedTermsOfUsePayload",
  "AcceptEscalationResult",
  "AcceptLeadTransferRequestPayload",
  "AcceptProposedShiftsPayload",
  "AcceptReturningUserConnectionPayload",
  "AcceptSurgeShiftPayload",
  "AccountExistsCreateAccountPathNodeFlowViewModelError",
  "AccruedShiftPenalty",
  "AcknowledgePurchaseOrderRevisionAcknowledgeFailedError",
  "AcknowledgePurchaseOrderRevisionFeedbackExistsConflictError",
  "AcknowledgePurchaseOrderRevisionPayload",
  "ActivateExpertCuratedOrderAsBookablePayload",
  "ActivateExpertCuratedOrdersForLeadPayload",
  "ActivateExpertPayload",
  "ActivateFlightPlanPayload",
  "ActivateGigRequestPayload",
  "ActivateGuestConversationSequencePayload",
  "ActivatePartnerAccountPayload",
  "ActivatePartnerUserPayload",
  "ActivatePromotionPayload",
  "ActiveAuthorNotInWhitelistUpdateGigRequestError",
  "ActiveClassesExpertHiringCohortArchiveError",
  "ActiveConsumerCountSocialProof",
  "ActiveGigSubmissionArchiveGigRequestError",
  "AdHocFulfillmentSource",
  "AddActiveItemToCartPayload",
  "AddAddressWithValidationPayload",
  "AddAddresssPayload",
  "AddApplicationToExpertHiringClassPayload",
  "AddBlacklistChannelAddressPayload",
  "AddCampaignAudienceIntegrationPayload",
  "AddCoOwnerSubmittedMediaIdPayload",
  "AddConsumerProductReviewModerationObservationPayload",
  "AddConsumerProductReviewReactionPayload",
  "AddCuratedItemToSavedCurationPayload",
  "AddEmailTemplateDataSourcePayload",
  "AddExperimentRevisionTreatmentPayload",
  "AddExpertCuratedItemToCartPayload",
  "AddExpertCuratedItemToWishlistPayload",
  "AddExpertSellableCoRecommendationReactionPayload",
  "AddFeedReactionPayload",
  "AddGiftCardToWishlistPayload",
  "AddGiftCardWishlistItemToOrderPayload",
  "AddGigSubmissionReviewPayload",
  "AddGigUserClaimLimitOverridePayload",
  "AddGuestConversationSequenceMessagePayload",
  "AddInfluencerCuratedItemToListsPayload",
  "AddItemsToOrderBundleSelection",
  "AddItemsToOrderNotAvailableError",
  "AddItemsToOrderPayload",
  "AddJiraTicketAttachmentError",
  "AddJiraTicketAttachmentPayload",
  "AddJiraTicketCommentError",
  "AddJiraTicketCommentPayload",
  "AddMembershipToOrderPayload",
  "AddOwnItemAddGiftCardWishlistItemToOrderError",
  "AddOwnItemAddSellableWishlistItemToOrderError",
  "AddProductBundleComponentPayload",
  "AddProductBundleToWishlistPayload",
  "AddProductCustomizationFeatureGroupPayload",
  "AddProductCustomizationFeaturePayload",
  "AddPromotionToOrderPayload",
  "AddSellableExpertiseRuleMappingPayload",
  "AddSellableToWishlistPayload",
  "AddSellableWishlistItemToOrderPayload",
  "AddSupportToLeadConversationPayload",
  "AddTakeImageSellableEditorReviewRevisionPayload",
  "AddTakeImageSellableEditorialReviewRevisionNoTakeFoundError",
  "AddTemplateChannelPayload",
  "AddTemplateDataSourceReferencePayload",
  "AddTipToOrderPayload",
  "AddTooManyTrialSellablesToOrderError",
  "AddUserInfoForLeadPayload",
  "AddWarrantyToOrderPayload",
  "AdjustFeedRankingPayload",
  "AdminUpdatePurchaseOrderConfirmedQuantityPayload",
  "AdministrativeArea",
  "AffiliateAdGroupPagination",
  "AffiliateAdPagination",
  "AffiliateCampaignRevisionPagination",
  "AffiliatePagination",
  "AffiliateSourceRevisionPagination",
  "AfterStartDateExpertHiringClassAddApplicationError",
  "AggregateExpertReport",
  "AggregatedBillableTimeEntriesPagination",
  "AggregatedBillableTimeEntry",
  "AiChatNextSuggestionsResult",
  "AirportTypeaheadPayload",
  "AlreadyActiveExpertHiringClassUpdateError",
  "AlreadyAddedExpertHiringClassAddApplicationError",
  "AlreadyAlertedCancelSellableStockAlertError",
  "AlreadyArchivedAddGiftCardWishlistItemToOrderError",
  "AlreadyArchivedAddSellableWishlistItemToOrderError",
  "AlreadyArchivedExpertHiringClassArchiveError",
  "AlreadyArchivedUserNeedArchiveError",
  "AlreadyArchivedUserProfileArchiveError",
  "AlreadyClaimedGigClaimError",
  "AlreadyClaimedRewardCreditClaimError",
  "AlreadyCompletedNavigatePathGraphFlowError",
  "AlreadyCreatedCreateVendorAgreementRevisionError",
  "AlreadyCreatedProductCatalogDraftFromSellableError",
  "AlreadyCreatedProductCatalogGroupDraftFromSellableError",
  "AlreadyExistsCopyTriggerIntoTenantError",
  "AlreadyExistsCreatePartnerUserError",
  "AlreadyExistsExternalAdCampaignCategoryRuleError",
  "AlreadyExistsInitiateLeadTransferRequestError",
  "AlreadyExistsSetSellableStockAlertError",
  "AlreadyHasGiftCardAddGiftCardToWishlistError",
  "AlreadyInProgressMerchantReturnError",
  "AlreadyInProgressPartnerOrderReturnError",
  "AlreadyInProgressUpdateOrderReturnError",
  "AlreadyInStockSetSellableStockAlertError",
  "AlreadyInWishlistAddExpertCuratedItemToWishlistError",
  "AlreadyInWishlistAddProductBundleToWishlistError",
  "AlreadyInWishlistAddSellableToWishlistError",
  "AlreadyInactiveExpertHiringCohortArchiveError",
  "AlreadyMemberAddMembershipToOrderError",
  "AlreadyPaidPayGigError",
  "AlreadyPaidPublishAndPayGigError",
  "AlreadyPublishedCreateInternalOrderError",
  "AlreadyRefundedPartnerOrderReturnError",
  "AlreadyRefundedRefundOrderReturnError",
  "AlreadyRegisteredRegisterCustomerError",
  "AlreadyReviewedCreateConsumerProductReviewError",
  "AlreadyStartedStartExerciseTrackingError",
  "AlreadySubmittedApprovalRequestCreationError",
  "AlreadySubmittedApprovalRequestSubmitDraftEditError",
  "AlreadyWithdrawnWithdrawExpertApplicationError",
  "AlwaysTrueConditionDefinition",
  "AppStoreRating",
  "AppleWallet",
  "ApplicationConversationMetrics",
  "ApplicationHighPromiseScore",
  "ApplicationNotRequiredCreateGigApplicationError",
  "ApplicationRequiredGigClaimError",
  "ApplyExternalAdBudgetPayload",
  "ApplyFinishRedirectPathNodeFlowViewModelSuccess",
  "ApplyRewardCreditPromotionPostPurchasePayload",
  "ApplyStoreCreditToOrderPayload",
  "ApprovableEntityNotReadyApprovalRequestCreationError",
  "ApprovableEntitySwitchToPublishFailedApprovalRequestPublishError",
  "ApprovableEntityValidationError",
  "ApprovalEntitiesChangedApprovalRequestCreationError",
  "ApprovalErrorArchiveGigSubmissionRevisionError",
  "ApprovalRequestAuthorization",
  "ApproveApprovalRequestActivityEntryDetail",
  "ApproveApprovalRequestPayload",
  "ApproveApprovalRequestPublishAndPayGigError",
  "ApproveBillableTimeEntriesPayload",
  "ApproveConsumerProductReviewPayload",
  "ApproveErrorUnclaimGigSubmissionRevisionError",
  "ApproveExpertAccountChangeRequestPayload",
  "ApproveExpertApplicationPayload",
  "ApproveExpertCuratedItemNotePayload",
  "ApproveFailedApprovalRequestPublishError",
  "ApproveGigApplicationPayload",
  "ApproveLeadRPALExclusionOverrideRequestAlreadyApprovedError",
  "ApproveLeadRPALExclusionOverrideRequestPayload",
  "ApprovePayableActivityEntriesPayload",
  "ApprovePostPublishGigSubmissionRevisionPayload",
  "ApproveSellableQuestionCandidatePayload",
  "ArchiveAffiliateAdGroupPayload",
  "ArchiveAffiliateAdPayload",
  "ArchiveAffiliateCampaignRevisionPayload",
  "ArchiveAffiliateSourceRevisionPayload",
  "ArchiveArticlePayload",
  "ArchiveArticleRevisionPayload",
  "ArchiveBrandProfileRevisionPayload",
  "ArchiveCampaignAudiencePayload",
  "ArchiveCategoryAssetRevisionPayload",
  "ArchiveCategoryAttributeChangeRevisionPayload",
  "ArchiveCategoryChangeRevisionPayload",
  "ArchiveCategoryExpertPerformanceSettingRevisionPayload",
  "ArchiveCategoryNavigationGroupRevisionPayload",
  "ArchiveCategoryNavigationRevisionPayload",
  "ArchiveContentSelectionRuleRevisionPayload",
  "ArchiveContentSelectionToolRevisionPayload",
  "ArchiveConversationStarterSetRevisionPayload",
  "ArchiveDraftVendorAgreementRevisionPayload",
  "ArchiveEmailTemplateRevisionPayload",
  "ArchiveErrorRejectGigSubmissionRevisionError",
  "ArchiveErrorUnclaimGigSubmissionRevisionError",
  "ArchiveExperimentMetricRevisionPayload",
  "ArchiveExperimentRevisionPayload",
  "ArchiveExpertHiringClassPayload",
  "ArchiveExpertHiringCohortPayload",
  "ArchiveExpertLeadCampaignAlreadyArchivedError",
  "ArchiveExpertLeadCampaignArchiveEmailFailedError",
  "ArchiveExpertLeadCampaignPayload",
  "ArchiveFailedApprovalRequestCreationError",
  "ArchiveFedExBillRevisionInvalidStateError",
  "ArchiveFedExBillRevisionMustArchiveThroughApprovalError",
  "ArchiveFedExBillRevisionPayload",
  "ArchiveFedExRateCardRevisionMustArchiveThroughApprovalError",
  "ArchiveFedExRateCardRevisionPayload",
  "ArchiveFedExSurchargeRevisionMustArchiveThroughApprovalError",
  "ArchiveFedExSurchargeRevisionPayload",
  "ArchiveFedExZoneLocatorRevisionMustArchiveThroughApprovalError",
  "ArchiveFedExZoneLocatorRevisionPayload",
  "ArchiveGigContentErrorArchiveGigSubmissionRevisionError",
  "ArchiveGigRequestPayload",
  "ArchiveGigSubmissionRevisionPayload",
  "ArchiveInfluencerCuratedListPayload",
  "ArchiveMilestonePayPlanRevisionPayload",
  "ArchivePageTemplateRevisionPayload",
  "ArchivePageTemplateRevisionUsagesPresentError",
  "ArchivePathGraphContainerPayload",
  "ArchiveProductBundleRevisionPayload",
  "ArchiveProductCatalogExportConfigRevisionPayload",
  "ArchiveProductCatalogGroupRevisionPayload",
  "ArchiveProductCatalogPricingScheduleLinePayload",
  "ArchiveProductCatalogRevisionPayload",
  "ArchiveProductCatalogTemplateRevisionPayload",
  "ArchiveProductCustomizationRevisionPayload",
  "ArchiveProductQuestionPayload",
  "ArchivePromotionSelectionRuleRevisionPayload",
  "ArchivePromptTemplateRevisionPayload",
  "ArchivePublisherRevisionPayload",
  "ArchivePurchaseRequestRevisionMustArchiveThroughApprovalError",
  "ArchivePurchaseRequestRevisionPayload",
  "ArchivePurchaseRequestRevisionPublishedCannotBeArchivedError",
  "ArchiveRewardCreditGrantTemplateRevisionPayload",
  "ArchiveSearchQueryRewriteRulePayload",
  "ArchiveSellableEditorReviewRevisionPayload",
  "ArchiveSellableExpertOwnerReviewRevisionPayload",
  "ArchiveSellableExpertiseRequestPayload",
  "ArchiveSellableExpertiseRulePayload",
  "ArchiveSellableSearchRuleRevisionPayload",
  "ArchiveSellableSearchSitemapRuleRevisionPayload",
  "ArchiveSuggestedActionRevisionPayload",
  "ArchiveSuggestedActionSystemActionNotAllowedError",
  "ArchiveSuggestedMessageRevisionPayload",
  "ArchiveTemplateDataSourceRevisionPayload",
  "ArchiveTemplateFragmentPayload",
  "ArchiveTemplatePayload",
  "ArchiveTenantRevisionPayload",
  "ArchiveTextLinkifyRulePayload",
  "ArchiveTriggerPayload",
  "ArchiveUpsRateCardRevisionMustArchiveThroughApprovalError",
  "ArchiveUpsRateCardRevisionPayload",
  "ArchiveUpsSurchargeRevisionMustArchiveThroughApprovalError",
  "ArchiveUpsSurchargeRevisionPayload",
  "ArchiveUpsZoneLocatorRevisionMustArchiveThroughApprovalError",
  "ArchiveUpsZoneLocatorRevisionPayload",
  "ArchiveUserNeedsPayload",
  "ArchiveVideoContentRevisionPayload",
  "ArchiveVirtualCategoryChildNodesHaveActiveError",
  "ArchiveVirtualCategoryPayload",
  "ArchiveVirtualCategoryTreeRevisionPayload",
  "ArchiveWishlistItemPayload",
  "ArchiveWishlistPayload",
  "ArchivedExpertHiringClassCloseError",
  "ArchivedExpertHiringClassOpenError",
  "ArchivedExpertHiringClassUpdateError",
  "ArchivedProductCatalogGroupPublishError",
  "ArchivedProductCatalogPublishError",
  "ArchivedUserNeedCopyError",
  "ArchivedUserNeedEditNoteError",
  "ArticleApplicationDetail",
  "ArticleDynamicContentBlockConfig",
  "ArticleInlinePathSelections",
  "ArticleMedia",
  "ArticleNavigationLink",
  "ArticlePerformanceStatsArticleAggregate",
  "ArticlePerformanceStatsArticleAggregatePagination",
  "ArticlePerformanceStatsAuthorAggregate",
  "ArticlePerformanceStatsAuthorAggregatePagination",
  "ArticlePerformanceStatsPagination",
  "ArticleQueryMappingRule",
  "ArticleRequestDetail",
  "ArticleSearchConnection",
  "ArticleSearchEdge",
  "ArticleSearchPaginationResult",
  "ArticleSubmissionRevisionDetail",
  "ArticleVersusMedia",
  "ArticleVisitorsTrending",
  "ArticleVisitorsTrendingNode",
  "AskQuestionRequirement",
  "AssignSupportTaskPayload",
  "AssumeBusinessUserIdentityPayload",
  "AssumeConsumerIdentityPayload",
  "AssumeOperatorIdentityPayload",
  "AssumePartnerUserIdentityPayload",
  "AttentionNeededLeadChanged",
  "AttentionNeededLeadCountChanged",
  "Attribute",
  "AttributeEnumEntry",
  "AttributeEnumEntryDeprecated",
  "AttributeEnumEntryDoesNotExistExpertCuratedItemProsAndConsEditError",
  "AttributeName",
  "AttributeSchemaRevisionDeprecated",
  "AttributeSchemaRevisionPublished",
  "AttributeSchemaRevisionsPagination",
  "AttributeSchemaValidator",
  "AttributeValidationSubmitEditCategoryActionError",
  "AttributeValueDate",
  "AttributeValueDateRange",
  "AttributeValueDateTime",
  "AttributeValueDateTimeRange",
  "AttributeValueDecimal",
  "AttributeValueDecimalRange",
  "AttributeValueEnumeration",
  "AttributeValueInteger",
  "AttributeValueIntegerRange",
  "AttributeValueText",
  "AttributeValueTime",
  "AttributeValueTimeRange",
  "AttributeValueZoneId",
  "AuditStamp",
  "AuthenticateByExternalOAuthTokenPayload",
  "AuthenticateByLoginTokenPayload",
  "AuthenticationResultPayload",
  "AuthenticationSessionState",
  "AuthorPaidActivity",
  "AuthorQualificationRequestDetail",
  "AuthorQualificationSubmissionRevisionDetail",
  "AutoApprovalNotSupportedPublishAndPayGigError",
  "AutoCurationExplanations",
  "AutoCurationModelAttributeWeightDebug",
  "AutoCurationWithExplanation",
  "AvailabilityByFulfillmentCenter",
  "AvailableFinalEvalShiftSlotDailyView",
  "BackgroundFacetImageMetadata",
  "BacktrackingNotAllowedNavigatePathGraphFlowError",
  "BankCheckPaymentEntity",
  "BatchConfirmPurchaseOrderPayload",
  "BatchCreatePurchaseOrdersPayload",
  "BatchUpsertCategoryInventoryPropertiesPayload",
  "BatchUpsertProductInventoryPropertiesPayload",
  "BigDecimalRange",
  "Bill",
  "BillableTimeEntriesPagination",
  "BillingContact",
  "BlockAddressPayload",
  "BranchContainerPathNodeConfig",
  "BranchFallbackRulePathNodeConfig",
  "BranchRulePathNodeConfig",
  "BrandConnection",
  "BrandEdge",
  "BrandFulfillmentChannelTypeVisibility",
  "BrandTypeaheadPayload",
  "BrandUploadTaskPayload",
  "BrandsPagination",
  "BridgePhoneCallPayload",
  "BrowseCategoryPageViewModel",
  "BsonObject",
  "BucketedDisplayFacet",
  "BucketedRangeDisplayFacet",
  "BulkApproveFlxpointOrderInvoiceError",
  "BulkApproveFlxpointOrderInvoicePayload",
  "BulkApprovePartnerOrderCreditMemoError",
  "BulkApprovePartnerOrderCreditMemoPayload",
  "BulkApprovePartnerOrderInvoiceError",
  "BulkApprovePartnerOrderInvoicePayload",
  "BulkArchiveGigRequestPayload",
  "BulkArchiveProductCatalogGroupRevisionError",
  "BulkArchiveProductCatalogGroupRevisionPayload",
  "BulkArchiveProductCatalogPricingScheduleLinesPayload",
  "BulkArchiveProductCatalogRevisionError",
  "BulkArchiveProductCatalogRevisionPayload",
  "BulkAssignGigSubmissionsPayload",
  "BulkCopyProductCatalogTemplateRevisionsPayload",
  "BulkCreateDraftProductCatalogGroupRevisionForGroupPayload",
  "BulkCreateDraftProductCatalogRevisionForCatalogPayload",
  "BulkCreateExpertTestedProductPayload",
  "BulkCreateExpertWishListProductPayload",
  "BulkGradeSampleFreeFormResponsePayload",
  "BulkGradeSampleFreeFormResponseTaskPayload",
  "BulkPayGigPayloadError",
  "BulkPayGigsPayload",
  "BulkPostponeLeadSnoozePayload",
  "BulkPublishExpertTestedProductError",
  "BulkPublishExpertTestedProductPayload",
  "BulkRemoveProductCatalogAttributeError",
  "BulkRemoveProductCatalogAttributePayload",
  "BulkRemoveProductCatalogGroupAttributeError",
  "BulkRemoveProductCatalogGroupAttributePayload",
  "BulkRemoveProductCatalogGroupRawAttributesError",
  "BulkRemoveProductCatalogGroupRawAttributesPayload",
  "BulkRemoveProductCatalogRawAttributesError",
  "BulkRemoveProductCatalogRawAttributesPayload",
  "BulkRemoveSellableAttributePayload",
  "BulkRemoveSellableRawAttributePayload",
  "BulkReorderProductCatalogGroupRawAttributesError",
  "BulkReorderProductCatalogGroupRawAttributesPayload",
  "BulkReorderProductCatalogRawAttributesError",
  "BulkReorderProductCatalogRawAttributesPayload",
  "BulkSendPayoutPayload",
  "BulkSetLeadSnoozePayload",
  "BulkSetProductCatalogAttributeError",
  "BulkSetProductCatalogAttributePayload",
  "BulkSetProductCatalogGroupAttributeError",
  "BulkSetProductCatalogGroupAttributePayload",
  "BulkSetProductCatalogGroupRawAttributeError",
  "BulkSetProductCatalogGroupRawAttributePayload",
  "BulkSetProductCatalogRawAttributeError",
  "BulkSetProductCatalogRawAttributePayload",
  "BulkSetSellableAttributePayload",
  "BulkSetSellableRawAttributePayload",
  "BulkSilentRejectPendingApplicationsPayload",
  "BulkSubmitForReviewInApprovalRequestPayload",
  "BulkUnassignGigSubmissionsPayload",
  "BulkUpdateConnectPartnerTransactionPayload",
  "BulkUpdateProductCatalogDraftError",
  "BulkUpdateProductCatalogDraftPayload",
  "BulkUpdateProductCatalogGroupDraftError",
  "BulkUpdateProductCatalogGroupDraftPayload",
  "BulkUpdatePromotionCodeForContentSelectionRulesPayload",
  "BulkUpdatePublisherPayLineItemsPayload",
  "BulkUpdateSellablePayload",
  "BulkUpdateTicketsPayload",
  "BundleSelectionNotAvailableAddProductBundleToWishlistError",
  "BusinessUserActivityConnection",
  "BusinessUserActivityEdge",
  "BusinessUserExpertAttributes",
  "BusinessUserTeamMembersPaginated",
  "BusinessUserTeamsPagination",
  "ByolPurchaseCoOwnerPointsSource",
  "COGSError",
  "CalculateActionPathNodeConfig",
  "CalculateFedExBillLineItemsTaskError",
  "CalculateFedExBillLineItemsTaskPayload",
  "CalculateFedExRateInvalidZipCodeNumberError",
  "CalculateFedExRateInvalidZoneError",
  "CalculateFedExRateNoZoneFoundError",
  "CalculateFedExRatePayload",
  "CalculateFedExRateResult",
  "CalculateSimilarityScorePayload",
  "CalculateUpsRateInvalidZipCodeNumberError",
  "CalculateUpsRateNoZoneFoundError",
  "CalculateUpsRatePayload",
  "CalculateUpsRateResult",
  "CalculationResultPathNodeConfig",
  "CalculationResultPathNodeFlowViewModelItem",
  "CallActivityConnection",
  "CallActivityEdge",
  "CampaignAudiencePagination",
  "CampaignAudienceUploadTaskPayload",
  "CampaignConnection",
  "CampaignDeliveryRequest",
  "CampaignDeliveryResult",
  "CampaignEdge",
  "CampaignExpertUpdateDetail",
  "CampaignTaskError",
  "CampaignsPagination",
  "CancelHollingsworthOrderAlreadyCancelledError",
  "CancelHollingsworthOrderAlreadyShippedError",
  "CancelHollingsworthOrderInsufficientQuantityError",
  "CancelHollingsworthOrderInvalidLineItemError",
  "CancelHollingsworthOrderNotFoundError",
  "CancelHollingsworthOrderPayload",
  "CancelHollingsworthOutboundOrderLineItemPayload",
  "CancelLeadRPALExclusionOverrideRequestNotSubmittedError",
  "CancelLeadRPALExclusionOverrideRequestPayload",
  "CancelPaymentRequestForOrderPayload",
  "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedByAdminError",
  "CancelPurchaseOrderRevisionConfirmedCannotBeArchivedError",
  "CancelPurchaseOrderRevisionMustArchiveThroughApprovalError",
  "CancelPurchaseOrderRevisionPayload",
  "CancelPurchaseOrderRevisionUnableToCancelError",
  "CancelScheduledExpertLeadCampaignCampaignNotExistError",
  "CancelScheduledExpertLeadCampaignCancelFailedError",
  "CancelScheduledExpertLeadCampaignPayload",
  "CancelSellableStockAlertPayload",
  "CancelSubmittedOrderPayload",
  "CancelWarehouseOrderAlreadyCancelledError",
  "CancelWarehouseOrderAlreadyShippedError",
  "CancelWarehouseOrderHttpError",
  "CancelWarehouseOrderNotExistWarehouseOrderError",
  "CancelWarehouseOrderNotWare2GoOrderError",
  "CancelWarehouseOrderPayload",
  "CancellationTicket",
  "CatalogNotSupportedPublishGigContentError",
  "CategoriesConnection",
  "CategoriesEdge",
  "CategoryAndDepartmentForLineItem",
  "CategoryApplicationCount",
  "CategoryAssetImage",
  "CategoryAssetImageList",
  "CategoryAssetMarkdown",
  "CategoryAssetNoun",
  "CategoryAssetPlainText",
  "CategoryAssetRevisionPagination",
  "CategoryAttributeChangeRevisionsPayload",
  "CategoryAttributeSchema",
  "CategoryChangeExecutionResult",
  "CategoryChangeNotInDraftStateSubmitEditCategoryActionError",
  "CategoryChangeRevisionConnection",
  "CategoryChangeRevisionEdge",
  "CategoryCommissionRule",
  "CategoryContentAssets",
  "CategoryCorrelatedSellable",
  "CategoryExpertPerformanceSettingDraftExistsError",
  "CategoryExpertPerformanceSettingPublishNotReadyError",
  "CategoryExpertPerformanceSettingUpdateNotInDraftError",
  "CategoryHiringGoal",
  "CategoryInUseSubmitDeactivateCategoryActionError",
  "CategoryMappingRule",
  "CategoryMarginOverrideExistsError",
  "CategoryNavigationAssets",
  "CategoryNavigationDecoratedGroup",
  "CategoryNotAllowedExpertHiringClassAddApplicationError",
  "CategoryPageMetadata",
  "CategoryQueryMappingRule",
  "CategorySellablesPagination",
  "CategorySubCategoryTileViewModel",
  "CategoryTargetMarginExpiredError",
  "CategoryTargetMarginInvalidEffectiveTimeError",
  "CategoryTargetMarginPagination",
  "CategoryTargetMarginPayload",
  "CategoryTypeaheadInEditModePayload",
  "CategoryTypeaheadPayload",
  "CategoryTypeaheadResultInEditMode",
  "ChangeCategoryAttributeAction",
  "ChangeIntoTestedProductAlreadyExistsError",
  "ChangeIntoTestedProductCreateFailedError",
  "ChangeIntoTestedProductInactiveStateError",
  "ChangeIntoTestedProductPayload",
  "ChangePasswordPayload",
  "ChangingProductIdentifierProductCatalogPublishError",
  "ChannelAddress",
  "ChargeFailureGiftTipStoreCreditError",
  "ChatCompletionRequest",
  "CheckExerciseStatusPayload",
  "ClaimConsumerReferralUserSourcePayload",
  "ClaimExpertReferralUserSourcePayload",
  "ClaimOrderLineItemPayload",
  "ClaimPromotionPayload",
  "ClaimRequestApproveGigApplicationError",
  "ClaimRequestSubmitGigApplicationError",
  "ClaimRewardCreditFailureClaimConsumerReferralRewardCreditError",
  "ClaimSocialShareCreditPayload",
  "ClaimedGigTypesOfSameProductError",
  "ClaimedSameProductQuickReviewBeforeError",
  "ClearanceItemCreateOrderReturnError",
  "CloseAccountPayload",
  "CloseApprovalRequestActivityEntryDetail",
  "CloseApprovalRequestPayload",
  "CloseExpertCuratedOrderPayload",
  "CloseExpertHiringClassPayload",
  "ClosePurchaseOrderRevisionReasonWithDisplayName",
  "CoOwnerExpertPerformanceAverageRating",
  "CoOwnerExpertPerformanceRating",
  "CoOwnerGrantsPagination",
  "CoOwnerMilestoneAchievement",
  "CoOwnerPointsAccountsPagination",
  "CoOwnerPointsEntriesPagination",
  "CoOwnerPointsSourceSummary",
  "CoOwnerQualificationCriteria",
  "CoOwnerQualificationCriteriaBased",
  "CoOwnerQualificationManualOverride",
  "CoOwnerQualityConversation",
  "CoOwnerVestingEvaluation",
  "CoOwnerVestingQualification",
  "CodeExperimentUsage",
  "CommTemplateDataModelPayload",
  "CommTemplateDirective",
  "CommTemplateExperimentUsage",
  "CommentApprovalRequestActivityEntryDetail",
  "CommentOnApprovalRequestPayload",
  "CommissionEligibilityOverride",
  "CommissionImpact",
  "CommissionImpactBadge",
  "CommonFieldsQuestionPathNodeFlowViewModel",
  "CompleteGigConfigDetail",
  "CompleteLeadSupportTaskCheckForSpamPayload",
  "CompleteLeadSupportTaskDelegateLeadPayload",
  "CompleteLeadSupportTaskEscalateForReassignmentPayload",
  "CompleteLeadSupportTaskEscalateToTeamForDelegationPayload",
  "CompleteLeadSupportTaskReassignLeadPayload",
  "CompleteLeadSupportTaskRequestOrderSupportPayload",
  "CompleteSupportTaskNoActionNeededPayload",
  "CompleteSupportTaskSnoozePayload",
  "CompleteTrainingConfigDetail",
  "CompleteTrainingLessonConfigDetail",
  "CompleteTrainingStatusDetail",
  "CompletelyPurchasedAddSellableWishlistItemToOrderError",
  "ConditionPathNodeEditorViewModel",
  "ConfigDomain",
  "ConfirmBudgetRequirement",
  "ConfirmOrderCancellationPayload",
  "ConfirmOrderPriceChangePayload",
  "ConfirmPurchaseOrderRevisionFailedError",
  "ConfirmPurchaseOrderRevisionPayload",
  "ConfirmShiftProbationsPayload",
  "ConfirmedReturnShippingLabelSentActivity",
  "ConflictingProductIdentifierProductCatalogPublishError",
  "ConflictingSkuProductCatalogGroupPublishError",
  "ConnectExpertContribution",
  "ConnectPartnerTransactionCreationErrorAlreadyExists",
  "ConnectPartnerTransactionCreationErrorConsumerMismatch",
  "ConnectPartnerTransactionCreationErrorNoConsumer",
  "ConnectPartnerTransactionCreationErrorNoLead",
  "ConnectPartnerTransactionCreationErrorUnknownBrand",
  "ConnectPartnerTransactionPagination",
  "ConnectPartnerTransactionRefundUploadTaskPayload",
  "ConnectPartnerTransactionRefundUploadTaskRowError",
  "ConnectPartnerTransactionUploadTaskPayload",
  "ConnectPartnerTransactionUploadTaskRowError",
  "ConnectSourcePageLLMClassifiedResult",
  "ConsignmentOrderMeta",
  "ConsumerContactPreferenceForLead",
  "ConsumerExpertReviewConnection",
  "ConsumerExpertReviewEdge",
  "ConsumerFeedbackOnCuration",
  "ConsumerFeedbackOnCurationRichCard",
  "ConsumerProductReviewConnection",
  "ConsumerProductReviewEdge",
  "ConsumerProductReviewLMSummary",
  "ConsumerProductReviewLMSummaryGenerateResult",
  "ConsumerProductReviewLMSummaryThemeEntry",
  "ConsumerProductReviewLMThemeSentiment",
  "ConsumerProductReviewLMThemeSentimentEntry",
  "ConsumerProductReviewLMThemeSentimentGenerateResult",
  "ConsumerProductReviewModerationObservation",
  "ConsumerProductReviewReactionSummary",
  "ConsumerProductReviewReactionTypeAndCount",
  "ConsumerProductReviewSourceCurated",
  "ConsumerProductReviewSourceImported",
  "ConsumerProductReviewTarget",
  "ConsumerProductReviewTargetsPayload",
  "ConsumerProductReviewsPagination",
  "ConsumerRefundTicket",
  "ConsumerReviewStats",
  "ConsumerSearchResultArticle",
  "ConsumerSearchResultCategoryPage",
  "ConsumerSearchResultRedirect",
  "ConsumerSearchResultSellables",
  "ConsumerSellableReactionConnection",
  "ConsumerSellableReactionEdge",
  "ConsumerSellableReactionRatingStats",
  "ConsumerSellableReactionRatingWithDisplayName",
  "ConsumerSellableReactionReasonWithDisplayName",
  "ConsumerStoreCreditTransferPayload",
  "ConsumerUrlRedirectPathNodeFlowViewModelSuccess",
  "ConsumerWarningAttributeSchemaMetadata",
  "ContactCollectionRichCard",
  "ContentBooleanValue",
  "ContentCoOwnerPointsSource",
  "ContentFloatValue",
  "ContentIntValue",
  "ContentObjectEntry",
  "ContentRejectedCreateFeedItemError",
  "ContentRejectedUpdateFeedContentBlocksError",
  "ContentSelectionEvaluation",
  "ContentSelectionRequestV2",
  "ContentSelectionResult",
  "ContentSelectionResultV2",
  "ContentSelectionRevisionValidationError",
  "ContentSelectionRuleExperimentUsage",
  "ContentSelectionRuleInvalidPathError",
  "ContentSelectionRuleInvalidPromotionError",
  "ContentSelectionRuleInvalidRegExError",
  "ContentSelectionRulePreviewResult",
  "ContentSelectionRuleRevisionConnection",
  "ContentSelectionRuleRevisionEdge",
  "ContentSelectionRuleRevisionExperimentUsage",
  "ContentSelectionRuleRevisionPageTemplateUsage",
  "ContentSelectionRulesPagination",
  "ContentSelectionTemplate",
  "ContentSelectionToolRevisionPageTemplateUsage",
  "ContentSelectionToolRevisionPagination",
  "ContentSpace",
  "ContentStringValue",
  "ContentValueArray",
  "ContentfulTemplate",
  "ContinueButtonInterstitialProgressionPathNodeFlowViewModel",
  "ContinueButtonPathInterstitialProgressionSchema",
  "ConversationMessagePart",
  "ConversationMetrics",
  "ConversationStarter",
  "ConversationStarterRichCard",
  "ConversationStarterSetRevisionPagination",
  "ConversationStarters",
  "ConversationSummary",
  "CopyArticleRevisionAsDraftPayload",
  "CopyAsDraftExperimentMetricRevisionPayload",
  "CopyAsDraftExperimentRevisionPayload",
  "CopyAsDraftMilestonePayPlanRevisionPayload",
  "CopyAsDraftProductBundleRevisionPayload",
  "CopyAsDraftProductCatalogExportConfigRevisionPayload",
  "CopyAsDraftProductCustomizationRevisionPayload",
  "CopyAsDraftPromptTemplateRevisionPayload",
  "CopyAsDraftPublisherRevisionDraftExistsError",
  "CopyAsDraftPublisherRevisionPayload",
  "CopyAsDraftRevenueBonusPlanPayload",
  "CopyAsDraftSearchQueryRewriteRulePayload",
  "CopyAsDraftSellableEditorialReviewRevisionCreateFailedError",
  "CopyAsDraftSellableEditorialReviewRevisionNotAllowedToCopyFromDraftError",
  "CopyAsDraftSellableEditorialReviewRevisionPayload",
  "CopyAsDraftSellableEditorialTopListRevisionPayload",
  "CopyAsDraftSellableExpertOwnerReviewRevisionPayload",
  "CopyAsDraftSuggestedActionRevisionPayload",
  "CopyAsDraftSuggestedMessageRevisionPayload",
  "CopyAsDraftTenantRevisionDraftExistsError",
  "CopyAsDraftTenantRevisionPayload",
  "CopyAsNextTemplateDataSourceRevisionPayload",
  "CopyAttributeSchemaRevisionAsNewVersionPayload",
  "CopyBrandProfileRevisionAsNextRevisionPayload",
  "CopyCategoryAssetRevisionAsNextRevisionPayload",
  "CopyCategoryExpertPerformanceSettingRevisionAsDraftPayload",
  "CopyCategoryNavigationAsNewRevisionPayload",
  "CopyCategoryNavigationGroupAsNewRevisionPayload",
  "CopyFlightPlanAsDraftPayload",
  "CopyFromDraftNotAllowedProductCatalogCopyRevisionAsDraftError",
  "CopyFromDraftNotAllowedProductCatalogGroupCopyRevisionAsDraftError",
  "CopyFromExpertCuratedItemsPayload",
  "CopyFromSavedExpertCuratedItemsPayload",
  "CopyGigApplicationAsDraftPayload",
  "CopyGigSubmissionAsNewRevisionPayload",
  "CopyInfluencerCuratedListAsDraftPayload",
  "CopyItemsFromExpertCuratedOrderPayload",
  "CopyProductCatalogExportConfigDraftExistsError",
  "CopyProductCatalogGroupRevisionAsDraftPayload",
  "CopyProductCatalogRevisionAsDraftPayload",
  "CopyProductCatalogTemplateDraftExistsError",
  "CopyProductCatalogTemplateRevisionAsNewDraftPayload",
  "CopyPromotionPayload",
  "CopyRewardCreditGrantTemplateRevisionPayload",
  "CopySchedulingAvailabilityToNextPeriodPayload",
  "CopySchedulingDemandForecastPayload",
  "CopySchedulingUserAvailabilityToNextPeriodInvalidTimeRangeError",
  "CopySchedulingUserAvailabilityToNextPeriodNoShiftDemandsError",
  "CopySchedulingUserAvailabilityToNextPeriodResult",
  "CopySellableExpertiseRuleAsDraftPayload",
  "CopyTemplateAsNewInstancePayload",
  "CopyTemplateAsNextRevisionPayload",
  "CopyTemplateFragmentAsNewInstancePayload",
  "CopyTemplateFragmentAsNextRevisionPayload",
  "CopyTemplateTypePayload",
  "CopyTriggerAsNewInstancePayload",
  "CopyTriggerAsNextRevisionPayload",
  "CopyTriggerIntoTenantPayload",
  "CopyUserNeedPayload",
  "CopyVendorAgreementRevisionPayload",
  "CopyVideoContentRevisionAsNextRevisionPayload",
  "CostChangeReturnFeeInfo",
  "CostNetDown",
  "CostNetDownUsage",
  "CostResult",
  "Country",
  "CreateAccountActionPathNodeConfig",
  "CreateAdhocSellableFromEBayItemPayload",
  "CreateAffiliateAdAdGroupNotPublishError",
  "CreateAffiliateAdGroupCampaignNotPublishError",
  "CreateAffiliateAdGroupLengthExceedsLimitError",
  "CreateAffiliateAdGroupPayload",
  "CreateAffiliateAdLengthExceedsLimitError",
  "CreateAffiliateAdPayload",
  "CreateAffiliateCampaignRevisionDraftExistsError",
  "CreateAffiliateCampaignRevisionLengthExceedsLimitError",
  "CreateAffiliateCampaignRevisionPayload",
  "CreateAffiliateCampaignRevisionSourceNotPublishError",
  "CreateAffiliateLengthExceedsLimitError",
  "CreateAffiliatePayload",
  "CreateAffiliateSlugExistsError",
  "CreateAffiliateSourceRevisionAlreadyExistsError",
  "CreateAffiliateSourceRevisionDraftExistsError",
  "CreateAffiliateSourceRevisionPayload",
  "CreateAsPreMadePageTemplateRevisionFailedError",
  "CreateAsPreMadePageTemplateRevisionInvalidStateError",
  "CreateAttributeSchemaRevisionPayload",
  "CreateBillableTimeEntriesPayload",
  "CreateBlankExpertApplicationPayload",
  "CreateBrandPayload",
  "CreateBusinessAccountPayload",
  "CreateBusinessUserPayload",
  "CreateBusinessUserTeamPayload",
  "CreateCampaignAudiencePayload",
  "CreateCategoryAssetRevisionPayload",
  "CreateCategoryAttributeChangeRevisionDraftExistsError",
  "CreateCategoryAttributeChangeRevisionHasOtherUncompletedChangeError",
  "CreateCategoryAttributeChangeRevisionPayload",
  "CreateCategoryChangeRevisionDraftExistsError",
  "CreateCategoryChangeRevisionHasOtherUncompletedChangeError",
  "CreateCategoryChangeRevisionPayload",
  "CreateCategoryExpertPerformanceSettingDraftPayload",
  "CreateCategoryMarginOverridePayload",
  "CreateCategoryNavigationGroupRevisionPayload",
  "CreateCategoryNavigationRevisionPayload",
  "CreateCategoryNavigationSectionItemPayload",
  "CreateCategoryNavigationSectionPayload",
  "CreateCategoryRequest",
  "CreateConsumerExpertReviewActionPathNodeConfig",
  "CreateConsumerProductReviewPayload",
  "CreateContentSelectionRevisionDraftExistError",
  "CreateContentSelectionRevisionInvalidPathError",
  "CreateContentSelectionRulePayload",
  "CreateContentSelectionRuleRevisionPayload",
  "CreateContentSelectionToolRevisionComplexExperiment",
  "CreateContentSelectionToolRevisionCreateExperimentFailure",
  "CreateContentSelectionToolRevisionDraftExistError",
  "CreateContentSelectionToolRevisionPayload",
  "CreateContentSelectionToolRevisionUpdateExperimentFailure",
  "CreateConversationStarterSetRevisionDraftExistError",
  "CreateConversationStarterSetRevisionPayload",
  "CreateCuratedDistributionCenterDuplicatedCodeError",
  "CreateCuratedDistributionCenterPayload",
  "CreateCuratedGiftCardOrderPayload",
  "CreateCuratedMembershipOrderPayload",
  "CreateDealRequestPayload",
  "CreateDeepDiveGigRequestPayload",
  "CreateDemoProductRequestGenericError",
  "CreateDemoProductRequestPayload",
  "CreateDraftGuestConversationSequencePayload",
  "CreateDraftProductCatalogGroupRevisionForGroupPayload",
  "CreateDraftProductCatalogGroupRevisionFromSellablePayload",
  "CreateDraftProductCatalogRevisionForCatalogPayload",
  "CreateDraftProductCatalogRevisionFromSellablePayload",
  "CreateDraftPromotionPayload",
  "CreateEmailTemplateRevisionDraftExistsError",
  "CreateEmailTemplateRevisionPayload",
  "CreateExperimentMetricRevisionPayload",
  "CreateExperimentRevisionPayload",
  "CreateExpertAdvancementRulePayload",
  "CreateExpertAffiliateOutreachPayload",
  "CreateExpertApplicationActionPathNodeConfig",
  "CreateExpertApplicationDraftActionPathNodeConfig",
  "CreateExpertApplicationReferralPayload",
  "CreateExpertBulkUpdateTaskPayload",
  "CreateExpertConsultationFeeOrderPayload",
  "CreateExpertCuratedItemNoteFromSellablePayload",
  "CreateExpertCuratedItemPayload",
  "CreateExpertCuratedOrderPayload",
  "CreateExpertEmailTemplatePayload",
  "CreateExpertHiringCohortPayload",
  "CreateExpertKnowledgeTestActionPathNodeConfig",
  "CreateExpertLeadCampaignCreateEmailFailureError",
  "CreateExpertLeadCampaignPayload",
  "CreateExpertPersonnelNotePayload",
  "CreateExpertProfilePayload",
  "CreateExpertSavedLeadSearchPayload",
  "CreateExpertTagPayload",
  "CreateExpertTestedProductCreateFailedError",
  "CreateExpertTestedProductSellableDuplicatedError",
  "CreateExpertVideoRecordingPayload",
  "CreateExpertWishListProductCreateFailedError",
  "CreateExpertWishListProductSellableDuplicatedError",
  "CreateExternalAdCampaignCategoryRulePayload",
  "CreateFedExBillRevisionDraftExistsError",
  "CreateFedExBillRevisionDuplicatedDistributionCenterCodeError",
  "CreateFedExBillRevisionInvalidStateError",
  "CreateFedExBillRevisionPayload",
  "CreateFedExRateCardRevisionDraftExistsError",
  "CreateFedExRateCardRevisionDuplicatedServiceTypeError",
  "CreateFedExRateCardRevisionPayload",
  "CreateFedExSurchargeRevisionDraftExistsError",
  "CreateFedExSurchargeRevisionDuplicatedSurchargeTypeError",
  "CreateFedExSurchargeRevisionPayload",
  "CreateFedExZoneLocatorRevisionDraftExistsError",
  "CreateFedExZoneLocatorRevisionPayload",
  "CreateFeedCommentPayload",
  "CreateFeedPostActionPathNodeConfig",
  "CreateFeedPostActionPathNodeConfigProductRecommendationsPostType",
  "CreateFeedPostPayload",
  "CreateFirebaseTokenPayload",
  "CreateFlightPlanPayload",
  "CreateFulfillmentChannelPayload",
  "CreateFulfillmentEmburseCardPayload",
  "CreateFulfillmentShipmentMethodPayload",
  "CreateGiftCardOrderPayload",
  "CreateGigApplicationPayload",
  "CreateGigRequestPayload",
  "CreateGigSubmissionRevisionNoAvailableGigRequestError",
  "CreateGigSubmissionRevisionPayload",
  "CreateGooglePassPayload",
  "CreateGroupSalesQuoteRequestEmailAddressInvalidError",
  "CreateGroupSalesQuoteRequestPayload",
  "CreateInfluencerProfilePayload",
  "CreateInternalOrderPayload",
  "CreateInventoryAdjustmentDuplicatedError",
  "CreateInventoryAdjustmentHollingsworthNotSupportManualCreateError",
  "CreateInventoryAdjustmentInvalidAdjustedAtError",
  "CreateInventoryAdjustmentInvalidAdjustedQuantityError",
  "CreateInventoryAdjustmentInvalidDistributionCenterError",
  "CreateInventoryAdjustmentInvalidReturnQuantityError",
  "CreateInventoryAdjustmentInventoryItemNotExistsError",
  "CreateInventoryAdjustmentNotSupportedReasonTypeError",
  "CreateInventoryAdjustmentPayload",
  "CreateJiraTicketError",
  "CreateJiraTicketPayload",
  "CreateLeadConversationStarterActivityPayload",
  "CreateLeadNotePayload",
  "CreateLeadRPALExclusionOverrideRequestAlreadyExistsError",
  "CreateLeadRPALExclusionOverrideRequestPayload",
  "CreateLeadRPALExclusionOverrideRequestTooManyRejectionsError",
  "CreateLeadSurgePayload",
  "CreateLeadTrainingScenarioPayload",
  "CreateMediaAssetPayload",
  "CreateMediaPayload",
  "CreateMerchantOrderReturnPayload",
  "CreateMerchantPayload",
  "CreateMerchantRefundPayload",
  "CreateMerchantsPayload",
  "CreateNewDraftArticlePayload",
  "CreateNewDraftBrandProfileRevisionPayload",
  "CreateNewDraftInfluencerCuratedListPayload",
  "CreateNewDraftRevisionForArticlePayload",
  "CreateNewDraftRewardCreditGrantTemplateRevisionPayload",
  "CreateNewMilestonePayPlanPayload",
  "CreateNewProductBundlePayload",
  "CreateNewProductCustomizationPayload",
  "CreateNewPublisherRevisionPayload",
  "CreateNewRevenueBonusPlanPayload",
  "CreateNewSellableExpertOwnerReviewRevisionPayload",
  "CreateNewSuggestedActionPayload",
  "CreateNewSuggestedMessageByExpertPayload",
  "CreateNewSuggestedMessagePayload",
  "CreateNewTenantRevisionPayload",
  "CreateOperatorsPayload",
  "CreateOrderCancellationPayload",
  "CreateOrderPriceChangeRequestPayload",
  "CreateOrderReturnRequestPayload",
  "CreatePageTemplateRevisionAlreadyExistError",
  "CreatePageTemplateRevisionDraftExistsError",
  "CreatePageTemplateRevisionMissingRequiredParameterError",
  "CreatePageTemplateRevisionNotFoundPreMadeTemplateWithIdError",
  "CreatePageTemplateRevisionNotPreMadeTemplateIdError",
  "CreatePageTemplateRevisionPayload",
  "CreatePageTemplateRevisionShouldBePageTemplateTypeError",
  "CreatePartReplacementRequestPayload",
  "CreatePartnerAccountAndUserPayload",
  "CreatePartnerContactInfoPayload",
  "CreatePartnerDistributionCenterConfigRevisionDraftExistsError",
  "CreatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
  "CreatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError",
  "CreatePartnerDistributionCenterConfigRevisionPayload",
  "CreatePartnerOrderCreditMemoPayload",
  "CreatePartnerOrderInvoiceDuplicatedInvoiceNumberError",
  "CreatePartnerOrderInvoiceEmptyLineItemsError",
  "CreatePartnerOrderInvoiceIncorrectCostShipmentsError",
  "CreatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
  "CreatePartnerOrderInvoiceInvalidInvoiceDateError",
  "CreatePartnerOrderInvoiceInvalidShippingCostError",
  "CreatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
  "CreatePartnerOrderInvoiceNoReasonForDifferentUnitCostError",
  "CreatePartnerOrderInvoiceNotAuthorizedError",
  "CreatePartnerOrderInvoicePayload",
  "CreatePartnerUserPayload",
  "CreatePasswordPayload",
  "CreatePathGraphFlowForSeparateDevicePayload",
  "CreatePathGraphFlowPayload",
  "CreatePathSchemaRevisionPayload",
  "CreatePayStructurePayload",
  "CreatePayableActivityEntriesPayload",
  "CreatePayableEntryInvalidTimeError",
  "CreatePaymentSourcePayload",
  "CreatePendingAccessContactPayload",
  "CreatePracticeLeadPayload",
  "CreatePracticeLeadStartLessonError",
  "CreateProductCatalogExportConfigDraftExistsError",
  "CreateProductCatalogExportConfigRevisionPayload",
  "CreateProductCatalogPricingScheduleByQueryPayload",
  "CreateProductCatalogTemplateDraftExistsError",
  "CreateProductCatalogTemplateRevisionPayload",
  "CreateProductComparisonVideoPayload",
  "CreatePromotionSelectionRuleDraftExistsError",
  "CreatePromotionSelectionRuleInvalidPromotionError",
  "CreatePromotionSelectionRuleRevisionPayload",
  "CreatePromptTemplateRevisionPayload",
  "CreatePublisherPageViewedUserActivitiesPayload",
  "CreatePublisherRevisionDraftExistsError",
  "CreatePurchaseJointBusinessPlanPayload",
  "CreatePurchaseOrderInvalidPurchaseRequestLineItemError",
  "CreatePurchaseOrderInvoiceAlreadyExistedError",
  "CreatePurchaseOrderInvoiceEmptyPurchaseOrderReceiptLineItemError",
  "CreatePurchaseOrderInvoiceInvalidReceiptStateError",
  "CreatePurchaseOrderInvoicePayload",
  "CreatePurchaseOrderNetDownPayload",
  "CreatePurchaseOrderNotPublishedPurchaseRequestError",
  "CreatePurchaseOrderPayload",
  "CreatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
  "CreatePurchaseOrderReceiptAlreadyExistedError",
  "CreatePurchaseOrderReceiptEmptyPurchaseOrderShipmentLineItemError",
  "CreatePurchaseOrderReceiptGenericError",
  "CreatePurchaseOrderReceiptInvalidShipmentRevisionStateError",
  "CreatePurchaseOrderReceiptNoActualShipAtProvidedError",
  "CreatePurchaseOrderReceiptPayload",
  "CreatePurchaseOrderRevisionCannotCopyAsNextVersionError",
  "CreatePurchaseOrderRevisionDraftExistsError",
  "CreatePurchaseOrderRevisionPartnerAccountStateNotActiveError",
  "CreatePurchaseOrderRevisionPayload",
  "CreatePurchaseOrderRevisionPublishedExistsError",
  "CreatePurchaseOrderRevisionTitleTooLong",
  "CreatePurchaseOrderShipmentRevisionASNNumberAlreadyBeUsedError",
  "CreatePurchaseOrderShipmentRevisionAlreadyCancelledError",
  "CreatePurchaseOrderShipmentRevisionAlreadyInReviewError",
  "CreatePurchaseOrderShipmentRevisionAlreadyReceivedError",
  "CreatePurchaseOrderShipmentRevisionDraftExistsError",
  "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
  "CreatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdForPORevisionError",
  "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderLineItemError",
  "CreatePurchaseOrderShipmentRevisionEmptyPurchaseOrderRevisionLineItemError",
  "CreatePurchaseOrderShipmentRevisionExistingDraftShipmentUnderPORevisionError",
  "CreatePurchaseOrderShipmentRevisionGenericError",
  "CreatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
  "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
  "CreatePurchaseOrderShipmentRevisionInvalidPurchaseOrderRevisionLineItemError",
  "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderError",
  "CreatePurchaseOrderShipmentRevisionNotConfirmedPurchaseOrderRevisionError",
  "CreatePurchaseOrderShipmentRevisionNotSameDCError",
  "CreatePurchaseOrderShipmentRevisionPayload",
  "CreatePurchaseOrderShipmentRevisionPurchaseOrderLineItemOverQuantityError",
  "CreatePurchaseOrderShipmentRevisionPurchaseOrderRevisionLineItemOverQuantityError",
  "CreatePurchasePromotionCreditPayload",
  "CreatePurchaseRequestRevisionDraftExistsError",
  "CreatePurchaseRequestRevisionPartnerAccountStateNotActiveError",
  "CreatePurchaseRequestRevisionPayload",
  "CreatePurchaseRequestRevisionPublishedExistsError",
  "CreateRawSellableSourcePayload",
  "CreateRawSellableStandardizationRulePayload",
  "CreateRecommendedListDefinitionPayload",
  "CreateRequestLeadActionPathNodeConfig",
  "CreateSavedExpertCuratedItemsPayload",
  "CreateSchedulingDemandForecastPayload",
  "CreateSchedulingUserAvailabilityPayload",
  "CreateSearchQueryRewriteRulePayload",
  "CreateSelfCheckoutLeadPayload",
  "CreateSellableEditorReviewRevisionPayload",
  "CreateSellableEditorialReviewRevisionDraftExistsError",
  "CreateSellableEditorialReviewRevisionGenericError",
  "CreateSellableEditorialReviewRevisionSameSellableAlreadyExistError",
  "CreateSellableEditorialTopListGigRequestPayload",
  "CreateSellableEditorialTopListRevisionPayload",
  "CreateSellableExpertiseRequestActionPathNodeConfig",
  "CreateSellableExpertiseRuleDraftPayload",
  "CreateSellablePayload",
  "CreateSellableResearchLinkPayload",
  "CreateSellableSearchPageRuleAlreadyExistsError",
  "CreateSellableSearchPageRuleExistNoArchivedStateError",
  "CreateSellableSearchPageRuleFacetAllowAndBlockListedError",
  "CreateSellableSearchPageRuleRevisionDraftExistsError",
  "CreateSellableSearchPageRuleRevisionPayload",
  "CreateSellableSearchPageSitemapRuleAlreadyExistsError",
  "CreateSellableSearchPageSitemapRuleDraftExistsError",
  "CreateSellableSearchPageSitemapRuleExistNoArchivedStateError",
  "CreateSellableSearchPageSitemapRuleRevisionPayload",
  "CreateSellableTermsPayload",
  "CreateShiftAssignmentRuleSetPayload",
  "CreateShiftSlotPayload",
  "CreateSurveyResponseActionPathNodeConfig",
  "CreateTagPayload",
  "CreateTemplateDataSourceRevisionPayload",
  "CreateTemplateFragmentPayload",
  "CreateTemplatePayload",
  "CreateTemplateTypePayload",
  "CreateTenantRevisionDraftExistsError",
  "CreateTextLinkifyRulePayload",
  "CreateTextLinkifyRuleTargetExistsError",
  "CreateTradeInRequestPayload",
  "CreateTriggerPayload",
  "CreateUpsRateCardRevisionDraftExistsError",
  "CreateUpsRateCardRevisionDuplicatedServiceTypeError",
  "CreateUpsRateCardRevisionPayload",
  "CreateUpsSurchargeRevisionDraftExistsError",
  "CreateUpsSurchargeRevisionDuplicatedSurchargeTypeError",
  "CreateUpsSurchargeRevisionPayload",
  "CreateUpsZoneLocatorRevisionDraftExistsError",
  "CreateUpsZoneLocatorRevisionPayload",
  "CreateUserMilestoneActionPathNodeConfig",
  "CreateUserNeedsPayload",
  "CreateUserPayload",
  "CreateVendorAgreementRevisionPayload",
  "CreateVideoContentRevisionPayload",
  "CreateVirtualCategoryAlreadyExistError",
  "CreateVirtualCategoryDuplicatePluralDisplayNameError",
  "CreateVirtualCategoryDuplicateSingularDisplayNameError",
  "CreateVirtualCategoryDuplicateSlugError",
  "CreateVirtualCategoryParentNodeNotFoundError",
  "CreateVirtualCategoryPayload",
  "CreateVirtualCategoryTreeRevisionDraftExistsError",
  "CreateVirtualCategoryTreeRevisionPayload",
  "CreateVoiceTokenPayload",
  "CreateWarrantyClaimRequestPayload",
  "CreateWishlistPayload",
  "CreateWishlistRichCard",
  "CuratedFactSocialProof",
  "CuratedProductFeedContentBlockItem",
  "CurationExampleSocialProof",
  "CurationExercise",
  "CurationExerciseTracking",
  "CurationQualityRequestDetail",
  "CurationQualityRequestItem",
  "CurationQualitySubmissionItem",
  "CurationQualitySubmissionRevisionDetail",
  "CurationStats",
  "CurationSummary",
  "CustomDisplayNameTooLongExpertCuratedItemProsAndConsEditError",
  "CustomDisplayNameTooShortExpertCuratedItemProsAndConsEditError",
  "CustomErrorUpdateGigSubmissionRevisionError",
  "CustomShippingMethodAndCost",
  "CustomSourceItemNotEligibleForPromotionReason",
  "CustomerRequestExpertCuratedOrderPayload",
  "DateChangeWithAuditStamp",
  "DateRange",
  "DeactivateCategoryRequest",
  "DeactivateExpertCuratedItemNotePayload",
  "DeactivateExpertPayload",
  "DeactivateGuestConversationSequencePayload",
  "DeactivatePartnerAccountPayload",
  "DeactivatePartnerUserPayload",
  "DeactivateProductCatalogLaunchSchedulePayload",
  "DeactivateSellablePayload",
  "DeactivateSellablePriceSourcePayload",
  "DeactivateTriggerPayload",
  "DeactivatedProductCatalogLaunchScheduleError",
  "DealAddOn",
  "DealInventoryClaimedActivity",
  "DealInventoryFulfillmentOrderCanceledActivity",
  "DealInventoryRequestCanceledActivity",
  "DealInventoryRequestFailedActivity",
  "DealInventoryRequestedActivity",
  "DealUploadTaskPayload",
  "DecimalEntryGenericNumericRenderingStylePathQuestionPathNodeFlowViewModel",
  "DeclineReturningUserConnectionPayload",
  "DeclineVideoChatPayload",
  "DecoratedRecommendedListConnection",
  "DeepDiveRequestDetail",
  "DeepDiveSubmissionRevisionDetail",
  "DefaultErrorMessage",
  "DefaultPathNodeEditorViewModel",
  "DefaultQuestionPathNodeFlowViewModelValidationError",
  "DefaultUserRegistrationPathNodeFlowViewModelValidationError",
  "DeferApplicationPayload",
  "DelegateExpertJoinedRichCard",
  "DelegateExpertLeftRichCard",
  "DeleteAddressPayload",
  "DeleteCategoryMarginOverridePayload",
  "DeleteDraftAttributeSchemaRevisionPayload",
  "DeleteExpertCuratedLeadItineraryPayload",
  "DeleteExpertSavedLeadSearchPayload",
  "DeleteExpertTagPayload",
  "DeletePaymentSourcePayload",
  "DeleteProposedShiftsPayload",
  "DeleteRecommendedListDefinitionIdPayload",
  "DeleteSavedExpertCuratedItemsPayload",
  "DeleteSellableResearchLinkPayload",
  "DeleteShiftSlotPayload",
  "DeliveryTimeRangeUpdate",
  "DemoProductRequestAccept",
  "DemoProductRequestActivity",
  "DemoProductRequestConnection",
  "DemoProductRequestConsolidationView",
  "DemoProductRequestConsolidationViewConnection",
  "DemoProductRequestConsolidationViewEdge",
  "DemoProductRequestEdge",
  "DemoProductRequestLateShipmentAlert",
  "DemoProductRequestReject",
  "DepartmentConsumerExpertReviewRating",
  "DepartmentExpertStats",
  "DepartmentFlatNavigation",
  "DepartmentNavigation",
  "DepartmentNavigationCategoryLink",
  "DepartmentNavigationCreatePathLink",
  "DepartmentNavigationDealsLink",
  "DepartmentNavigationDepartmentLink",
  "DepartmentNavigationExpertCommunityLink",
  "DepartmentPagination",
  "DepartmentStats",
  "DependencyErrorWithdrawGigSubmissionRevisionError",
  "DependencyRequiredApprovalRequestSubmitDraftEditError",
  "DependencyResolutionFailedApprovalRequestCreationError",
  "DeprecateAttributeSchemaRevisionPayload",
  "DeriveArticlePayload",
  "DerivedCategoryProductCatalogGroupPublishError",
  "DerivedCategoryProductCatalogPublishError",
  "DerivedCategoryProductCatalogUpdateError",
  "DerivedCategoryRule",
  "DerivedFulfillmentCosts",
  "DerivedGigAssignedActivity",
  "DerivedSellableShortSupplyAlertReasonCart",
  "DerivedSellableShortSupplyAlertReasonCuration",
  "DerivedSellableShortSupplyAlertReasonSeen",
  "DevTools",
  "DevToolsMutation",
  "DifferenceMismatchReorderCategoryNavigationSectionItemsError",
  "DifferenceMismatchReorderCategoryNavigationSectionsError",
  "DifferentDepartmentInitiateLeadTransferRequestError",
  "DimensionRating",
  "DimensionRatingSummary",
  "DimensionsMediaFileMetadata",
  "DisablePromotionPayload",
  "DiscountCashExchangeErrorInsufficientPayrollBalance",
  "DiscountCashExchangeErrorMaximumAllowedExceeded",
  "DiscountCashTransferPayload",
  "DiscountEligibleForFirstPartyCuratedItemEditError",
  "DiscountNotEligibleExpertCuratedItemEditError",
  "DismissAllOrderUserMessagesPayload",
  "DismissLeadSupportTaskPayload",
  "DismissOrderMessagesPayload",
  "DismissOrderUserMessagesPayload",
  "DisplayFacetSelection",
  "DistributionCenterFulfillmentStats",
  "DistributionCenterInfo",
  "DistributionCenterInventoryPagination",
  "DistributionCenterInventorySummaryItem",
  "DistributionCenterInventorySummaryPagination",
  "DistributionCenterOrderReturnLineItem",
  "DistributionCenterOrderReturnNotFoundUpdateOrderReturnError",
  "DistributionCenterOrderReturnPagination",
  "DistributionCenterPagination",
  "DraftExistsCopyBrandProfileRevisionAsNextRevisionError",
  "DraftExistsCopyCategoryAssetRevisionAsNextRevisionError",
  "DraftExistsCopyRewardCreditGrantTemplateRevisionAsNextRevisionError",
  "DraftExistsCopyVendorAgreementRevisionError",
  "DraftExistsCreateCategoryAssetRevisionError",
  "DraftExistsCreateCategoryNavigationGroupRevisionError",
  "DraftExistsCreateCategoryNavigationRevisionError",
  "DraftExistsCreateCommTemplateError",
  "DraftExistsCreateCommTemplateFragmentError",
  "DraftExistsCreateNewDraftBrandProfileRevisionError",
  "DraftExistsCreateNewDraftRewardCreditGrantTemplateRevisionError",
  "DraftExistsCreatePathSchemaRevisionError",
  "DraftExistsCreateTriggerError",
  "DraftExistsExerciseRevisionCopyAsDraftError",
  "DraftExistsGigClaimError",
  "DraftExistsProductCatalogCreateDraftRevisionError",
  "DraftExistsProductCatalogGroupCreateDraftRevisionError",
  "DryRunInfoSectionDetailRowPathNodeFlowViewModel",
  "DryRunInfoSectionPathNodeFlowViewModel",
  "DryRunRawSellableStandardizationPayload",
  "DueDateChangedActivity",
  "DuplicateInvoiceFulfillmentRequestProcessingError",
  "DuplicateLeadAcceptLeadTransferRequestError",
  "DuplicateProOrConExpertCuratedItemProsAndConsEditError",
  "DuplicateProductCustomizationPayload",
  "DuplicatedVariationAttributeValuesProductCatalogPublishError",
  "Duration",
  "EBayItemDimension",
  "EBayItemSearchConnection",
  "EBayItemSearchEdge",
  "EBayItemSpecification",
  "EbayItemDimensionVector",
  "EdiPurchaseOrderIntegrationAction",
  "EdiPurchaseOrderIntegrationActionDetailConfirmReceive",
  "EdiPurchaseOrderIntegrationActionDetailConfirmSend",
  "EdiPurchaseOrderIntegrationActionDetailInvoiceNoticeReceive",
  "EdiPurchaseOrderIntegrationActionDetailShipmentNoticeReceive",
  "EditJiraTicketCommentError",
  "EditJiraTicketCommentPayload",
  "EditNoteOnUserNeedPayload",
  "EditTextLinkifyRuleNoKeywordError",
  "EditorAlreadyAssignedUpdateGigSubmissionRevisionError",
  "EditorAssignedActivity",
  "EditorUnassignedActivity",
  "EditorialContentGigSubmissionSource",
  "EditorialContentManualSource",
  "EditorialReview",
  "EditorialReviewRatingFiveStar",
  "EmailBlockLoop",
  "EmailBody",
  "EmailClientVisibility",
  "EmailModuleParameterMapping",
  "EmailStyleBackgroundImage",
  "EmailStyleBorder",
  "EmailStyleBorderRadius",
  "EmailStyleColor",
  "EmailStyleLine",
  "EmailStylePadding",
  "EmailTemplate",
  "EmailTemplateHistoryComment",
  "EmailTemplateRevisionConnection",
  "EmailTemplateRevisionEdge",
  "EmailTemplateRevisionPagination",
  "EmburseCardTransaction",
  "EmptyAttributeSellableLMRatingGenerateError",
  "EmptyChannelUpdateCommTemplateChannelError",
  "EmptyContentConsumerProductReviewLMSummaryGenerateError",
  "EmptyContentConsumerProductReviewLMThemeSentimentGenerateError",
  "EmptyContentCreateCategoryAssetRevisionError",
  "EmptyContentSelectionMethod",
  "EmptyContentUpdateCategoryAssetRevisionError",
  "EmptyDealTerms",
  "EmptyLineItemsPartnerOrderReturnError",
  "EmptyProductInfoSellableLMRatingGenerateError",
  "EmptyPublishGigContentError",
  "EmptyThemeConsumerProductReviewLMSummaryGenerateError",
  "EncodedAuthenticationTokens",
  "EndPathNodeConfig",
  "EndPathNodeConfigTypeWithName",
  "EngagementChannelConfig",
  "EngagementChannelPromotionConfig",
  "EntityCreatedPathGraphFlowRequestEvent",
  "EphemeralPathNotSupportedCreatePathGraphFlowError",
  "EscalateSupportTaskToNextTierPayload",
  "EscalationClosedActivity",
  "EscalationExpertFilter",
  "EscalationItemInfo",
  "EstimatedExpertMPQLStats",
  "EstimatedExpertRPALStats",
  "EstimatedRecipientsResult",
  "ExceedsMaximumLimitProductComparisonVideoError",
  "ExchangePayrollForDiscountCashPayload",
  "ExchangeTicket",
  "ExecutePathEditorMoveNodePayload",
  "ExecutePathEditorNodeConfigCommandPayload",
  "ExecutePathEditorPasteNodePayload",
  "ExecutePathEditorRedoPayload",
  "ExecutePathEditorRemoveEdgePayload",
  "ExecutePathEditorRemoveNodePayload",
  "ExecutePathEditorSetEdgePayload",
  "ExecutePathEditorUndoPayload",
  "ExerciseTutorial",
  "ExistInvalidPartnerOrderReturnsMerchantReturnError",
  "ExperimentArchiveInUseError",
  "ExperimentContentSelectionMethod",
  "ExperimentContentSelectionToolServe",
  "ExperimentContentTemplateSelection",
  "ExperimentConversationStarterSelection",
  "ExperimentConversationStarterSelectionTreatment",
  "ExperimentCopyAsDraftDraftExistsError",
  "ExperimentCreateExistsError",
  "ExperimentCreateInvalidNameError",
  "ExperimentMetricResultHistoryPagination",
  "ExperimentMetricResultPagination",
  "ExperimentMetricRevisionPagination",
  "ExperimentPathSelection",
  "ExperimentRevisionPartitionRules",
  "ExperimentRevisionTreatmentArmRules",
  "ExperimentSubjectAttribute",
  "ExperimentTreatmentPathSelection",
  "ExperimentUsageAlert",
  "ExperimentsPagination",
  "ExperimentsUpdatedAfterPayload",
  "ExpertAccountChangeRequestNotInSubmittedStateError",
  "ExpertAccountChangeRequestsPagination",
  "ExpertAccountReactivationSetting",
  "ExpertActiveGigSubmissionChange",
  "ExpertAffiliatePromotionRichCard",
  "ExpertApplicationActivityDailyView",
  "ExpertApplicationClaimStatus",
  "ExpertApplicationDeferral",
  "ExpertApplicationEdge",
  "ExpertApplicationEvaluation",
  "ExpertApplicationFolderGroup",
  "ExpertApplicationFolderItemChange",
  "ExpertApplicationInterview",
  "ExpertApplicationNote",
  "ExpertApplicationPathPoints",
  "ExpertApplicationReview",
  "ExpertApplicationSimpleRating",
  "ExpertApplicationSnooze",
  "ExpertApplicationSource",
  "ExpertApplicationSubscriptionChange",
  "ExpertApplicationSuggestedAction",
  "ExpertApplicationTaskStatus",
  "ExpertApplicationTaskStatusUpdate",
  "ExpertApplicationUpdateFailure",
  "ExpertApplicationsConnection",
  "ExpertApplicationsPagination",
  "ExpertAssignmentHoldbackRule",
  "ExpertAssignmentRankRule",
  "ExpertAwayAutoReplyRichCard",
  "ExpertBulkUpdateInvalidTaskTypeError",
  "ExpertBulkUpdateTaskAttributeOverrideParameters",
  "ExpertBulkUpdateTaskAttributeRemoveParameters",
  "ExpertBulkUpdateTaskCoownerQualificationUpdateParameters",
  "ExpertBulkUpdateTaskInvalidStatusError",
  "ExpertBulkUpdateTaskResult",
  "ExpertBulkUpdateTaskSchedulingOverrideParameters",
  "ExpertBulkUpdateTaskSetPresenceUpdateParameters",
  "ExpertBulkUpdateTasksPagination",
  "ExpertCategoryCommunicationSetting",
  "ExpertCategoryTopRecommendedSellable",
  "ExpertCloseAccountError",
  "ExpertContributionUpdateError",
  "ExpertCuratedActivityFeedItemConnection",
  "ExpertCuratedActivityFeedItemEdge",
  "ExpertCuratedActivityFeedOrderSellableItem",
  "ExpertCuratedItemActivationCreatedPayload",
  "ExpertCuratedItemActivationPayload",
  "ExpertCuratedItemActivationsConnection",
  "ExpertCuratedItemActivationsEdge",
  "ExpertCuratedItemBundleComponent",
  "ExpertCuratedItemCustomization",
  "ExpertCuratedItemFulfillmentNotes",
  "ExpertCuratedItemGroup",
  "ExpertCuratedItemNoteApprovalChange",
  "ExpertCuratedItemNoteStats",
  "ExpertCuratedItemNotesPagination",
  "ExpertCuratedItemPriceOverrideReason",
  "ExpertCuratedItemProsAndCons",
  "ExpertCuratedItemRemovedState",
  "ExpertCuratedItemSummary",
  "ExpertCuratedItemTag",
  "ExpertCuratedItemsByCategoryChangedPayload",
  "ExpertCuratedItemsChangedPayload",
  "ExpertCuratedItemsForNeeds",
  "ExpertCuratedOrderActivationError",
  "ExpertCuratedOrderAddOn",
  "ExpertCuratedOrderChangedPayload",
  "ExpertCuratedOrderListActivationConnection",
  "ExpertCuratedOrderListActivationEdge",
  "ExpertCuratedOrderManualTaxAdjustment",
  "ExpertCuratedOrderVariationOption",
  "ExpertCuratedOrderVariationOptionPriceBreakdown",
  "ExpertCuratedOrdersForLeadConnection",
  "ExpertCuratedOrdersForLeadConnectionChangedPayload",
  "ExpertCuratedOrdersForLeadEdge",
  "ExpertCuratedSearchRichCard",
  "ExpertCurationVideoRecordingPaySummary",
  "ExpertDeviceLLMPreference",
  "ExpertDiscountCashUploadTaskError",
  "ExpertDiscountCashUploadTaskPayload",
  "ExpertEmailTemplateConnection",
  "ExpertEmailTemplateEdge",
  "ExpertFeedbackRequestRichCard",
  "ExpertHiringClassStageSummary",
  "ExpertHiringSuggestedActionDetail",
  "ExpertInboxSnapshotBroadcastPoolDetail",
  "ExpertInboxSnapshotFolderDetail",
  "ExpertInboxSnapshotView",
  "ExpertInterviewFeedback",
  "ExpertJoinedRichCard",
  "ExpertKnowledgeTestAppcuesInstruction",
  "ExpertKnowledgeTestConfigDetail",
  "ExpertKnowledgeTestFinishRedirectPathNodeFlowViewModelSuccess",
  "ExpertKnowledgeTestGuruInstruction",
  "ExpertKnowledgeTestLoomEmbedInstruction",
  "ExpertLeadCampaignConnection",
  "ExpertLeadCampaignEdge",
  "ExpertLeadCampaignPagination",
  "ExpertLeadCampaignRequestChangeReasonCount",
  "ExpertLeadCampaignStats",
  "ExpertLeadEligibility",
  "ExpertLeadFolderGroup",
  "ExpertLeadFolderItemChange",
  "ExpertLeadGate",
  "ExpertLeadGateRule",
  "ExpertLeadGateRuleAndProgress",
  "ExpertLeadGateRuleEntityDefinition",
  "ExpertLeadGateSyntheticScoreRule",
  "ExpertLeadGateSyntheticScoreRuleAndProgress",
  "ExpertMPQLStats",
  "ExpertMetricRuleBasedSuggestion",
  "ExpertMetrics",
  "ExpertMetricsCallToActionPayload",
  "ExpertMetricsCallToActionViewModel",
  "ExpertMetricsLeadBreakdown",
  "ExpertMetricsLeads",
  "ExpertMetricsPayPeriodSnapshotsPayload",
  "ExpertMetricsQC",
  "ExpertMetricsQCBreakdown",
  "ExpertMetricsQCRate",
  "ExpertMetricsRevenue",
  "ExpertMetricsRevenueBreakdown",
  "ExpertNotAvailableProductComparisonVideoError",
  "ExpertNoteAttributeSchemaMetadata",
  "ExpertNotificationLeadEscalation",
  "ExpertNotificationLeadSurge",
  "ExpertNotificationNextShiftStarted",
  "ExpertNotificationPresenceStatusChange",
  "ExpertNotificationShiftEnded",
  "ExpertPerformance",
  "ExpertPerformanceStatistics",
  "ExpertPerformanceStatisticsForInterval",
  "ExpertPerformanceStatisticsPayload",
  "ExpertPersonnelNotePagination",
  "ExpertPresenceAway",
  "ExpertPresenceExtendedAway",
  "ExpertPresenceOffShift",
  "ExpertPresenceOnCall",
  "ExpertPresenceOnShift",
  "ExpertPresenceOnStandby",
  "ExpertPresenceStatusUpdateResponse",
  "ExpertProductRecommendationFilterAttributeSchemaMetadata",
  "ExpertProfilesPagination",
  "ExpertQCRatePayload",
  "ExpertRPALStats",
  "ExpertRankOverrideTaskPayload",
  "ExpertRankOverrideTaskRowError",
  "ExpertRankRule",
  "ExpertRatingStats",
  "ExpertRecommendationByPerformanceWeightCandidate",
  "ExpertReferralPaySummary",
  "ExpertReferralRequestRichCard",
  "ExpertSavedLeadSearchNoFiltersError",
  "ExpertSavedLeadSearchSameNameError",
  "ExpertSavedLeadSearchUnknownError",
  "ExpertSearchConnection",
  "ExpertSearchEdge",
  "ExpertSelectionSetting",
  "ExpertSellableCoRecommendationReactionPagination",
  "ExpertSellableCoRecommendationReactionReasonWithDisplayName",
  "ExpertSellableRecommendationCount",
  "ExpertSellableRecommendationCountConnection",
  "ExpertSellableRecommendationCountEdge",
  "ExpertServiceRequest",
  "ExpertShiftProbation",
  "ExpertStats",
  "ExpertStatsPayload",
  "ExpertSystemTask",
  "ExpertSystemTaskSurveyDetail",
  "ExpertTestedProductConnection",
  "ExpertTestedProductEdge",
  "ExpertTestedProductStats",
  "ExpertTipPercentage",
  "ExpertTopRecommendedBrand",
  "ExpertTopRecommendedBrandsPayload",
  "ExpertTopRecommendedSellable",
  "ExpertTopRecommendedSellablesPayload",
  "ExpertTypeaheadPayload",
  "ExpertVideoRecordingTranscript",
  "ExpertVideoRecordingTranscriptSegment",
  "ExpertVideoRecordingsPagination",
  "ExpertVisibleExperiment",
  "ExpertWishListProductConnection",
  "ExpertWishListProductEdge",
  "ExpertWorkloadForRecommendation",
  "ExpiredProductCatalogPricingScheduleLineArchiveError",
  "ExportAffiliateCampaignAdPayload",
  "ExportConnectPartnerTransactionPayload",
  "ExportGeneralLedgerEntryPayload",
  "ExportProductCatalogsPayload",
  "ExportPublisherPayLineItemsPayload",
  "ExportRawSellableStandardizationResultsPayload",
  "ExportRawSellablesPayload",
  "ExportSellablesPayload",
  "ExtendExpertCuratedItemExpirationPayload",
  "ExtendWarrantyContractDetails",
  "ExternalAdCampaignCategoryFacetRule",
  "ExternalAdRuleFacet",
  "ExternalAdRulePagination",
  "ExternalContent",
  "ExternalConversionResultPagination",
  "ExternalProductFeedContentBlockItem",
  "ExternalVideoLink",
  "FacebookTemplate",
  "FacetHistogramBucket",
  "FacetHistogramBuckets",
  "FacetSelectionPayload",
  "FacetTypeaheadMetadata",
  "FacetTypeaheadPayload",
  "FacetWithDisplayName",
  "FacetedCategoryPageViewModel",
  "FailedOAuthRegisterCustomerError",
  "FeaturedSellableContentUploadTaskPayload",
  "FedExBillLineItem",
  "FedExBillRateInfo",
  "FedExBillRevisionEditInvalidBillingPeriodError",
  "FedExBillRevisionPagination",
  "FedExBillSurchargeInfo",
  "FedExBillSurchargeRate",
  "FedExExpressRateCardLineItem",
  "FedExExpressZoneRate",
  "FedExExpressZoneSurchargeRate",
  "FedExGroundRateCardLineItem",
  "FedExGroundZoneRate",
  "FedExGroundZoneSurchargeRate",
  "FedExPackageRate",
  "FedExPackageRateDetails",
  "FedExRateCardRevisionEditInvalidEffectiveTimeError",
  "FedExRateCardRevisionEditInvalidWeightError",
  "FedExRateCardRevisionPagination",
  "FedExRateCardRevisionReference",
  "FedExSurchargeRateDetails",
  "FedExSurchargeRevisionEditInvalidEffectiveTimeError",
  "FedExSurchargeRevisionPagination",
  "FedExSurchargeRevisionReference",
  "FedExTransitTimeDetail",
  "FedExZoneLocatorLineItem",
  "FedExZoneLocatorRevisionEditInvalidEffectiveTimeError",
  "FedExZoneLocatorRevisionEditInvalidZipCodeNumberError",
  "FedExZoneLocatorRevisionEditZipCodeOverlapError",
  "FedExZoneLocatorRevisionPagination",
  "FedExZoneLocatorRevisionReference",
  "FeedCommentConnection",
  "FeedCommentEdge",
  "FeedItemRemovedByAdmin",
  "FeedItemRemovedBySystem",
  "FeedItemRemovedByUser",
  "FeedNotificationConnection",
  "FeedNotificationEdge",
  "FeedNotificationSummarySinceLastVisit",
  "FeedNotificationTextSummary",
  "FeedPostConnection",
  "FeedPostEdge",
  "FeedPostRedirectPathNodeFlowViewModelSuccess",
  "FeedRankingCancellingAdjustment",
  "FeedRankingPinningAdjustment",
  "FeedRankingScoreAdjustment",
  "FeedReactionConnection",
  "FeedReactionEdge",
  "FeedReactionSummaryItem",
  "FeedReactionTypeDescription",
  "FeedSimpleTagDescription",
  "FieldUpdatedActivity",
  "FillExpertHiringClassPayload",
  "FilterAttributeFieldDefinition",
  "FilterBooleanFieldDefinition",
  "FilterDateRangeFieldDefinition",
  "FilterEnumValueDefinition",
  "FilterFieldAttributeValue",
  "FilterFieldBooleanValue",
  "FilterFieldCondition",
  "FilterFieldDateRangeValue",
  "FilterFieldGeoSearchValue",
  "FilterFieldGroup",
  "FilterFieldIdValue",
  "FilterFieldIdValueTypeaheadPayload",
  "FilterFieldNumberRangeValue",
  "FilterFieldRelativeDateRangeValue",
  "FilterFieldStringValue",
  "FilterFieldStringValueTypeaheadPayload",
  "FilterGeoPointFieldDefinition",
  "FilterIdFieldDefinition",
  "FilterLogicalCondition",
  "FilterNumberRangeFieldDefinition",
  "FilterStringFieldDefinition",
  "FinalEvalGrade",
  "FinalEvalStatusDetail",
  "FindAndAcceptOpenLeadEscalationsResult",
  "FindGiftCardForRedemptionPayload",
  "FindOrCreateReplacementExpertCuratedOrderPayload",
  "FirstSellableNotAvailableProductComparisonVideoError",
  "FittingInvitationForConsumerRichCard",
  "FlexeOrderAttachment",
  "FlexeOrderItemCost",
  "FlexeOrderLineItem",
  "FlexeShipment",
  "FlxpointFulfillmentShipmentMethodSyncPayload",
  "FlxpointOrderInvoicePagination",
  "FlxpointOrderInvoiceSyncBillDotComRecord",
  "FlxpointOrderLineItem",
  "FlxpointShipment",
  "FollowupActionRequestTicket",
  "ForYouFeedConnection",
  "ForYouFeedEdge",
  "ForYouFeedPost",
  "FormattedAttribute",
  "FrontendTasksPagination",
  "Fulfillment",
  "FulfillmentCenterConnection",
  "FulfillmentCenterEdge",
  "FulfillmentChannelEdge",
  "FulfillmentChannelWithIdAndName",
  "FulfillmentChannelsConnection",
  "FulfillmentChannelsPagination",
  "FulfillmentCostVerification",
  "FulfillmentCostVerificationUploadTaskError",
  "FulfillmentCostVerificationUploadTaskPayload",
  "FulfillmentGlitch",
  "FulfillmentInvoiceLineItemCost",
  "FulfillmentIssue",
  "FulfillmentLineItem",
  "FulfillmentOrderDeliveryStatusWithDisplayName",
  "FulfillmentOrderPayload",
  "FulfillmentPartnerShippingCarrier",
  "FulfillmentPartnershipInfo",
  "FulfillmentPath",
  "FulfillmentPerformanceStats",
  "FulfillmentPlanItem",
  "FulfillmentProcessUpdatedActivity",
  "FulfillmentRequestAndOrderLineItem",
  "FulfillmentRequestLineItem",
  "FulfillmentRequestShipment",
  "FulfillmentRequestShipmentLineItem",
  "FulfillmentResponse",
  "FulfillmentServiceProviderWithDisplayName",
  "FulfillmentServiceTypeWithDisplayName",
  "FulfillmentShipmentMethodConnection",
  "FulfillmentShipmentMethodEdge",
  "FulfillmentStatusWithDisplayName",
  "FullPayoutHistoryPayload",
  "GeneralLedgerEntriesPagination",
  "GeneralLedgerEntryLineItem",
  "GeneralSupportTicket",
  "GenerateAndSaveConsumerProductReviewLMSummaryPayload",
  "GenerateAndSaveConsumerProductReviewLMSummaryTaskPayload",
  "GenerateAndSaveConsumerProductReviewLMThemeSentimentPayload",
  "GenerateAndSaveConsumerProductReviewLMThemeSentimentTaskPayload",
  "GenerateAndSaveSellableLMRatingPayload",
  "GenerateAndSaveSellableLMRatingTaskPayload",
  "GenerateConsumerProductReviewLMSummaryPayload",
  "GenerateConsumerProductReviewLMThemeSentimentPayload",
  "GenerateDataForEmailTemplatePayload",
  "GenerateDataForTemplateDataSourcePayload",
  "GenerateExpertQuestionAndAnswersPayload",
  "GenerateLeadSuggestedMessagePayload",
  "GeneratePromptSellableLMRatingPayload",
  "GenerateSellableLMRatingPayload",
  "GenerateSellableVersusContentPayload",
  "GenerateTemplateDirectiveSnippetPayload",
  "GenerateTemplateFragmentSnippetPayload",
  "GenerateTenantLinkToAssumeConsumerV2IdentityPayload",
  "GenericNumericRenderingStylePathQuestionPathNodeFlowViewModel",
  "GenericPartnerOrderCreditMemoError",
  "GenericPartnerOrderError",
  "GenericSyncFlxpointOrderInvoiceError",
  "GenericSyncPartnerOrderCreditMemoError",
  "GenericSyncPartnerOrderInvoiceError",
  "GenericUpdatePartnerOrderReturnError",
  "GeoZipCodeRadius",
  "GeocodingResponse",
  "GetCommentsResponse",
  "GetFedExTransitTimesFedExApiErrorError",
  "GetFedExTransitTimesInvalidZipCodeError",
  "GetFedExTransitTimesNoProductInventoryPropertiesFoundError",
  "GetFedExTransitTimesNotFoundError",
  "GetFedExTransitTimesNotSupportedPackageTypeError",
  "GetFedExTransitTimesNotSupportedShipperZipCodeError",
  "GetJiraTicketCommentsPayload",
  "GetOrCreateGuestCartPayload",
  "GetOrCreateUserCartForExpertPayload",
  "GetReferralCodePayload",
  "GetUpsRateAndTransitTimesBadRequestError",
  "GetUpsRateAndTransitTimesFailureError",
  "GetUpsRateAndTransitTimesNoInventoryPropertiesFoundError",
  "GetUpsRateAndTransitTimesNoPackageProvidedError",
  "GetUpsRateAndTransitTimesServiceUnavailableError",
  "GetUpsRateAndTransitTimesUnauthorizedError",
  "GetUpsTransitTimesInvalidZipCodeError",
  "GetUpsTransitTimesNoProductInventoryPropertiesFoundError",
  "GetUpsTransitTimesNotFoundError",
  "GetUpsTransitTimesNotSupportedPackageTypeError",
  "GetUpsTransitTimesNotSupportedShipperZipCodeError",
  "GetUpsTransitTimesUpsApiErrorError",
  "GigApplicationActivity",
  "GigApplicationPagination",
  "GigClaimGate",
  "GigClaimLimit",
  "GigClaimLimitOverride",
  "GigPayAmountForRole",
  "GigPublishResult",
  "GigRequestEdge",
  "GigRequestSearchConnection",
  "GigRequestSubscriptionChange",
  "GigRequestTaskError",
  "GigRequestUploadTaskPayload",
  "GigSubmissionDependency",
  "GigSubmissionDependencyWrapper",
  "GigSubmissionReviewError",
  "GigSubmissionReviewNote",
  "GigSubmissionRevisionChange",
  "GigSubmissionRevisionEdge",
  "GigSubmissionRevisionEditorUpdate",
  "GigSubmissionRevisionExpertStatusGroup",
  "GigSubmissionRevisionRejected",
  "GigSubmissionRevisionSearchConnection",
  "GigTypeBlacklistedGigClaimError",
  "GigTypeNotQualifiedGigClaimError",
  "GigTypeSummary",
  "GiveFeedbackOnPurchaseOrderRevisionLineItemPayload",
  "GiveFeedbackOnPurchaseOrderRevisionLineItemQuantityExceedLimitError",
  "GiveFeedbackOnPurchaseOrderRevisionNoteOrShipWindowMustBeSetError",
  "GiveFeedbackOnPurchaseOrderRevisionPayload",
  "GlobalStats",
  "GlobalStatsAggregateExpertReport",
  "GoogleShoppingFeedAttributeSchemaMetadata",
  "GradeFinalEvalPayload",
  "GradingCriterion",
  "GrantIncentiveActionPathNodeConfig",
  "GrantIncentiveActionPathNodeConfigConsumerRewardCreditIncentive",
  "GrantIncentiveActionPathNodeConfigPromotionIncentive",
  "GrantIncentiveAlreadyGrantedErrorPathNodeFlowViewModel",
  "GrantIncentiveConsumerRewardCreditSuccessPathNodeFlowViewModel",
  "GrantIncentiveGrantPromotionSuccessPathNodeFlowViewModel",
  "GrantIncentiveInternalErrorPathNodeFlowViewModel",
  "GrantIncentiveNotEligibleErrorPathNodeFlowViewModel",
  "GrantMembershipToConsumerPayload",
  "GrantOrderAppeasementCreditPayload",
  "GrantRewardCreditPayload",
  "GuestChatCreateRequestRichCard",
  "GuestConversation",
  "GuestConversationSequenceMessage",
  "HardcodedContentSelectionMethod",
  "HardcodedPathSelectionMethod",
  "HasActiveChildrenSubmitDeactivateCategoryActionError",
  "HasCategoryNavigationInGroupCategoryNavigationGroupRevisionError",
  "HasChildrenUpdateGigRequestError",
  "HasCustomizationAddExpertCuratedItemToWishlistError",
  "HasDiscountAddExpertCuratedItemToWishlistError",
  "HiddenAttributeSchemaMetadata",
  "HollingsworthOrderItemCost",
  "HollingsworthOrderLineItem",
  "HollingsworthOutboundOrderEdge",
  "HollingsworthOutboundOrderPagination",
  "HollingsworthOutboundOrderReceiptLineItem",
  "HollingsworthOutboundOrderReceiptTrackingInfo",
  "HollingsworthOutboundOrderRecipient",
  "HollingsworthOutboundOrderSearchConnection",
  "HollingsworthShipment",
  "HowCuratedWorksRichCard",
  "IconFacetImageMetadata",
  "IdCodecPayload",
  "IdentifyEBayItemAsSellablePayload",
  "ImageBlockInterstitialPathNodeFlowViewModel",
  "ImageBlocksInterstitialPathNodeFlowViewModel",
  "ImageListHasEmptyFieldCreateCategoryAssetRevisionError",
  "ImageListHasEmptyFieldUpdateCategoryAssetRevisionError",
  "ImageTextBlocksPathInterstitialSchema",
  "ImportCategoryInventoryPropertiesPayload",
  "ImportCategoryInventoryPropertiesTaskError",
  "ImportCategoryInventoryPropertiesTaskPayload",
  "ImportEntitiesPayload",
  "ImportFedExBillLineItemsPayload",
  "ImportFedExBillRevisionLineItemsTaskError",
  "ImportFedExBillRevisionLineItemsTaskPayload",
  "ImportFedExRateCardLineItemsPayload",
  "ImportFedExRateCardRevisionLineItemsTaskError",
  "ImportFedExRateCardRevisionLineItemsTaskPayload",
  "ImportFedExSurchargeRevisionSupportedZipcodesTaskError",
  "ImportFedExSurchargeRevisionSupportedZipcodesTaskPayload",
  "ImportFedExSurchargeSupportedZipcodesPayload",
  "ImportFedExZoneLocatorLineItemsPayload",
  "ImportFedExZoneLocatorRevisionLineItemsTaskError",
  "ImportFedExZoneLocatorRevisionLineItemsTaskPayload",
  "ImportProductAssortmentPayload",
  "ImportProductCatalogLaunchScheduleTaskError",
  "ImportProductCatalogLaunchScheduleTaskPayload",
  "ImportProductCatalogLaunchSchedulesPayload",
  "ImportProductCatalogPricingScheduleLineTaskError",
  "ImportProductCatalogPricingScheduleLineTaskPayload",
  "ImportProductCatalogPricingScheduleLinesPayload",
  "ImportProductCatalogTaskError",
  "ImportProductCatalogTaskPayload",
  "ImportProductCatalogsPayload",
  "ImportProductComparisonVideoPayload",
  "ImportProductComparisonVideoTaskError",
  "ImportProductComparisonVideoTaskPayload",
  "ImportProductInventoryPropertiesPayload",
  "ImportProductInventoryPropertiesTaskError",
  "ImportProductInventoryPropertiesTaskPayload",
  "ImportPurchaseJointBusinessPlanLineItemsPayload",
  "ImportPurchaseJointBusinessPlanLineItemsTaskError",
  "ImportPurchaseJointBusinessPlanLineItemsTaskPayload",
  "ImportPurchaseOrderLineItemsPayload",
  "ImportPurchaseOrderLineItemsTaskError",
  "ImportPurchaseOrderLineItemsTaskPayload",
  "ImportPurchaseOrderNetDownLineItemsPayload",
  "ImportPurchaseOrderNetDownLineItemsTaskError",
  "ImportPurchaseOrderNetDownLineItemsTaskPayload",
  "ImportPurchaseOrderRevisionLineItemsForPartnerPayload",
  "ImportPurchaseOrderRevisionLineItemsForPartnerTaskPayload",
  "ImportPurchaseOrderRevisionLineItemsPayload",
  "ImportPurchaseOrderRevisionLineItemsTaskError",
  "ImportPurchaseOrderRevisionLineItemsTaskPayload",
  "ImportPurchaseRequestLineItemsGenericError",
  "ImportPurchaseRequestLineItemsNoCatalogError",
  "ImportPurchaseRequestLineItemsPayload",
  "ImportPurchaseRequestLineItemsTaskError",
  "ImportPurchaseRequestLineItemsTaskPayload",
  "ImportRawSellableSourcePayload",
  "ImportRawSellableStandardizationRulePayload",
  "ImportRawSellableStandardizationRulesPayload",
  "ImportReference",
  "ImportUpsRateCardLineItemsPayload",
  "ImportUpsRateCardRevisionLineItemsTaskError",
  "ImportUpsRateCardRevisionLineItemsTaskPayload",
  "ImportUpsSurchargeRevisionSupportedZipcodesTaskError",
  "ImportUpsSurchargeRevisionSupportedZipcodesTaskPayload",
  "ImportUpsSurchargeSupportedZipcodesPayload",
  "ImportUpsZoneLocatorLineItemsPayload",
  "ImportUpsZoneLocatorRevisionLineItemsTaskError",
  "ImportUpsZoneLocatorRevisionLineItemsTaskPayload",
  "ImportVideoContentRevisionPayload",
  "ImportVideoContentRevisionTaskError",
  "ImportVideoContentRevisionTaskPayload",
  "InAppTemplate",
  "InArchivedStateAddExpertCuratedItemToWishlistError",
  "InArchivedStateAddGiftCardToWishlistError",
  "InArchivedStateAddProductBundleToWishlistError",
  "InArchivedStateAddSellableToWishlistError",
  "InArchivedStateArchiveWishlistItemError",
  "InArchivedStateSetWishlistVisibilityError",
  "InArchivedStateUpdateGiftCardWishlistItemError",
  "InArchivedStateUpdateSellableWishlistItemError",
  "InArchivedStateUpdateWishlistError",
  "InArchivedStateUpdateWishlistShippingAddressError",
  "InRangeNumericValidationQuestionPathNodeFlowViewModel",
  "InUseProductCatalogArchiveRevisionError",
  "InactiveExpertHiringCohortUpdateError",
  "InactiveGigRequiredCopyGigApplicationAsDraftError",
  "InboxRedirectPathNodeFlowViewModelSuccess",
  "IncompatibleLayoutTypeCreatePathGraphFlowError",
  "IncompleteDependencyPublishAndPayGigError",
  "IncompleteSubmitGigApplicationError",
  "InconsistentVariationDimensionsProductCatalogPublishError",
  "IncorrectVideoUrlProductComparisonVideoError",
  "IndividualBucketedDisplayFacet",
  "IndividualBucketedRangeDisplayFacet",
  "IndividualProductAttributeReview",
  "IndividualSellableAttributeProCon",
  "IndividualSellableCustomProCon",
  "InfluencerPerformanceDetails",
  "InitiateAffirmPaymentForOrderPayload",
  "InitiateAfterpayPaymentForOrderPayload",
  "InitiateAuthenticationPayload",
  "InitiateDealInventoryFulfillmentPayload",
  "InitiateLeadTransferRequestPayload",
  "InitiatePaypalPaymentForOrderPayload",
  "InitiateResetPasswordPayload",
  "InitiateVerifyHandlePayload",
  "InitiateWireTransferPaymentForOrderPayload",
  "InjectAttributesActionPathNodeConfig",
  "InjectAttributesActionPathNodeConfigAttributeEntityReferenceSource",
  "InjectAttributesActionPathNodeConfigAttributeValuesSource",
  "InputTextPathQuestionSchemaLengthValidationViewModel",
  "InputTextPathQuestionSchemaRegexValidationViewModel",
  "InsertExperimentRevisionMetricPayload",
  "InsertExperimentRevisionRulePayload",
  "InstallmentPaymentEntity",
  "InsufficientQuantityPartnerOrderReturnError",
  "InterestedInCuratedOrderRichCard",
  "InternalOrderLineItemSellableSource",
  "InternalOrderPagination",
  "InternalUserOnlyNotEligibleForPromotionReason",
  "InterstitialPathNodeConfig",
  "InvalidAccountStatePartnerAccountEditError",
  "InvalidAddressAddAddressWithValidationError",
  "InvalidAddressUpdateOrderShippingAddressError",
  "InvalidAmountAddGiftCardWishlistItemToOrderError",
  "InvalidAttributeExplanationsSellableLMRatingGenerateError",
  "InvalidAttributeForDepartmentExpertCuratedItemNoteEditError",
  "InvalidAttributeValueProductCatalogGroupUpdateError",
  "InvalidAttributeValueProductCatalogUpdateError",
  "InvalidCategoryProductCatalogGroupPublishError",
  "InvalidCategoryProductCatalogPublishError",
  "InvalidChannelUpdateExpertCategoryCommunicationError",
  "InvalidCurrencyCodeUpdatePartnerAccountError",
  "InvalidDistributionCenterInfoError",
  "InvalidDistributionCenterVendorUpdatePartnerAccountError",
  "InvalidEditorUpdateGigSubmissionRevisionError",
  "InvalidEmailAddressRegisterCustomerError",
  "InvalidEmailContactError",
  "InvalidEmailEditExpertApplicationError",
  "InvalidExpertApplicationState",
  "InvalidExpertCategorySeasonEndDate",
  "InvalidFieldUpdatePartnerAccountError",
  "InvalidGigApplicationStateArchiveGigRequestError",
  "InvalidHandleVerificationTokenRegisterCustomerError",
  "InvalidItemCreatePartReplacementRequestError",
  "InvalidItemCreateWarrantyClaimRequestError",
  "InvalidLLMResultConsumerProductReviewLMSummaryGenerateError",
  "InvalidMerchantCreateTradeInRequestError",
  "InvalidNoteStateExpertCuratedItemNoteEditError",
  "InvalidOrderLineItemTypeCreateConsumerProductReviewError",
  "InvalidParentEditCategoryError",
  "InvalidParentStateGigRequestError",
  "InvalidParentSubmitEditCategoryActionError",
  "InvalidPhoneNumberRegisterCustomerError",
  "InvalidPostTokenConsumerProductReviewError",
  "InvalidPromotionCodeBulkUpdateContentSelectionRuleError",
  "InvalidQuantityChangeOrderError",
  "InvalidRankRuleExpertAdvancementRuleError",
  "InvalidReferralRewardCreditClaimTokenRegisterCustomerError",
  "InvalidSearchQuerySellableSearchError",
  "InvalidSlackChannelBusinessUserTeamEditError",
  "InvalidSlugEditCategoryError",
  "InvalidSlugSubmitEditCategoryActionError",
  "InvalidSmsContactError",
  "InvalidSmsSignInRegisterCustomerError",
  "InvalidSourceNodePathCopyTokenError",
  "InvalidStateActivateGigRequestError",
  "InvalidStateApprovalRequestPublishError",
  "InvalidStateApproveGigApplicationError",
  "InvalidStateApprovePostPublishGigSubmissionRevisionError",
  "InvalidStateArchivePathSchemaRevisionError",
  "InvalidStateChangeOrderError",
  "InvalidStatePartnerOrderReturnError",
  "InvalidStateRejectGigApplicationError",
  "InvalidStateSubmitGigApplicationError",
  "InvalidStateUpdateGigRequestError",
  "InvalidStateVendorAgreementRevisionError",
  "InvalidStoreCreditRefundOrderStoreCreditError",
  "InvalidTargetFeedReactionAddError",
  "InvalidTenantCopyTriggerIntoTenantError",
  "InvalidTrackingCreatePartReplacementRequestError",
  "InvalidTrackingCreateTradeInRequestError",
  "InvalidTrackingMerchantReturnError",
  "InvalidTrackingPartnerOrderReturnError",
  "InvalidTransactionStatusResendOrderPaymentVerificationError",
  "InvalidUserStateUpdatePartnerUserError",
  "InvalidValueCreateTradeInRequestError",
  "InvalidValueUpdateExpertCategoryLeadSettingError",
  "InvalidVariantProductCatalogPublishError",
  "InventoryAdjustmentPagination",
  "InventoryCOGSUsage",
  "InventoryFlexeInboundFlowContent",
  "InventoryFlexeReturnFlowContent",
  "InventoryFlowConsolidationView",
  "InventoryFlowConsolidationViewSearchConnection",
  "InventoryFlowConsolidationViewSearchEdge",
  "InventoryFlowSnapshotLatest",
  "InventoryGenericCOGS",
  "InventoryGenericCOGSData",
  "InventoryGenericCost",
  "InventoryGenericInboundAdjustmentFlowContent",
  "InventoryGenericInboundFlowContent",
  "InventoryGenericInvoiceFlowContent",
  "InventoryGenericOutboundAdjustmentFlowContent",
  "InventoryGenericReturnFlowContent",
  "InventoryHollingsworthOutboundFlowContent",
  "InventoryTaskError",
  "InventoryTransferOrderMeta",
  "InventoryW2GOutboundFlowContent",
  "InviteNewPartnerPayload",
  "InviteToCreateWishlistPayload",
  "InvoiceDiscount",
  "InvoiceReconciliationFlxpointSource",
  "InvoiceReconciliationManualSource",
  "InvoiceReconciliationPartnerHubSource",
  "InvoicedShippingCostUploadTaskError",
  "InvoicedShippingCostUploadTaskPayload",
  "IpBasedUserData",
  "ItemClaimsGroupingTicket",
  "ItemCountNotEligibleForPromotionReason",
  "ItemFulfillmentSuggestion",
  "ItemIneligibleForPurchaseUpdateTrialOrderError",
  "ItemProcessedOrShippedChangeOrderError",
  "ItemReturnReceivedActivity",
  "ItemReturnRequestedActivity",
  "ItemReturnShippedActivity",
  "ItemUnavailableTicket",
  "JobListingLocation",
  "JoinVideoChatPayload",
  "JournalEntry",
  "LLMConversationStarterTemplate",
  "LLMSellableSuggestedNote",
  "LLMSellableSuggestedNotePayload",
  "LLMSuggestedNoteAttribute",
  "LMApiErrorSellableLMRatingGenerateError",
  "LabelRequestFailedMerchantReturnError",
  "LazyLoadRecommendedListSellableEdge",
  "LeadBulkActionInvalidSnooze",
  "LeadBulkActionUnsupportedBulkSizeError",
  "LeadCampaignSettings",
  "LeadChecklistRequirementTracking",
  "LeadChecklistTaskTracking",
  "LeadConversationStarterFreeFormQuestionContent",
  "LeadConversationStarterPredefinedQuestionContent",
  "LeadDestinationCategoryPage",
  "LeadDestinationDepartmentPage",
  "LeadDestinationInboxPage",
  "LeadDestinationSellablePage",
  "LeadDestinationTopListPage",
  "LeadExistsUnderOtherExpertCreateExpertAffiliateOutreachError",
  "LeadExistsUnderRequestingExpertCreateExpertAffiliateOutreachError",
  "LeadForecastBasedSchedulingDemandForecastStrategy",
  "LeadIntentChange",
  "LeadPayload",
  "LeadPromptTemplateParameters",
  "LeadRPALExclusionOverrideRequestsPagination",
  "LeadRecommendedExpert",
  "LeadRequestInternalDerived",
  "LeadSearchConnection",
  "LeadSearchEdge",
  "LeadSearchHighlight",
  "LeadSourceAiGuestChatFlow",
  "LeadSourceArticlePage",
  "LeadSourceConversationStarterFlow",
  "LeadSourceDepartmentPage",
  "LeadSourceDirectExpertChatFlow",
  "LeadSourceExpertIntroFlow",
  "LeadSourceExpertProfilePage",
  "LeadSourceExpertisePage",
  "LeadSourceFeedProductRecommendationSourceFlow",
  "LeadSourceGiftCardRedeemPage",
  "LeadSourceGuestChatFlow",
  "LeadSourceIncomingCallOrMessageFlow",
  "LeadSourceInfluencerProfilePage",
  "LeadSourceOrderPage",
  "LeadSourcePathFlow",
  "LeadSourcePostOrderCheckinFlow",
  "LeadSourceProductAnswerPage",
  "LeadSourceProductQuickReviewPage",
  "LeadSourcePublisherPage",
  "LeadSourceRegistrationFlow",
  "LeadSourceSelfCheckoutExpertSelectionFlow",
  "LeadSourceSellableEditorialTopListPage",
  "LeadSourceSellablePage",
  "LeadSourceSellableSearchPage",
  "LeadSourceSellableTopListPage",
  "LeadStats",
  "LeadSuggestedActionDetail",
  "LeadSuggestedActionGracePeriodChange",
  "LeadTouchFilterFieldDefinition",
  "LeadTouchFilterFieldValue",
  "LeadTrainingScenariosPagination",
  "LeadWaitingConsumerInput",
  "LedgerAuthorizeAndPostPayload",
  "LedgerBalanceExpirationPagination",
  "LedgerEntryPagination",
  "LedgerTransactionAlreadyPostedError",
  "LedgerTransactionAuthorizationExpiredError",
  "LedgerTransactionAuthorizeAndPostDetails",
  "LedgerTransactionAuthorizeDetails",
  "LedgerTransactionCancelAuthorizationDetails",
  "LedgerTransactionDuplicatedError",
  "LedgerTransactionExtendAuthorizationDetails",
  "LedgerTransactionInsufficientBalanceError",
  "LedgerTransactionInvalidAmountError",
  "LedgerTransactionInvalidExpirationError",
  "LedgerTransactionPostDetails",
  "LedgerTransactionTransferInvalidTransferAmountError",
  "LedgerTransactionTransferMismatchAccountCurrencyCodeError",
  "LedgerTransactionTransferMismatchAccountTypeError",
  "LedgerTransactionTransferSelfTransferError",
  "LedgerTransferDetails",
  "LegacyCoOwnerPointsSource",
  "LengthInInchesNumericRenderingStylePathQuestionPathNodeFlowViewModel",
  "LessonTrackingAlreadyCompletedStartLessonError",
  "LessonTrackingAlreadyExistsStartLessonError",
  "LineItemClaimTicket",
  "LineItemFulfillmentCost",
  "LineItemFulfillmentPlan",
  "LineItemNotFoundPartnerOrderReturnError",
  "LineItemParcelRequestData",
  "LineItemReturnFee",
  "LineItemTrackingUpdateTicket",
  "LinkedPathNodeEditorViewModel",
  "LiquidationOrderMeta",
  "ListPathQuestionSchemaFreeFormChoice",
  "ListQuestionFreeFormChoiceViewModel",
  "LogicalConditionDefinition",
  "LogoutAllSessionsPayload",
  "LongRange",
  "LookupReferralCodePayload",
  "LookupReferralRewardCreditPayload",
  "MakeExpertCuratedItemDraftsActivePayload",
  "MalformedSlugCreatePathSchemaRevisionError",
  "ManualFeedItemSource",
  "ManualGLAdjustmentUploadTaskPayload",
  "ManualGLAdjustmentUploadTaskRowError",
  "ManualGeneralLedgerAdjustmentLineItem",
  "ManualGeneralLedgerAdjustmentPayload",
  "MapCatalogIdAndSyncWarehouseOrderLineReceiptPayload",
  "MappingAttributeValue",
  "MarkConversationReadPayload",
  "MarkFeedNotificationAsInteractedPayload",
  "MarkFeedNotificationsSeenPayload",
  "MarkJiraTicketReadError",
  "MarkJiraTicketReadPayload",
  "MarkPaymentFailedForOrderPayload",
  "MarkPaymentPreApprovalFailedForOrderPayload",
  "MarkdownHasEmptyFieldCreateCategoryAssetRevisionError",
  "MarkdownHasEmptyFieldUpdateCategoryAssetRevisionError",
  "MarketHeatChangedPayload",
  "MarketingContentOrderMeta",
  "MarketingPageConfig",
  "MaxClaimReachedGigClaimError",
  "MaxSizeExpertHiringClassAddApplicationError",
  "MaxSubmissionsReachedGigClaimError",
  "MaxSubmissionsUnarchiveGigRequestError",
  "MaximumLeadsWithTagExceededError",
  "MaximumTagExceededError",
  "MediaAssetConnection",
  "MediaAssetEdge",
  "MediaAssetMediaNotExistError",
  "MediaAssetShareStatsPagination",
  "MediaAssetsPagination",
  "MediaFileWithCaption",
  "MediaIdDoesNotExistPublishProductComparisonVideoError",
  "MediaRenderedContent",
  "MediaTranscriptSegment",
  "MediaUploadPathQuestionSchemaVideoFormat",
  "MediaUrlEmptyProductComparisonVideoError",
  "MembershipAlreadyInCartAddMembershipToOrderError",
  "MembershipOfferRichCard",
  "MembershipRenewalNotSupportedError",
  "MembershipReward",
  "MembershipVariationMatrixPriceRange",
  "MerchantClaimedActivity",
  "MerchantInvoicePagination",
  "MerchantOrderNotFoundMerchantReturnError",
  "MerchantRefundTicket",
  "MerchantRefundUpdatedActivity",
  "MerchantReturnAndOrderLineItem",
  "MerchantReturnLineItem",
  "MerchantReturnShipment",
  "MerchantSuggestionResult",
  "MerchantUploadTaskPayload",
  "MerchantsPagination",
  "MergeStandardizedSellableGroupPayload",
  "MergedCategoryAttribute",
  "MessageBody",
  "MessageExercise",
  "MessageExerciseTracking",
  "MessageRichCardDataModel",
  "MessageRichCardMeta",
  "MessageSummary",
  "MilestoneFixedAmountPayAction",
  "MilestonePayPlanDraftExistsError",
  "MilestonePayPlanNotInDraftError",
  "MilestonePayPlanRevisionsPagination",
  "MilestonePayment",
  "MilestoneTotalRevenuePercentagePayAction",
  "MinimumCategoryCountExpertHiringCohortUpdateError",
  "MisMatchedMerchantReturnsUpdateOrderReturnError",
  "MissingAttributeProductCatalogGroupPublishError",
  "MissingAttributeProductCatalogGroupRevision",
  "MissingAttributeProductCatalogGroupUpdateError",
  "MissingAttributeProductCatalogPublishError",
  "MissingAttributeProductCatalogRevision",
  "MissingAttributeProductCatalogUpdateError",
  "MissingAttributeValueProductCatalogGroupPublishError",
  "MissingAttributeValueProductCatalogGroupRevision",
  "MissingAttributeValueProductCatalogPublishError",
  "MissingAttributeValueProductCatalogRevision",
  "MissingBillDotComVendorIdUpdatePartnerAccountError",
  "MissingCategoryProductCatalogGroupPublishError",
  "MissingCategoryProductCatalogPublishError",
  "MissingContactUpdatePartnerAccountError",
  "MissingEntityFetcherCheckExerciseStatusError",
  "MissingExerciseEvaluatorCheckExerciseStatusError",
  "MissingFieldUpdatePartnerAccountError",
  "MissingItemTicket",
  "MissingLabelDataMerchantReturnError",
  "MissingNameEditExpertApplicationError",
  "MissingPaymentMethodUpdateMerchantRefundError",
  "MissingPublishedExerciseRevisionStartLessonError",
  "MissingRawAttributeProductCatalogGroupUpdateError",
  "MissingRawAttributeProductCatalogUpdateError",
  "MissingReferralEditExpertApplicationError",
  "MissingResolutionCreateWarrantyClaimRequestError",
  "MissingSpecsTicket",
  "MissingTemplateAttributeProductCatalogGroupPublishError",
  "MissingTemplateAttributeProductCatalogPublishError",
  "MissingTrainingScenarioStartLessonError",
  "MixingTrialAndPurchaseSellablesError",
  "MobileAppUpdateDetails",
  "MonetaryAmount",
  "MonetaryAmountRange",
  "MonetaryAmountWithTax",
  "Money",
  "MonitorVideoChatPayload",
  "MonthlyFulfillmentPerformanceStats",
  "MoveCategoryRequest",
  "MoveExperimentRevisionMetricPayload",
  "MoveExperimentRevisionRulePayload",
  "MultipleUsersSpecifiedUserNeedArchiveError",
  "MultipleUsersSpecifiedUserNeedCreateError",
  "MultipleUsersSpecifiedUserNeedProfileError",
  "MustArchiveThroughApprovalArchiveCategoryAssetRevisionError",
  "MustArchiveThroughApprovalArchiveGigSubmissionRevisionError",
  "MustArchiveThroughApprovalCategoryNavigationGroupRevisionError",
  "MustArchiveThroughApprovalCategoryNavigationRevisionError",
  "MustArchiveThroughApprovalCommTemplateError",
  "MustArchiveThroughApprovalCommTemplateFragmentError",
  "MustLoginRequireAuthenticationPathNodeFlowViewModelError",
  "MustUseEphemeralPathCreatePathGraphFlowError",
  "NameConflictEditCategoryError",
  "NameConflictSubmitEditCategoryActionError",
  "NeedExistsInCategoryUserNeedCopyError",
  "NeedForCategoryExistsUserNeedCreateError",
  "NewBookableCuratedOrderRichCard",
  "NewCuratedItemsRichCard",
  "NewCuratedOrderListRichCard",
  "NewCustomCuratedOrderRichCard",
  "NewLinePathEditorRichTextContentPart",
  "NewUserMilestoneRichCard",
  "NextSocialProofPayload",
  "NoAvailableShift",
  "NoCategoriesUserNeedCopyError",
  "NoFinalEvalShiftForExpertApplication",
  "NoImagesAvailableForFirstSellableProductComparisonVideoError",
  "NoImagesAvailableForSecondSellableProductComparisonVideoError",
  "NoMembershipTypeAvailableAddMembershipToOrderError",
  "NoMerchantCreatePartReplacementRequestError",
  "NoProductIdentifierProductCatalogDraftFromSellableError",
  "NoPublishedVariantParentProductCatalogPublishError",
  "NoReturnsAcceptedReturnPolicy",
  "NoSellableFoundCreateInternalOrderError",
  "NoSuchAttributeError",
  "NoSuchDependencyApprovalRequestSubmitDraftEditError",
  "NoSuchFeedItemError",
  "NoSuchPathSchemaCreatePathGraphFlowError",
  "NoTipStoreCreditGiftTipStoreCreditError",
  "NodeNotTraversedNavigatePathGraphFlowError",
  "NormalizedContact",
  "NotActiveAddConsumerProductReviewReactionError",
  "NotActualAddressBlockAddressError",
  "NotApprovedApprovalRequestPublishError",
  "NotApprovedDeferExpertApplicationError",
  "NotApprovedPublishAndPayGigError",
  "NotAssignedExpertInitiateLeadTransferRequestError",
  "NotAuthorizedApprovalRequestPublishError",
  "NotAvailableAddSellableWishlistItemToOrderError",
  "NotAvailableRejectLeadTransferRequestError",
  "NotCancellableUpdateOrderReturnError",
  "NotCatalogSourceMerchantReturnError",
  "NotDraftExpertHiringClassUpdateError",
  "NotDraftProductCatalogGroupUpdateError",
  "NotDraftProductCatalogUpdateError",
  "NotDraftUpdateGigApplicationError",
  "NotEligibleChangeOrderError",
  "NotEligibleClaimConsumerReferralRewardCreditError",
  "NotEligibleForStoreCreditRefundCreateOrderReturnError",
  "NotEligibleRefundOrderReturnError",
  "NotEligibleRefundPartnerOrderReturnError",
  "NotEligibleStateApprovableEntityDependencyResolutionError",
  "NotEmptyBusinessUserTeamUpdateError",
  "NotEnoughInfoUpdateMerchantRefundError",
  "NotEnoughInformationConsumerProductReviewLMSummaryGenerateError",
  "NotEnoughInformationConsumerProductReviewLMThemeSentimentGenerateError",
  "NotEnoughInformationSellableLMRatingGenerateError",
  "NotFoundApprovableEntityDependencyResolutionError",
  "NotFoundRewardCreditClaimError",
  "NotFullyAppliedSellableEditError",
  "NotInClaimedStatusWithdrawGigSubmissionRevisionError",
  "NotInDraftStateAddCommTemplateChannelError",
  "NotInDraftStateCreateCategoryNavigationSectionError",
  "NotInDraftStateCreateCategoryNavigationSectionItemError",
  "NotInDraftStateExerciseRevisionUpdateError",
  "NotInDraftStateProductComparisonVideoError",
  "NotInDraftStateRemoveCategoryNavigationSectionError",
  "NotInDraftStateRemoveCategoryNavigationSectionItemError",
  "NotInDraftStateRemoveCommTemplateChannelError",
  "NotInDraftStateReorderCategoryNavigationSectionItemsError",
  "NotInDraftStateReorderCategoryNavigationSectionsError",
  "NotInDraftStateUpdateBrandProfileRevisionError",
  "NotInDraftStateUpdateCategoryAssetRevisionError",
  "NotInDraftStateUpdateCategoryNavigationGroupRevisionError",
  "NotInDraftStateUpdateCategoryNavigationRevisionError",
  "NotInDraftStateUpdateCategoryNavigationSectionError",
  "NotInDraftStateUpdateCategoryNavigationSectionItemError",
  "NotInDraftStateUpdateCommTemplateChannelError",
  "NotInDraftStateUpdateCommTemplateError",
  "NotInDraftStateUpdateCommTemplateFragmentError",
  "NotInDraftStateUpdateGigSubmissionRevisionError",
  "NotInDraftStateUpdateRewardCreditGrantTemplateRevisionError",
  "NotInProgressCheckExerciseStatusError",
  "NotInProgressSetExerciseTrackingCompletedError",
  "NotInReviewAddGigSubmissionReviewError",
  "NotInReviewGigSubmissionRevisionError",
  "NotLatestRevisionProductCatalogArchiveRevisionError",
  "NotLatestRevisionProductCatalogGroupArchiveRevisionError",
  "NotOpenExpertHiringClassAddApplicationError",
  "NotParentGigRequestError",
  "NotParentGigSubmissionRevisionError",
  "NotParentSellableUpsertSellableLMRatingError",
  "NotPublicAddGiftCardWishlistItemToOrderError",
  "NotPublicAddSellableWishlistItemToOrderError",
  "NotPublishedCopyTriggerIntoTenantError",
  "NotPublishedExpertHiringClassCloseError",
  "NotPublishedExpertHiringClassOpenError",
  "NotPurchasedCreateConsumerProductReviewError",
  "NotQualifiedGigClaimError",
  "NotReadyExerciseRevisionPublishError",
  "NotReadyPublishBrandProfileRevisionError",
  "NotReadyPublishRewardCreditGrantTemplateRevisionError",
  "NotSubmittedWithdrawGigApplicationError",
  "NotSupportedPublishGigContentError",
  "NotValidAcceptLeadTransferRequestError",
  "NotWhitelistedGigClaimError",
  "NoteEditErrorExpertCuratedItemEditError",
  "NotificationSetting",
  "NotifyCustomerExpertCuratedOrderPayload",
  "NotifyShiftsPayload",
  "NounHasEmptyFieldCreateCategoryAssetRevisionError",
  "NounHasEmptyFieldUpdateCategoryAssetRevisionError",
  "NumericPathQuestionSchemaGenericRenderingStyle",
  "NumericPathQuestionSchemaLengthInInchesRenderingStyle",
  "NumericPathQuestionSchemaStarRatingRenderingStyle",
  "NumericRangeDisplayFacet",
  "OEmbedLink",
  "OEmbedPhoto",
  "OEmbedRich",
  "OEmbedVideo",
  "OfferPhoneCallRequirement",
  "OnboardedWithdrawExpertApplicationError",
  "OpenAllShiftSlotsPayload",
  "OpenExpertHiringClassPayload",
  "OpenGraphData",
  "OpenGraphProperty",
  "OpenLeadEscalationCountChange",
  "OpenVipTicketCountNotification",
  "OperatorAssignedActivity",
  "OperatorCloseAccountError",
  "OperatorShiftPresence",
  "OperatorShiftUpdatePayload",
  "OrderActivity",
  "OrderAddressChangedActivity",
  "OrderAdjustmentTicket",
  "OrderAmountNotEligibleForPromotionReason",
  "OrderAppliedLedgerCredit",
  "OrderChargeableStatus",
  "OrderCostChange",
  "OrderCostChangeDetails",
  "OrderEscalatedActivity",
  "OrderEscalationAttachment",
  "OrderExpertContribution",
  "OrderExpertContributionChangedActivity",
  "OrderGiftCardDetails",
  "OrderInformationTicket",
  "OrderItemReceivedActivity",
  "OrderItemShippedActivity",
  "OrderLineItemAdHocSource",
  "OrderLineItemBasePriceSource",
  "OrderLineItemCancellation",
  "OrderLineItemCostChange",
  "OrderLineItemCourtesyCreditSource",
  "OrderLineItemCustomizationAddOnSource",
  "OrderLineItemCustomizationAttributeSource",
  "OrderLineItemExpertServiceSource",
  "OrderLineItemExpertTipSource",
  "OrderLineItemExpertTipSourceExpertAmount",
  "OrderLineItemGiftCardSource",
  "OrderLineItemGratuitySource",
  "OrderLineItemGroupDefinition",
  "OrderLineItemMarginCommission",
  "OrderLineItemMembershipSource",
  "OrderLineItemPrepaidIncidentalSource",
  "OrderLineItemProductBundleSource",
  "OrderLineItemRefundSource",
  "OrderLineItemRefundSummary",
  "OrderLineItemReplacementRequest",
  "OrderLineItemReturn",
  "OrderLineItemReturnFeeSource",
  "OrderLineItemRewardCreditClawbackSource",
  "OrderLineItemSellableSource",
  "OrderLineItemShipmentSource",
  "OrderLineItemTaxAdjustmentCreditSource",
  "OrderLineItemTrialFeeSource",
  "OrderLineItemWarrantySource",
  "OrderLineItemWithShippedQuantity",
  "OrderLineItemsTrackingSummary",
  "OrderMessage",
  "OrderOperatorNote",
  "OrderPayload",
  "OrderPickupInstructions",
  "OrderPromotionEntry",
  "OrderRefundEdge",
  "OrderRefundWithError",
  "OrderRefundsConnection",
  "OrderRefundsPagination",
  "OrderReturnCosts",
  "OrderReturnRequestAndOrderLineItem",
  "OrderShipmentStatusUpdateActivity",
  "OrderShippingCost",
  "OrderVerification",
  "OrderVerificationActivity",
  "OrderVerificationTicket",
  "OrdersConnection",
  "OrdersEdge",
  "OriginOrderReturnRequestLineItem",
  "OtherReasonEmptyNoteInitiateLeadTransferRequestError",
  "OtherReasonEmptyNoteRejectLeadTransferRequestError",
  "OthersYouMayLikePayload",
  "OutreachExistsCreateExpertAffiliateOutreachError",
  "OverrideCommissionEligibilityPayload",
  "OverwriteShiftSlotPayload",
  "PageGraphFlowTrackingCodes",
  "PageInfo",
  "PageTemplateComponentConnection",
  "PageTemplateComponentEdge",
  "PageTemplateComponentFieldValueAnchor",
  "PageTemplateComponentFieldValueAnchorList",
  "PageTemplateComponentFieldValueBoolean",
  "PageTemplateComponentFieldValueButton",
  "PageTemplateComponentFieldValueComposite",
  "PageTemplateComponentFieldValueCompositeList",
  "PageTemplateComponentFieldValueEmpty",
  "PageTemplateComponentFieldValueEntryItem",
  "PageTemplateComponentFieldValueEntryList",
  "PageTemplateComponentFieldValueEnum",
  "PageTemplateComponentFieldValueHref",
  "PageTemplateComponentFieldValueImage",
  "PageTemplateComponentFieldValueImageList",
  "PageTemplateComponentFieldValueMarkdown",
  "PageTemplateComponentFieldValueNumber",
  "PageTemplateComponentFieldValueText",
  "PageTemplateComponentFieldValueVideo",
  "PageTemplateComponentValue",
  "PageTemplateComponentView",
  "PageTemplateContentSelectionToolServe",
  "PageTemplateExperimentSelection",
  "PageTemplateExperimentUsage",
  "PageTemplateParameterMeta",
  "PageTemplatePreviewParams",
  "PageTemplateResolveResult",
  "PageTemplateRevisionConnection",
  "PageTemplateRevisionEdge",
  "PageTemplateRevisionPagination",
  "PageTemplateSelection",
  "PageTemplateStyleColor",
  "PageTemplateTreatmentSelection",
  "ParcelsTooLargeMerchantReturnError",
  "ParentDeactivatedEditCategoryError",
  "ParentDeactivatedSubmitEditCategoryActionError",
  "ParentHasParentGigRequestError",
  "ParentNotExistProductCatalogDraftFromSellableError",
  "ParentOrderLineItemCreateConsumerProductReviewError",
  "ParentSourceTypeNotEligibleForPromotionReason",
  "ParseErrorConsumerProductReviewLMSummaryGenerateError",
  "ParseErrorConsumerProductReviewLMThemeSentimentGenerateError",
  "ParseErrorSellableLMRatingGenerateError",
  "ParsedPhoneNumber",
  "PartReplacementRequestItem",
  "PartialBundleCreateOrderCancellationError",
  "PartitionDefinition",
  "PartnerAccountContact",
  "PartnerAccountEdge",
  "PartnerAccountSearchConnection",
  "PartnerAccountsPagination",
  "PartnerContactInfoPagination",
  "PartnerDistributionCenterConfigRevisionPagination",
  "PartnerDistributionCenterOverrideDayConfig",
  "PartnerDistributionCenterWorkingDayConfig",
  "PartnerExternalSignableDocumentActivity",
  "PartnerFulfillmentOrder",
  "PartnerFulfillmentOrderLineItem",
  "PartnerFulfillmentOrderLineItemProduct",
  "PartnerFulfillmentOrderSource",
  "PartnerFulfillmentPagination",
  "PartnerFulfillmentPerformancePagination",
  "PartnerFulfillmentSource",
  "PartnerInventoryAggregate",
  "PartnerInventoryAggregatePagination",
  "PartnerInventoryFlxpointDataSource",
  "PartnerInventoryFulfillmentChannelData",
  "PartnerInventoryPagination",
  "PartnerModuleConfig",
  "PartnerOrderAlert",
  "PartnerOrderAlertLateAcknowledgeContent",
  "PartnerOrderAlertLateShipmentContent",
  "PartnerOrderAlertTrackingNotUpdatedContent",
  "PartnerOrderConnection",
  "PartnerOrderConsolidationView",
  "PartnerOrderConsolidationViewConnection",
  "PartnerOrderConsolidationViewEdge",
  "PartnerOrderCreditMemoForPreview",
  "PartnerOrderCreditMemoLineItemForPreview",
  "PartnerOrderCreditMemoPagination",
  "PartnerOrderCreditMemoSyncBillDotComRecord",
  "PartnerOrderEdge",
  "PartnerOrderFulfillmentMeta",
  "PartnerOrderInvoiceForPreview",
  "PartnerOrderInvoiceLineItemUnitCostMismatchReasonWithDisplayName",
  "PartnerOrderInvoicePagination",
  "PartnerOrderInvoiceShippingCost",
  "PartnerOrderInvoiceSyncBillDotComRecord",
  "PartnerOrderLineItemCancellation",
  "PartnerOrderLineItemRejectReason",
  "PartnerOrderPagination",
  "PartnerOrderReturnAlert",
  "PartnerOrderReturnCreditMemoDetail",
  "PartnerOrderReturnPagination",
  "PartnerOrderReturnSLAAlert",
  "PartnerOrderReturnShipmentLineItem",
  "PartnerOrderSLAAlert",
  "PartnerOrderShipmentLineItem",
  "PartnerOrderShipmentLineItemPart",
  "PartnerProductCatalogSearchConnection",
  "PartnerProductCatalogSearchEdge",
  "PartnerSellableFulfillmentPerformancePagination",
  "PartnerUsersPagination",
  "PathCommonQuestionSchemaFields",
  "PathCompletedPathGraphFlowRequestEvent",
  "PathConditionAttributeEnumDeclaration",
  "PathConditionAttributeOperand",
  "PathConditionEnumDefinition",
  "PathConditionEnumDefinitionItem",
  "PathConditionExperimentOperand",
  "PathConditionLiteralBigDecimalOperand",
  "PathConditionLiteralBigDecimalRangeOperand",
  "PathConditionLiteralEnumOperand",
  "PathConditionLiteralLongOperand",
  "PathConditionLiteralLongRangeOperand",
  "PathConditionLiteralStringOperand",
  "PathConditionOperandValueType",
  "PathConditionOperator",
  "PathConditionOperatorTypeDescription",
  "PathConditionStaticEnumDeclaration",
  "PathCopyTokenResult",
  "PathDealRequestSourceFlow",
  "PathDefaultLayoutSchema",
  "PathEdge",
  "PathEditorContext",
  "PathEditorEditSideEffect",
  "PathEditorRichText",
  "PathFeedItemSource",
  "PathGraphContainerAuditStamp",
  "PathGraphFlowInternalDerived",
  "PathInlineLayoutSchema",
  "PathNodeFlowConnection",
  "PathNodeFlowEdge",
  "PathNodeFlowLayout",
  "PathPort",
  "PathRequestedByExpertRichCard",
  "PathSchemaDomainTypeDescription",
  "PathSchemaExperimentUsage",
  "PathSchemaFragmentEndPathNodeConfig",
  "PathSchemaFragmentExperimentUsage",
  "PathSchemaFragmentPathNodeConfig",
  "PathSchemaFragmentReferencePathNodeConfig",
  "PathSchemaFragmentReferencePathNodeDynamicExpanderState",
  "PathSchemaFragmentRevisionsPagination",
  "PathSchemaPathNodeConfig",
  "PathSchemaReferencePathNodeConfig",
  "PathSchemaReferencePathNodeDynamicExpanderState",
  "PathSchemaRevisionsPagination",
  "PathSelectionResult",
  "PathSelectionResultV2",
  "PathSelectionRuleDetail",
  "PathStaticAttributeValue",
  "PathTemplateText",
  "PathTextViewModel",
  "PayComponentCategoryCommissionRule",
  "PayComponentCategoryCommissionSummary",
  "PayComponentCommissionBonusRate",
  "PayComponentCommissionRule",
  "PayComponentCommissionSummary",
  "PayComponentCurationRecordingSummary",
  "PayComponentDebitSummary",
  "PayComponentEngagementPaySummary",
  "PayComponentExpertReferralAccumulativeSalesRule",
  "PayComponentExpertReferralAccumulativeSalesSummary",
  "PayComponentExpertReferralFirstSaleRule",
  "PayComponentExpertReferralFirstSaleSummary",
  "PayComponentExpertServiceRule",
  "PayComponentExpertServiceSummary",
  "PayComponentFeedProductCommissionRule",
  "PayComponentFeedProductCommissionSummary",
  "PayComponentFinalEvalSummary",
  "PayComponentFirstShiftsGuaranteedRule",
  "PayComponentFirstShiftsGuaranteedSummary",
  "PayComponentGigSummary",
  "PayComponentHourlyOnCallRule",
  "PayComponentHourlyOnCallSummary",
  "PayComponentManualAdjustmentSummary",
  "PayComponentMarginCommissionBonusRate",
  "PayComponentMarginCommissionRule",
  "PayComponentMarginCommissionSummary",
  "PayComponentMilestonePaymentSummary",
  "PayComponentPreviousBalanceSummary",
  "PayComponentQualityConversationRule",
  "PayComponentQualityConversationSummary",
  "PayComponentReferralFirstOrderRule",
  "PayComponentReferralFirstOrderSummary",
  "PayComponentRevenueBonusSummary",
  "PayComponentTeamLeadCommissionRule",
  "PayComponentTeamLeadCommissionSummary",
  "PayComponentTipRule",
  "PayComponentTipSummary",
  "PayForOrderPayload",
  "PayGigPayload",
  "PayLineItemCategoryCommissionSource",
  "PayLineItemCommissionSource",
  "PayLineItemCurationVideoRecordingSource",
  "PayLineItemDebitSource",
  "PayLineItemEngagementPaySource",
  "PayLineItemExpertReferralAccumulativeSalesSource",
  "PayLineItemExpertReferralFirstSaleSource",
  "PayLineItemExpertServiceSource",
  "PayLineItemFeedProductCommissionSource",
  "PayLineItemFinalEvalSource",
  "PayLineItemFirstShiftsGuaranteedSource",
  "PayLineItemGigSource",
  "PayLineItemHourlyOnCallSource",
  "PayLineItemLeadDetails",
  "PayLineItemManualAdjustmentSource",
  "PayLineItemMarginCommissionSource",
  "PayLineItemMilestonePaymentSource",
  "PayLineItemOrderDetails",
  "PayLineItemPreviousBalanceSource",
  "PayLineItemQualityConversationSource",
  "PayLineItemReferralFirstOrderSource",
  "PayLineItemRevenueBonusSource",
  "PayLineItemTeamLeadCommissionRate",
  "PayLineItemTeamLeadCommissionSource",
  "PayLineItemTipSource",
  "PayLineItemsPagination",
  "PayPerLeadDailySummary",
  "PayPeriod",
  "PayPeriodExpertMetrics",
  "PayStructuresPagination",
  "PayableActivityEntriesPagination",
  "PayableActivityEntryUploadError",
  "PayableActivityEntryUploadTaskPayload",
  "PayableMilestoneConsumerMessageQualityConversationEvent",
  "PayableMilestoneConsumerMessageQualityConversationGoal",
  "PayableMilestoneContactLeadEvent",
  "PayableMilestoneContactLeadGoal",
  "PayableMilestoneCurationEvent",
  "PayableMilestoneCurationGoal",
  "PayableMilestoneCurationVideoEvent",
  "PayableMilestoneCurationVideoGoal",
  "PayableMilestoneGoalTracking",
  "PayableMilestoneLeadEngagementPayEvent",
  "PayableMilestoneLeadEngagementPayGoal",
  "PayableMilestoneOrderEvent",
  "PayableMilestoneOrderGoal",
  "PayableMilestonePayoutEvent",
  "PayableMilestonePayoutGoal",
  "PayableMilestoneQCOrPhoneCallEvent",
  "PayableMilestoneQCOrPhoneCallGoal",
  "PayableMilestoneQualityConversationEvent",
  "PayableMilestoneQualityConversationGoal",
  "PayableMilestoneRankEvent",
  "PayableMilestoneRankGoal",
  "PayableMilestoneShiftEvent",
  "PayableMilestoneShiftGoal",
  "PayableMilestoneTracking",
  "Payment3DSecure",
  "PaymentBlockedPayGigError",
  "PaymentCard",
  "PaymentScheduleEntry",
  "PaymentTermEntry",
  "PaymentVerificationRequestRichCard",
  "PayoutRollupSummary",
  "PayoutTotal",
  "PayoutsPagination",
  "PaypalPaymentEntity",
  "PendingChangeExistsChangeOrderError",
  "Period",
  "PersistMediaPayload",
  "PersistentAdmonitionPathNodeConfig",
  "PhoneCallExercise",
  "PhoneCallExerciseTracking",
  "PhoneCallRequirement",
  "PhoneCallSummary",
  "PickUpCallPayload",
  "PiplAddress",
  "PiplEducation",
  "PiplName",
  "PiplPosition",
  "PixelFiredEvent",
  "PlainTextHasEmptyFieldCreateCategoryAssetRevisionError",
  "PlainTextHasEmptyFieldUpdateCategoryAssetRevisionError",
  "PopularExpertCuratedItem",
  "PopularExpertCuratedItemConnection",
  "PopularExpertCuratedItemEdge",
  "PopularExpertCuratedItemFromSimilarUserSystemCuratedItemSource",
  "PostFailedRefundOrderStoreCreditError",
  "PostPublishNotEnabledApprovePostPublishGigSubmissionRevisionError",
  "PostPublishUpdatePayload",
  "PostRewardFailureRewardCreditClaimError",
  "PostalAddress",
  "PredictNextMessageForLeadPayload",
  "PredictedNextMessageForLeadPayload",
  "PrepareApprovalRequestSubmitDraftPayload",
  "PrepareExpertLeadCampaignForEditCopyNextVersionEmailFailedError",
  "PrepareExpertLeadCampaignForEditPayload",
  "PreparePrefillSmsConversationStarterPathPayload",
  "PreviewExternalAdRulePayload",
  "PreviewPartnerOrderCreditMemoPayload",
  "PreviewPartnerOrderInvoicePayload",
  "PriceMatchRequestRetailPrice",
  "PriceMatchRequestRichCard",
  "ProConAttributeSchemaMetadata",
  "ProcessGigSubmissionProductVideoMediaIdDoesNotExistError",
  "ProcessGigSubmissionProductVideoNotInDraftStateError",
  "ProcessGigSubmissionProductVideoNotVideoGigError",
  "ProcessGigSubmissionProductVideoOnProcessingAlreadyError",
  "ProcessGigSubmissionProductVideoPayload",
  "ProcessGigSubmissionProductVideoProcessFailedError",
  "ProcessProductVideoTranscriptInArchivedStateError",
  "ProcessProductVideoTranscriptMediaTranscriptDoesNotExistError",
  "ProcessProductVideoTranscriptPayload",
  "ProcessProductVideoTranscriptPrimarySellableDoesNotExistError",
  "ProcessProductVideoTranscriptProcessFailedError",
  "ProductAssortmentImportTaskError",
  "ProductAssortmentImportTaskPayload",
  "ProductAttributeReviewRequestDetail",
  "ProductAttributeReviewSubmissionRevisionDetail",
  "ProductBundleAmountPriceAdjustmentRule",
  "ProductBundleDraftExistsError",
  "ProductBundlePublishDerivedCategoryError",
  "ProductBundlePublishNoComponentError",
  "ProductBundlePublishNoSellableInComponentError",
  "ProductBundlePublishNotReadyError",
  "ProductBundleRatePriceAdjustmentRule",
  "ProductBundleReorderMissedComponentError",
  "ProductBundleUpdateAttributeValidationError",
  "ProductBundleUpdateComponentNotFoundError",
  "ProductBundleUpdateDerivedCategoryError",
  "ProductBundleUpdateNotInDraftError",
  "ProductCatalogAttributeMatrix",
  "ProductCatalogAttributeMatrixItem",
  "ProductCatalogGroupInvalidAttribute",
  "ProductCatalogGroupSearchConnection",
  "ProductCatalogGroupSearchEdge",
  "ProductCatalogInvalidAttribute",
  "ProductCatalogPriceSchedule",
  "ProductCatalogPricingScheduleLineBulkEditTaskPayload",
  "ProductCatalogSearchConnection",
  "ProductCatalogSearchEdge",
  "ProductCatalogTemplateShoppableAttribute",
  "ProductComparisonVideoPagination",
  "ProductCostCorrectionUploadTaskError",
  "ProductCostCorrectionUploadTaskPayload",
  "ProductCustomizationAddOnFeatureSelection",
  "ProductCustomizationAttributeEnumOption",
  "ProductCustomizationAttributeFeatureSelection",
  "ProductCustomizationAttributeIntegerRangeOption",
  "ProductCustomizationDraftExistsError",
  "ProductCustomizationFeaturePriceAdjustment",
  "ProductCustomizationProductFeatureOption",
  "ProductCustomizationProductFeatureSelection",
  "ProductCustomizationPublishNoFeatureError",
  "ProductCustomizationPublishNoFulfillmentChannelError",
  "ProductCustomizationPublishNoOptionInFeatureError",
  "ProductCustomizationPublishNotReadyError",
  "ProductCustomizationReorderMissedFeatureError",
  "ProductCustomizationReorderMissedFeatureGroupError",
  "ProductCustomizationRevisionPagination",
  "ProductCustomizationSelectedFeature",
  "ProductCustomizationSelection",
  "ProductCustomizationUpdateFeatureGroupNotFoundError",
  "ProductCustomizationUpdateFeatureNotFoundError",
  "ProductCustomizationUpdateFeatureTypeChangedError",
  "ProductCustomizationUpdateIncompatibleAttributeOptionTypeError",
  "ProductCustomizationUpdateNotInDraftError",
  "ProductDealTerms",
  "ProductDescriptionRequestDetail",
  "ProductDescriptionSubmissionRevisionDetail",
  "ProductInventoryDimension",
  "ProductInventoryWeight",
  "ProductQuestionGenerationTaskPayload",
  "ProductQuestionGigSource",
  "ProductQuestionLLMLeadSource",
  "ProductQuestionTheme",
  "ProductQuickReviewPagination",
  "ProductQuickReviewProsAndCons",
  "ProductQuickReviewRating",
  "ProductQuickReviewRequestDetail",
  "ProductQuickReviewSubmissionRevisionDetail",
  "ProductRatingAttributeSchemaMetadata",
  "ProductRecommendationPathCompletedActionPathNodeConfig",
  "ProductRecommendationSuccessRedirectPathNodeFlowViewModelSuccess",
  "ProductRecommendationsWithExplanationRichCard",
  "ProductReturnTicket",
  "ProductReviewApplicationDetail",
  "ProductReviewGroupRequestDetail",
  "ProductReviewGroupSubmissionRevisionDetail",
  "ProductReviewRequestDetail",
  "ProductReviewSubmissionRevisionDetail",
  "ProductUsageInfo",
  "ProductWithPosition",
  "ProductsOnInventoryView",
  "ProductsOnInventoryViewSearchConnection",
  "ProductsOnInventoryViewSearchEdge",
  "ProfileHighlight",
  "PromotionClaimUrl",
  "PromotionDisabled",
  "PromotionEdge",
  "PromotionSelectionRuleRevisionPagination",
  "PromotionSourceClaim",
  "PromotionSourceExternalIntegration",
  "PromotionSourceFirstPurchase",
  "PromotionSourceMembership",
  "PromotionSourceReferee",
  "PromotionSourceReferrer",
  "PromotionSourceRegistration",
  "PromotionSourceSocialSharing",
  "PromotionUseCase",
  "PromotionsConnection",
  "PromotionsPagination",
  "PromptCompletionTaskPayload",
  "PromptCompletionTaskResult",
  "PromptCompletionTaskTemplateVariable",
  "PromptCompletionTaskTemplateVariablePayload",
  "PromptMessageTemplate",
  "PromptTemplateRevisionsPagination",
  "ProsAndConsErrorExpertCuratedItemNoteEditError",
  "PublishAffiliateAdGroupPayload",
  "PublishAffiliateAdPayload",
  "PublishAffiliateCampaignRevisionPayload",
  "PublishAffiliateSourceRevisionPayload",
  "PublishAndPayGigPayload",
  "PublishApprovalRequestActivityEntryDetail",
  "PublishApprovalRequestPayload",
  "PublishApprovalRequestPublishAndPayGigError",
  "PublishArticleRevisionPayload",
  "PublishAttributeSchemaRevisionPayload",
  "PublishBrandProfileRevisionPayload",
  "PublishCampaignAudiencePayload",
  "PublishCategoryAttributeChangeRevisionPayload",
  "PublishCategoryExpertPerformanceSettingRevisionPayload",
  "PublishExperimentMetricRevisionNotReadyError",
  "PublishExperimentMetricRevisionPayload",
  "PublishExperimentRevisionPayload",
  "PublishExpertCuratedItemNotePayload",
  "PublishExpertHiringClassPayload",
  "PublishExpertTestedProductAlreadyPublishedError",
  "PublishExpertTestedProductAttributeIsNotAvailableError",
  "PublishExpertTestedProductCreateGigSubmissionFailureError",
  "PublishExpertTestedProductExceedMaxGigClaimLimitForProductError",
  "PublishExpertTestedProductExistsGigRequestCreatedByHimselfBeforeError",
  "PublishExpertTestedProductExistsGigRequestCreatedByOperatorError",
  "PublishExpertTestedProductExistsGigSubmissionNotFinishedError",
  "PublishExpertTestedProductMustFillAllDetailsError",
  "PublishExpertTestedProductNotInEditableStateError",
  "PublishFailurePublishGigContentError",
  "PublishGigApprovalRequestAndContentPayload",
  "PublishGigContentPayload",
  "PublishImportedProductCatalogsPayload",
  "PublishInfluencerCuratedListPayload",
  "PublishMilestonePayPlanNoMilestoneError",
  "PublishMilestonePayPlanNotReadyError",
  "PublishMilestonePayPlanRevisionPayload",
  "PublishProductBundleRevisionPayload",
  "PublishProductCatalogExportConfigNotReadyError",
  "PublishProductCatalogExportConfigRevisionPayload",
  "PublishProductCatalogFromSellablesPayload",
  "PublishProductCatalogGroupFromSellablesPayload",
  "PublishProductCatalogGroupRevisionsPayload",
  "PublishProductCatalogRevisionsPayload",
  "PublishProductCatalogTemplateNotReadyError",
  "PublishProductCatalogTemplateRevisionPayload",
  "PublishProductCatalogsAndGroupsPayload",
  "PublishProductCatalogsBySearchPayload",
  "PublishProductComparisonVideoPayload",
  "PublishProductCustomizationRevisionPayload",
  "PublishPromptTemplateRevisionPayload",
  "PublishPublisherRevisionNotReadyError",
  "PublishPublisherRevisionPayload",
  "PublishPurchaseOrderShipmentRevisionPayload",
  "PublishRevenueBonusPlanPayload",
  "PublishRewardCreditGrantTemplateRevisionPayload",
  "PublishSavedExpertCuratedItemsPayload",
  "PublishSearchQueryRewriteRulePayload",
  "PublishSellableEditorReviewRevisionPayload",
  "PublishSellableEditorialTopListRevisionPayload",
  "PublishSellableExpertOwnerReviewRevisionPayload",
  "PublishSellableExpertiseRulePayload",
  "PublishSuggestedActionMissingTagError",
  "PublishSuggestedActionNotReadyError",
  "PublishSuggestedActionRevisionPayload",
  "PublishSuggestedMessageMissingTagError",
  "PublishSuggestedMessageNotReadyError",
  "PublishSuggestedMessageRevisionPayload",
  "PublishTemplateDataSourceRevisionPayload",
  "PublishTenantRevisionNotReadyError",
  "PublishTenantRevisionPayload",
  "PublishVideoContentRevisionPayload",
  "PublishedVariantsProductCatalogGroupArchiveRevisionError",
  "PublisherCommissionRate",
  "PublisherOrderReferralPayLineItemSource",
  "PublisherPayLineItemPagination",
  "PublisherRevisionPagination",
  "PurchaseJointBusinessPlanEditDuplicatedNameError",
  "PurchaseJointBusinessPlanEditInvalidTimeRangeError",
  "PurchaseJointBusinessPlanLineItemConnection",
  "PurchaseJointBusinessPlanLineItemCostDeductionApplyResult",
  "PurchaseJointBusinessPlanLineItemEdge",
  "PurchaseJointBusinessPlanPagination",
  "PurchaseJointBusinessPlanTaskRecord",
  "PurchaseOrderConsolidationViewEdge",
  "PurchaseOrderConsolidationViewPagination",
  "PurchaseOrderConsolidationViewSearchConnection",
  "PurchaseOrderConsolidationViewSearchGroupedEdges",
  "PurchaseOrderIntegrationConfig",
  "PurchaseOrderInvoiceEditDuplicatedPurchaseOrderReceiptLineItemsError",
  "PurchaseOrderInvoiceEditInvalidActualFreightCostError",
  "PurchaseOrderInvoiceEditInvalidPurchaseOrderReceiptLineItemError",
  "PurchaseOrderInvoiceImportTaskPayload",
  "PurchaseOrderInvoiceLineItemImportError",
  "PurchaseOrderInvoiceLineItemsImportPayload",
  "PurchaseOrderInvoicePagination",
  "PurchaseOrderManagementExceptionInventoryCOGSContent",
  "PurchaseOrderManagementExceptionInventoryFlexeMismatchContent",
  "PurchaseOrderManagementExceptionLineItem",
  "PurchaseOrderManagementExceptionMissingCatalogContent",
  "PurchaseOrderManagementExceptionNoInvoiceContent",
  "PurchaseOrderManagementExceptionPagination",
  "PurchaseOrderManagementExceptionShipmentOverdueContent",
  "PurchaseOrderNetDownEditDuplicatedNameError",
  "PurchaseOrderNetDownEditInvalidRebateReceivedDateError",
  "PurchaseOrderNetDownNotSupportedVendorError",
  "PurchaseOrderNetDownPagination",
  "PurchaseOrderPagination",
  "PurchaseOrderReceiptEditDuplicatedPurchaseOrderShipmentLineItemsError",
  "PurchaseOrderReceiptEditInvalidPurchaseOrderShipmentLineItemError",
  "PurchaseOrderReceiptImportError",
  "PurchaseOrderReceiptImportPayload",
  "PurchaseOrderReceiptImportTaskPayload",
  "PurchaseOrderReceiptLineItemDamaged",
  "PurchaseOrderReceiptLineItemReceived",
  "PurchaseOrderReceiptPagination",
  "PurchaseOrderReceiptsEditInvalidActualShipmentArriveAtError",
  "PurchaseOrderRevisionEditInvalidRequestShipAtError",
  "PurchaseOrderRevisionEditNotInAwaitingAcknowledgementStateError",
  "PurchaseOrderRevisionFeedback",
  "PurchaseOrderRevisionForPartnerPagination",
  "PurchaseOrderRevisionLineItemFeedback",
  "PurchaseOrderRevisionPagination",
  "PurchaseOrderShipmentLineItemImportError",
  "PurchaseOrderShipmentRevisionImportPayload",
  "PurchaseOrderShipmentRevisionImportTaskPayload",
  "PurchaseOrderShipmentRevisionPagination",
  "PurchasePromotionCreditEditDuplicatedNameError",
  "PurchasePromotionCreditEditInvalidPromoAmountError",
  "PurchasePromotionCreditPagination",
  "PurchaseRequestRevisionPagination",
  "PurchasedOrderCountNotEligibleForPromotionReason",
  "PushNotificationLogsPagination",
  "PushNotificationTemplate",
  "QualityScoreNeedsImprovementProductCatalogGroupPublishError",
  "QualityScoreNeedsImprovementProductCatalogPublishError",
  "Query",
  "QuestionAndAnswerRequestDetail",
  "QuestionAndAnswerSubmissionRevisionDetail",
  "QuestionsPathNodeConfig",
  "QuickReplyExercise",
  "QuickReplyExerciseTracking",
  "QuickReplyTag",
  "QuickReplyTemplateVariable",
  "QuickReplyTemplateVariablePayload",
  "RPALExclusion",
  "RatingChangedActivity",
  "RawAttribute",
  "RawSellableAttribute",
  "RawSellableAttributeReference",
  "RawSellableAttributeValues",
  "RawSellableSearchConnection",
  "RawSellableSearchEdge",
  "RawSellableSourcePagination",
  "RawSellableSourceScrapinghubSpider",
  "RawSellableSourceScrapinghubSpiderArgument",
  "RawSellableStandardizationAttributeValueFieldMeta",
  "RawSellableStandardizationAttributeVariationDimensionFieldMeta",
  "RawSellableStandardizationCopyAttributeValueAction",
  "RawSellableStandardizationDeactivateAction",
  "RawSellableStandardizationHideFromConsumerSearchAction",
  "RawSellableStandardizationMarkIgnoreSpecAction",
  "RawSellableStandardizationMarkNoneVariantAction",
  "RawSellableStandardizationMarkOutOfStockAction",
  "RawSellableStandardizationMeta",
  "RawSellableStandardizationPredefinedFieldMeta",
  "RawSellableStandardizationRemoveAttributeAction",
  "RawSellableStandardizationRenameAttributeAction",
  "RawSellableStandardizationReplaceAttributeValueAction",
  "RawSellableStandardizationResultSearchConnection",
  "RawSellableStandardizationResultSearchEdge",
  "RawSellableStandardizationRuleBinaryCondition",
  "RawSellableStandardizationRuleConstantCondition",
  "RawSellableStandardizationRuleEntityFieldReference",
  "RawSellableStandardizationRuleFieldOperator",
  "RawSellableStandardizationRuleLiteralBooleanValue",
  "RawSellableStandardizationRuleLiteralDecimalValue",
  "RawSellableStandardizationRuleLiteralIdListValue",
  "RawSellableStandardizationRuleLiteralIdValue",
  "RawSellableStandardizationRuleLiteralIntegerValue",
  "RawSellableStandardizationRuleLiteralStringListValue",
  "RawSellableStandardizationRuleLiteralStringValue",
  "RawSellableStandardizationRuleLogicalCondition",
  "RawSellableStandardizationRuleResult",
  "RawSellableStandardizationSetAttributeValueAction",
  "RawSellableStandardizationSetAttributeVariationDimensionAction",
  "RawSellableStandardizationSetBrandAction",
  "RawSellableStandardizationSetBrandIdAction",
  "RawSellableStandardizationSetCategoryAction",
  "RawSellableStandardizationSetDescriptionAction",
  "RawSellableStandardizationSetGtinAction",
  "RawSellableStandardizationSetMpnAction",
  "RawSellableStandardizationSetTitleAction",
  "RawSellableStandardizationSetVariationGroupReferenceAction",
  "RawSellableStandardizationSetVariationGroupTitleAction",
  "ReassignToSameExpertInitiateLeadTransferRequestError",
  "RecentCurationCountSocialProof",
  "RecentExpertCuratedItemFromSimilarUserSystemCuratedItemSource",
  "RecentExpertCuratedItemGroupConnection",
  "RecentExpertCuratedItemGroupEdge",
  "RecipientEmailInvalidGiftTipStoreCreditError",
  "RecommendExpertPayload",
  "RecommendedExpert",
  "RecommendedExpertsPayload",
  "RecommendedGigRequestSearch",
  "RecommendedGigRequestSearchQuery",
  "RecommendedList",
  "RecommendedListConnection",
  "RecommendedListDefinitionConnection",
  "RecommendedListDefinitionEdge",
  "RecommendedListDisplayFacet",
  "RecommendedListEdge",
  "RecommendedListSellableConnection",
  "RecommendedListSellableEdge",
  "RecommendedListSortOrder",
  "RecordProductClickAttributionEventPayload",
  "RecursiveParentUpdateGigRequestError",
  "RecursiveUsageApprovableEntityDependencyResolutionError",
  "RedeemGiftCardPayload",
  "RedirectPathNodeConfig",
  "RedirectPathNodeConfigAbsoluteUrlDestination",
  "RedirectPathNodeConfigConsumerInboxDestination",
  "RedirectPathNodeConfigConsumerUrlDestination",
  "RedirectPathNodeConfigExpertApplyFinishDestination",
  "RedirectPathNodeConfigExpertKnowledgeTestFinishDestination",
  "RedirectPathNodeConfigFeedPostDestination",
  "RedirectPathNodeConfigRecommendedProductsDestination",
  "RedirectPathNodeConfigReturnToPreviousContextDestination",
  "RedirectPathNodeConfigSurveyFinishDestination",
  "RedirectPathNodeConfigUserMilestoneFinishDestination",
  "RedirectQueryMappingRule",
  "ReengagementLinkToken",
  "ReferralCodeSharePayload",
  "ReferralSourceConsumerGeneric",
  "ReferralSourceConsumerToExpert",
  "ReferralSourceExpertDirect",
  "ReferrerAlreadyPaidExpertEditError",
  "ReferrerAlreadySetExpertEditError",
  "RefreshAccessTokenPayload",
  "RefreshAllCategoryHistoricalMarginsPayload",
  "RefreshSellablePriceSourcePayload",
  "RefundAmountAdjustedActivity",
  "RefundAmountExceededChangeOrderError",
  "RefundMerchantOrderPayload",
  "RefundOrderStoreCreditPayload",
  "RefundPartnerOrderPayload",
  "RefundRequestedActivity",
  "RefundStatusUpdateActivity",
  "RegisterCustomerPayload",
  "RegisterFirebaseCloudMessagingTokenPayload",
  "RegisterWebPushSubscriptionPayload",
  "RejectApprovalRequestActivityEntryDetail",
  "RejectApprovalRequestPayload",
  "RejectBillableTimeEntriesPayload",
  "RejectExpertAccountChangeRequestPayload",
  "RejectExpertApplicationPayload",
  "RejectExpertCuratedItemNotePayload",
  "RejectGigApplicationPayload",
  "RejectGigSubmissionRevisionPayload",
  "RejectLeadRPALExclusionOverrideRequestAlreadyRejectedError",
  "RejectLeadRPALExclusionOverrideRequestPayload",
  "RejectLeadTransferRequestPayload",
  "RejectPayableActivityEntriesPayload",
  "RejectSellableQuestionCandidatePayload",
  "RejectedGigClaimError",
  "RejectedWithdrawExpertApplicationError",
  "RelativeDate",
  "RelativeDateRange",
  "ReleaseBusinessUserIdentityPayload",
  "ReleaseConsumerIdentityPayload",
  "ReleaseOperatorIdentityPayload",
  "ReleasePartnerUserIdentityPayload",
  "RemoveActionFromPromotionPayload",
  "RemoveCampaignAudienceIntegrationPayload",
  "RemoveCategoryActionPayload",
  "RemoveCategoryNavigationSectionItemPayload",
  "RemoveCategoryNavigationSectionPayload",
  "RemoveConsumerProductReviewReactionPayload",
  "RemoveCuratedItemFromSavedCurationPayload",
  "RemoveDraftEnumEntriesFromAttributeSchemaRevisionPayload",
  "RemoveEmailTemplateDataSourcePayload",
  "RemoveExperimentRevisionMetricPayload",
  "RemoveExperimentRevisionRulePayload",
  "RemoveExperimentRevisionTreatmentPayload",
  "RemoveExpertAwayAutoReplyPayload",
  "RemoveExpertCuratedItemPayload",
  "RemoveExpertSellableCoRecommendationReactionPayload",
  "RemoveExternalOAuthPayload",
  "RemoveFeedItemPayload",
  "RemoveGigUserClaimLimitOverridePayload",
  "RemoveItemsFromExpertCuratedOrderPayload",
  "RemoveItemsFromOrderPayload",
  "RemoveLegsFromFlightPlanPayload",
  "RemoveMetadataFromAttributeSchemaRevisionPayload",
  "RemoveProductBundleComponentPayload",
  "RemoveProductCatalogAttributePayload",
  "RemoveProductCatalogGroupAttributePayload",
  "RemoveProductCatalogGroupRawAttributesPayload",
  "RemoveProductCatalogRawAttributesPayload",
  "RemoveProductCustomizationFeatureGroupPayload",
  "RemoveProductCustomizationFeaturePayload",
  "RemovePromotionFromOrderPayload",
  "RemoveRuleFromPromotionPayload",
  "RemoveSchedulingDemandForecastPayload",
  "RemoveSchedulingUserAvailabilityPayload",
  "RemoveSellableExpertiseRuleMappingPayload",
  "RemoveStoreCreditFromOrderPayload",
  "RemoveSupportFromLeadConversationPayload",
  "RemoveTagPayload",
  "RemoveTemplateChannelPayload",
  "RemoveTemplateDataSourceReferencePayload",
  "RemoveTipFromOrderPayload",
  "RemoveValidatorFromAttributeSchemaRevisionPayload",
  "RenderCommTemplatePayload",
  "RenderEmailTemplatePayload",
  "RenderedEmailContent",
  "RenderedFacebookContent",
  "RenderedInAppContent",
  "RenderedPushNotificationContent",
  "RenderedPushNotificationContentCustomData",
  "RenderedSlackContent",
  "RenderedSmsContent",
  "ReorderActionsInPromotionPayload",
  "ReorderCategoryNavigationSectionItemsPayload",
  "ReorderCategoryNavigationSectionsPayload",
  "ReorderEnumEntriesInAttributeSchemaRevisionPayload",
  "ReorderInfluencerCuratedListsPayload",
  "ReorderProductBundleComponentsPayload",
  "ReorderProductCatalogGroupRawAttributesPayload",
  "ReorderProductCatalogRawAttributesPayload",
  "ReorderProductCustomizationFeatureGroupsPayload",
  "ReorderProductCustomizationFeaturesPayload",
  "ReorderRulesInPromotionPayload",
  "RepeatPurchaseCoOwnerPointsSource",
  "ReplicatePreviousWeekSchedulingDemandForecastStrategy",
  "ReportFeedItemPayload",
  "ReportPredictedLifeTimeValuePayload",
  "RequestConfirmationRichCard",
  "RequestConsumerExpertReviewPayload",
  "RequestExpertAccountChangeActionPathNodeConfig",
  "RequestExpertFeedbackOnSellableRichCard",
  "RequestExpiredGigClaimError",
  "RequestFeedbackOnCuratedItemActivationPayload",
  "RequestPathByExpertPayload",
  "RequestReferralByExpertPayload",
  "RequestSellablePayload",
  "RequestSellablePriceMatchPayload",
  "RequestTimedOutChangeOrderError",
  "RequestUpdatesOnPurchaseOrderRevisionPayload",
  "RequireAuthenticationActionPathNodeConfig",
  "RequireLoginQuestionPathNodeFlowViewModelValidationError",
  "RequireLoginUserRegistrationPathNodeFlowViewModelValidationError",
  "RequiredWordCountNotMetUpdateGigSubmissionRevisionError",
  "RerunExpertApplicationTaskPayload",
  "ResendExpertCuratedItemsPayload",
  "ResendGiftCardPayload",
  "ResendOrderPaymentVerificationPayload",
  "ResetAllShiftPenaltiesPayload",
  "ResetConfigPayload",
  "ResetDraftTriggerPayload",
  "ResetExpertCuratedItemNotePayload",
  "ResetPasswordPayload",
  "ResetSavedExpertCuratedItemsPayload",
  "ResolveJiraTicketError",
  "ResolveJiraTicketPayload",
  "ResolvedTextLinkifyRule",
  "ResubmitFrontendTaskPayload",
  "RetryConversionResult",
  "RetryProductComparisonVideoProcessingArticleCreatedError",
  "RetryProductComparisonVideoProcessingNotInActiveStateError",
  "RetryProductComparisonVideoProcessingOnProcessingError",
  "RetryProductComparisonVideoProcessingPayload",
  "ReturnDestinationChangedActivity",
  "ReturnShipmentTrackingRemovedActivity",
  "ReturnShipmentTrackingUpdatedActivity",
  "ReturnTicket",
  "ReturnToPreviousContextRedirectPathNodeFlowViewModelSuccess",
  "ReturningUserConnectionOfferedRichCard",
  "RevenueBonusPlanRevisionsPagination",
  "RevenueBonusProgress",
  "RevenueBonusTier",
  "ReviewPayableEntryAlreadyPaidError",
  "ReviewRatingAndCount",
  "ReviewSummary",
  "ReviewSummaryByRating",
  "RevisionExistsCopyVendorAgreementRevisionError",
  "RevisionExistsCreatePathSchemaRevisionError",
  "RewardCreditGrantTemplateUsageLimit",
  "RewardCreditPromotionActionLine",
  "RichCardExercise",
  "RichCardExerciseTracking",
  "RichPathGraph",
  "RingingCallCountChange",
  "RuleDefinition",
  "RunSchedulingDemandForecastsPayload",
  "RunShiftAssignmentsPayload",
  "RunShiftAssignmentsWithRuleSetPayload",
  "SameDCTransferCreateInternalOrderError",
  "SameSellableProductComparisonVideoError",
  "SameSourceTargetExpertInitiateLeadTransferRequestError",
  "SaveConsumerSellableReactionPayload",
  "SaveExpertTestedProductPayload",
  "SaveExpertTestedProductSaveFailedError",
  "SaveMediaFailedProductComparisonVideoError",
  "SaveReviewForSuggestedAlternativeSellablePayload",
  "SaveSellableForReviewPayload",
  "SavedCurationsPagination",
  "SavedExpertCuratedItemsConnection",
  "SavedExpertCuratedItemsEdge",
  "SavedSellableEdge",
  "SavedSellablesConnection",
  "ScheduleEmailResult",
  "ScheduleExpertEmailCancelCampaignFailedError",
  "ScheduleExpertEmailConditionNotMetError",
  "ScheduleExpertEmailCreateCampaignError",
  "ScheduleExpertEmailFailedError",
  "ScheduleSendExpertEmailPayload",
  "SchedulingAvailabilityTeamDailyView",
  "SchedulingDemandForecastDailyView",
  "SchedulingDepartmentChangeInvalidRunTimeError",
  "SchedulingDepartmentChangePendingChangeExistsError",
  "SchedulingPeriod",
  "SchedulingUserAvailabilityDailyView",
  "SchedulingUserSettings",
  "SearchFacetCappedNumericAttributeSchemaMetadata",
  "SearchFacetDecimalScaleAttributeSchemaMetadata",
  "SearchFacetFixedIntegerBucketAttributeSchemaMetadata",
  "SearchFacetHistogramBucketAttributeSchemaMetadata",
  "SearchFacetNameAliasAttributeSchemaMetadata",
  "SearchFedExTransitTimesPayload",
  "SearchFilterFieldAttributeSchemaMetadata",
  "SearchFilterFieldAttributeSchemaMetadataSearchFilter",
  "SearchHighlightContent",
  "SearchHighlightFragment",
  "SearchJiraIssuesResponse",
  "SearchJiraTicketsPayload",
  "SearchQueryRewriteRuleRevisionPagination",
  "SearchQueryUploadTaskPayload",
  "SearchUpsLocationsPayload",
  "SearchUpsTransitTimesPayload",
  "SecondSellableNotAvailableProductComparisonVideoError",
  "SelectExpertCuratedOrderItemsPayload",
  "SelectExpertCuratedOrderVariationOptionPayload",
  "SelfReviewAddConsumerProductReviewReactionError",
  "SelfServeReturnInvalidOperationUpdateOrderReturnError",
  "SelfServeReturnUpsShipment",
  "SellableAttributeFilterNotEligibleForPromotionReason",
  "SellableAttributeMatrix",
  "SellableAttributeMatrixItem",
  "SellableAttributeRelevanceRating",
  "SellableBadgeNotEligibleForPromotionReason",
  "SellableBadgeWithDisplayName",
  "SellableBrandFilterNotEligibleForPromotionReason",
  "SellableCategoryFilterNotEligibleForPromotionReason",
  "SellableCoRecommendation",
  "SellableCoRecommendationCategorySlate",
  "SellableCoRecommendationCategorySlateConnection",
  "SellableCoRecommendationCategorySlateEdge",
  "SellableCoRecommendationConnection",
  "SellableCoRecommendationEdge",
  "SellableCoRecommendationGroup",
  "SellableConsumerWarning",
  "SellableCustomOfferRulesPagination",
  "SellableDeactivationTaskPayload",
  "SellableEditorialCuratedTakeModule",
  "SellableEditorialExpertNoteModule",
  "SellableEditorialExpertQualificationModule",
  "SellableEditorialQAReviewModule",
  "SellableEditorialRatingReviewModule",
  "SellableEditorialReviewProAndConItem",
  "SellableEditorialReviewProsAndCons",
  "SellableEditorialReviewRevisionPagination",
  "SellableEditorialReviewRevisionSearchConnection",
  "SellableEditorialReviewRevisionSearchEdge",
  "SellableEditorialTopListAnswer",
  "SellableEditorialTopListAuthorQualification",
  "SellableEditorialTopListItemPosition",
  "SellableEditorialTopListItemReview",
  "SellableEditorialTopListProductQuickReviewRequestDetail",
  "SellableEditorialTopListProductQuickReviewSubmissionRevisionDetail",
  "SellableEditorialTopListProsAndCons",
  "SellableEditorialTopListRequestDetail",
  "SellableEditorialTopListRevisionPagination",
  "SellableEditorialTopListSubmissionRevisionDetail",
  "SellableEditorialTopListV2RequestDetail",
  "SellableEditorialTopListV2SubmissionRevisionDetail",
  "SellableExpertCuratedItemNoteStatsConnection",
  "SellableExpertCuratedItemNoteStatsEdge",
  "SellableExpertCuratedItemNoteStatsPagination",
  "SellableExpertOwnerReviewPivotRecommendationsPayload",
  "SellableExpertOwnerReviewShoppableAttribute",
  "SellableExpertOwnerReviewShoppableAttributesSummary",
  "SellableExpertOwnerReviewShoppableAttributesSummaryEntry",
  "SellableExpertOwnerReviewShoppableAttributesSummaryPivot",
  "SellableExpertPerformanceSettingImportTaskError",
  "SellableExpertPerformanceSettingImportTaskPayload",
  "SellableExpertPerformanceSettingRevisionPagination",
  "SellableExpertSearchRule",
  "SellableExpertiseAttributeScore",
  "SellableExpertiseRecommendationConnection",
  "SellableExpertiseRecommendationCount",
  "SellableExpertiseRecommendationEdge",
  "SellableExpertiseRuleDraftExistsError",
  "SellableExpertiseRuleIncompleteMappingError",
  "SellableExpertiseRuleMappingNotFoundError",
  "SellableExpertiseRuleMappingSellableAttributeDecimalRangeValue",
  "SellableExpertiseRuleMappingSellableAttributeEnumerationValue",
  "SellableExpertiseRuleMappingSellableAttributeIntegerRangeValue",
  "SellableExpertiseRuleNoRequestAttributeValuesError",
  "SellableExpertiseRuleNoSellableAttributeValuesError",
  "SellableExpertiseRuleNotInDraftError",
  "SellableExpertiseRulePublishCategoryRuleExistsError",
  "SellableExpertiseRulePublishNotReadyError",
  "SellableExpertiseRuleSellableAttributeAddedAlreadyError",
  "SellableExpertiseScore",
  "SellableFilterNotEligibleForPromotionReason",
  "SellableFormattedVariant",
  "SellableFrequentlyBoughtTogetherResult",
  "SellableFulfillmentChannelNotEligibleForPromotionReason",
  "SellableFullPriceNotEligibleForPromotionReason",
  "SellableGigRequestMaxEarnings",
  "SellableGigRequestStats",
  "SellableInventoryItemLevel",
  "SellableInventoryItemPurchaseOrder",
  "SellableInventoryItemUpdatePayload",
  "SellableInventoryUploadTaskPayload",
  "SellableKeywordTypeaheadResult",
  "SellableLLMRecommendationsResult",
  "SellableLMRating",
  "SellableLMRatingAttributeEntry",
  "SellableLMRatingGeneratePromptResult",
  "SellableLMRatingGenerateResult",
  "SellableMetrics",
  "SellableNotAllowedSetSellableStockAlertError",
  "SellableNotFromCatalogGroupProductCatalogGroupPublishFromSellablesError",
  "SellableNotFromCatalogProductCatalogPublishFromSellablesError",
  "SellableNotInCartAddWarrantyToOrderError",
  "SellableOnlineRetailer",
  "SellablePersona",
  "SellablePrice",
  "SellablePriceScheduledPrice",
  "SellablePriceSourceGoogleShoppingSeller",
  "SellablePriceSourceSnapshot",
  "SellablePriceUploadTaskPayload",
  "SellableProductQuickReviewSummary",
  "SellablePromptTemplateParameters",
  "SellableQueryMappingRule",
  "SellableRecommendationCategorySlate",
  "SellableRecommendationCategorySlateConnection",
  "SellableRecommendationCategorySlateEdge",
  "SellableRecommendationConnection",
  "SellableRecommendationEdge",
  "SellableRecommendationSlateMetadata",
  "SellableRelevanceRequestDetail",
  "SellableRelevanceSubmissionRevisionDetail",
  "SellableRequestRichCard",
  "SellableReviewRatingSummary",
  "SellableReviewStats",
  "SellableSearchConnection",
  "SellableSearchEdge",
  "SellableSearchFacetsPayload",
  "SellableSearchPageArticleModule",
  "SellableSearchPageConsumerExpertReviewModule",
  "SellableSearchPageConversationStarterModule",
  "SellableSearchPageMetadata",
  "SellableSearchPageRelatedLinks",
  "SellableSearchPageRelatedSearch",
  "SellableSearchPageRuleRevisionPagination",
  "SellableSearchPageSitemapFacetRule",
  "SellableSearchPageSitemapRuleRevisionPagination",
  "SellableSearchPageSubcategoryTile",
  "SellableSearchPageSubcategoryTileV2",
  "SellableSearchPageTitleCustomTemplate",
  "SellableSearchPageTitleStandardTemplate",
  "SellableSearchPageTopListModule",
  "SellableServiceLocation",
  "SellableSourceExternalId",
  "SellableSourceProductBundle",
  "SellableSourceProductCatalog",
  "SellableSourceProductCatalogGroup",
  "SellableSourceStandardizedSellableGroup",
  "SellableSourceUrl",
  "SellableSpecExplanationEntry",
  "SellableSpecification",
  "SellableTaskError",
  "SellableTopListDefinitionsPagination",
  "SellableTopListItem",
  "SellableTopListItemLLMReview",
  "SellableTopListItemLLMReviewSection",
  "SellableTopListItemPosition",
  "SellableTopListItemSearchPayload",
  "SellableTopListsPagination",
  "SellableTypeaheadPayload",
  "SellableUpdateTaskPayload",
  "SellableUploadTaskPayload",
  "SellableVersusContentGenerationTaskPayload",
  "SellableVersusContentGenerationTaskSellablePair",
  "SellableWithRecommendedVariant",
  "SellablesPagination",
  "SendAboutCuratedMessagePayload",
  "SendEmailTemplateTestMessagePayload",
  "SendExpertCuratedSearchPayload",
  "SendExpertReferralPayload",
  "SendFittingInvitationPayload",
  "SendMembershipOfferToConsumerPayload",
  "SendMobileUpsellPayload",
  "SendPaymentResult",
  "SendRichCardPayload",
  "SendTemplateTestMessagePayload",
  "SendTestPushNotificationPayload",
  "ServiceDealTerms",
  "SetConsumerInitialLeadActionPayload",
  "SetExerciseTrackingCompletedPayload",
  "SetExpertApplicationTaskCompletedPayload",
  "SetExpertAwayAutoReplyPayload",
  "SetExpertCategorySeasonPayload",
  "SetExpertCuratedItemNoteFeaturedPayload",
  "SetExpertGigAuthorPayload",
  "SetExpertGigEditorPayload",
  "SetExpertOffShiftPayload",
  "SetExpertTeamLeadPayload",
  "SetMarketingSmsPreferenceActionPathNodeConfig",
  "SetOrderAutoTipEnabledPayload",
  "SetProductCatalogAttributePayload",
  "SetProductCatalogGroupAttributePayload",
  "SetProductCatalogGroupRawAttributePayload",
  "SetProductCatalogRawAttributePayload",
  "SetSellableLMRatingStatePayload",
  "SetSellableStockAlertPayload",
  "SetSessionBoundPostalAddressPayload",
  "SetShiftAbsenceExcusedPayload",
  "SetUserTaxExemptedPayload",
  "SetWishlistVisibilityPayload",
  "SetupInfluencerPaymentMethodPayload",
  "SetupPaymentSourcePayload",
  "ShiftAllocation",
  "ShiftAssignmentDemandDrivenStrategy",
  "ShiftAssignmentDistribution",
  "ShiftAssignmentExpertSelection",
  "ShiftAssignmentRuleSetsPagination",
  "ShiftAssignmentRunProgress",
  "ShiftAssignmentUserSettingsOverride",
  "ShiftAssignmentUserSummaryPayload",
  "ShiftAttendance",
  "ShiftAttendanceSummary",
  "ShiftAvailabilitySummary",
  "ShiftChangeTooCloseFromShiftStartTime",
  "ShiftClaim",
  "ShiftDailyView",
  "ShiftGiveUpRequest",
  "ShiftSlotAnalytics",
  "ShiftSlotConflictError",
  "ShiftSlotDailyView",
  "ShiftSlotUnsupportedSchedulingUserRoleError",
  "ShiftSlotUserDailyView",
  "ShiftSwapRequest",
  "ShiftTeamDailyView",
  "ShiftTimeInterval",
  "ShiftTimeIntervalDailyView",
  "ShiftTransferRequest",
  "ShiftWeeklyTime",
  "ShipmentNotFoundPartnerOrderReturnError",
  "ShipmentPricingModelPagination",
  "ShipmentPricingOption",
  "ShipmentTracking",
  "ShipmentTrackingRemovedActivity",
  "ShipmentTrackingUpdatedActivity",
  "ShipmentUpdatePayload",
  "ShippingAddressNotSpecifiedSetWishlistVisibilityError",
  "ShippingDelayedNotificationActivity",
  "ShippingEtaComputation",
  "ShippingMethodCost",
  "ShippingOption",
  "ShippingOptionNameAndCost",
  "ShippingRegion",
  "ShippingUpdateForbiddenMerchantReturnError",
  "ShopifyExternalInventoryItemSource",
  "ShopifyFulfillmentOrderSource",
  "ShortProductVideoRequestDetail",
  "ShortProductVideoSubmissionRevisionDetail",
  "ShortenUrlPayload",
  "ShouldNotPayPublishAndPayGigError",
  "SignupFinalEvalShiftPayload",
  "SimilarConsumerMessage",
  "SimilarConsumerMessageWithExpertResponsePayload",
  "SimilarSellablePayload",
  "SimpleCuratedOrderRichCard",
  "SingleContentTemplateSelection",
  "SingleConversationStarterSelection",
  "SingleLocaleTemplateSet",
  "SinglePathSelection",
  "SlackChannelNotFoundBusinessUserTeamEditError",
  "SlackTemplate",
  "SlugExistsCreateCategoryNavigationGroupRevisionError",
  "SlugInUseEditCategoryError",
  "SlugInUseSubmitEditCategoryActionError",
  "SmsTemplate",
  "SnoozeExercise",
  "SnoozeExerciseTracking",
  "SnoozeStep",
  "SocialMediaAccessToken",
  "SocialMediaLink",
  "SourceExpertNotActiveInitiateLeadTransferRequestError",
  "SourceNotEligibleCreateInternalOrderError",
  "SourceTypeNotEligibleForPromotionReason",
  "StandardReturnPolicy",
  "StandardizedSellableScore",
  "StarRatingNumericRenderingStylePathQuestionPathNodeFlowViewModel",
  "StartAiGuestConversationPayload",
  "StartExerciseTrackingPayload",
  "StartExpertConsumerConversationPayload",
  "StartExpertGuestConversationPayload",
  "StartGuestConversationPayload",
  "StartLeadEscalationPayload",
  "StartLessonPayload",
  "StartPathNodeConfig",
  "StartRawSellableSourceIngestionPayload",
  "StartRawSellableStandardizationPayload",
  "StartSmsPromotionRegistrationPayload",
  "StartStandardizationSellableMatchingPayload",
  "StartStandardizedSellableGroupIngestionPayload",
  "StartSystemCuratedItemSurveyPayload",
  "StaticConversationStarterTemplate",
  "StatusChangedActivity",
  "StediGenerateEdisResponse",
  "StringBinaryConditionDefinition",
  "StripeAddress",
  "StripeCodeVerification",
  "StripeOwner",
  "StripeRequiredPaymentAction",
  "StripeRequiredSetupAction",
  "SubjectIdInConditionDefinition",
  "SubmitCreateCategoryActionPayload",
  "SubmitDeactivateCategoryActionPayload",
  "SubmitExpertAccountChangeAlreadyActivatedError",
  "SubmitExpertAccountChangeAlreadyDeactivatedError",
  "SubmitExpertAccountChangeRequestExistsError",
  "SubmitExpertAccountChangeRequestPayload",
  "SubmitExpertBulkUpdatePayload",
  "SubmitExpertHiringFeedbackPayload",
  "SubmitExpertLeadCampaignForReviewDeliveryTimeRequiredError",
  "SubmitExpertLeadCampaignForReviewDeliveryTimeTooEarlyError",
  "SubmitExpertLeadCampaignForReviewNotInDraftStateError",
  "SubmitExpertLeadCampaignForReviewPayload",
  "SubmitExpertLeadCampaignForReviewSubmitFailedError",
  "SubmitForReviewApprovalRequestActivityEntryDetail",
  "SubmitForReviewInApprovalRequestPayload",
  "SubmitGigApplicationPayload",
  "SubmitGigSubmissionRevisionForReviewHasOptionalChildrenClaimedOrBlockedError",
  "SubmitGigSubmissionRevisionForReviewHasRequiredChildrenUnsubmittedError",
  "SubmitGigSubmissionRevisionForReviewPayload",
  "SubmitGigSubmissionRevisionForReviewSubmitFailedError",
  "SubmitMoveCategoryActionPayload",
  "SubmitOrderPayload",
  "SubmitPathNodeFlowInputCommandsPayload",
  "SubmitPromptCompletionTaskPayload",
  "SubmitUpdateCategoryActionPayload",
  "SubmitUpdateCategoryAttributeActionPayload",
  "SuggestExpertCuratedItemNotesConnection",
  "SuggestExpertCuratedItemNotesEdge",
  "SuggestExpertCuratedItemNotesPagination",
  "SuggestExpertCuratedItemsForLeadConnection",
  "SuggestExpertCuratedItemsForLeadEdge",
  "SuggestSnoozeStepMessagesPayload",
  "SuggestedActionDraftExistsError",
  "SuggestedActionNotInDraftStateError",
  "SuggestedActionRevisionsPagination",
  "SuggestedActionServiceLevelAgreement",
  "SuggestedAlternativeSellableConnection",
  "SuggestedAlternativeSellableEdge",
  "SuggestedExpertCuratedItems",
  "SuggestedExpertTaggableRequestAttribute",
  "SuggestedExpertTaggableRequestAttributeValue",
  "SuggestedIndividualExpertCuratedItemProConTypeaheadPayload",
  "SuggestedMessageDraftExistsError",
  "SuggestedMessageNotInDraftStateError",
  "SuggestedMessageRevisionsPagination",
  "SuggestedSellableExpertReview",
  "SuggestedSellableReviewAnswerWithDisplayName",
  "SuggestedShippingGroups",
  "SummarizedAddSellableUserActivity",
  "SummarizedCheckoutStartedUserActivity",
  "SummarizedOrderCompletedUserActivity",
  "SummarizedPaymentInfoEnteredUserActivity",
  "SummarizedRemoveSellableUserActivity",
  "SummarizedSavedSellableUserActivity",
  "SummarizedUnSavedSellableUserActivity",
  "SummarizedViewCartUserActivity",
  "SummarizedViewCuratedListUserActivity",
  "SummarizedViewPublisherPageUserActivity",
  "SummarizedViewSellableUserActivity",
  "SupportConversationCountNotification",
  "SupportConversationEdge",
  "SupportConversationsConnection",
  "SupportTaskEscalation",
  "SupportTaskFolderEvent",
  "SupportTaskListEvent",
  "SupportTasksConnection",
  "SupportTasksEdge",
  "SupportedPaymentMethod",
  "SurveyResponseFinishRedirectPathNodeFlowViewModelSuccess",
  "SurveySummary",
  "SwitchLayoutPathNodeConfig",
  "SyncCampaignAudiencePayload",
  "SyncExternalAdCampaignsPayload",
  "SyncFlxpointOrderInvoicePayload",
  "SyncOrderPayload",
  "SyncPartnerOrderCreditMemoPayload",
  "SyncPartnerOrderInvoicePayload",
  "SyncPurchaseOrderShipmentRevisionPayload",
  "SyncShopifyInventoryPayload",
  "SystemCuratedItemSurveyRichCard",
  "SystemCuratedItemSurveyStats",
  "SystemCuratedItemsRichCard",
  "TagAlreadyPresentError",
  "TagLeadPayload",
  "TagUnknownError",
  "TargetExpertNotActiveInitiateLeadTransferRequestError",
  "TcpaCompliancePathQuestionsDisplayComponentSchema",
  "TcpaComplianceQuestionsDisplayComponentPathNodeFlowViewModel",
  "TemplateCustomQueryDataModel",
  "TemplateDataSourceLookupId",
  "TemplateDataSourceModel",
  "TemplateDataSourceParameter",
  "TemplateDataSourceReference",
  "TemplateDataSourceRevisionPagination",
  "TemplateEdge",
  "TemplateFragmentParameter",
  "TemplateMessageContentQuickView",
  "TemplateMessageDeliveryLogPagination",
  "TemplateMessageFrequencyCapRule",
  "TemplateNamedParameter",
  "TemplateNamedQuery",
  "TemplateNamedQueryDataModel",
  "TemplateNamedQueryMeta",
  "TemplateObjectTemplateDataModel",
  "TemplateSelectionRuleDetail",
  "TemplatesConnection",
  "TenantRevisionPagination",
  "TerminateSystemCuratedItemSurveyPayload",
  "TermsAndConditionsPathQuestionsDisplayComponentSchema",
  "TermsAndConditionsQuestionsDisplayComponentPathNodeFlowViewModel",
  "TestTriggerExecutionPayload",
  "TextCompletionRequest",
  "TextLinkifyRuleArticleTarget",
  "TextLinkifyRuleKeywordLink",
  "TextLinkifyRuleProductListingPageLink",
  "TextPathEditorRichTextContentPart",
  "TextRenderedContent",
  "TicketActivityTimelineItem",
  "TicketAssignedActivity",
  "TicketClosedActivity",
  "TicketComment",
  "TicketCommentAddedActivity",
  "TicketCommentTimelineItem",
  "TicketCreatedActivity",
  "TicketDependencyUpdatedActivity",
  "TicketEdge",
  "TicketIssueSummary",
  "TicketOrderActivityTimelineItem",
  "TicketPickedUpActivity",
  "TicketQueueTransferActivity",
  "TicketReopenedActivity",
  "TicketSearchConnection",
  "TicketSnooze",
  "TicketSnoozeChangedActivity",
  "TicketTeamTransferActivity",
  "TicketTimeline",
  "TicketUnassignedActivity",
  "TicketWithErrorPayload",
  "TicketWorkLog",
  "TicketWorklogTimelineItem",
  "TicketsConnection",
  "TicketsMergedActivity",
  "TicketsPagination",
  "TimeInterval",
  "TimedContinueInterstitialProgressionPathNodeFlowViewModel",
  "TimedContinuePathInterstitialProgressionSchema",
  "TipMethodFixedAmount",
  "TipMethodPercentOfAmount",
  "TipMethodPercentOption",
  "TogglePathEditorNodeExpansionPayload",
  "TopRecommendationAndSimilarUserSystemCuratedItemSource",
  "TopRecommendedListSystemCuratedItemSource",
  "TrackOnlineActivityPayload",
  "TrackingAlreadyExistsMerchantReturnError",
  "TrackingAlreadyExistsPartnerOrderReturnError",
  "TrackingGroupedOrderLineItems",
  "TrackingInquiryTicket",
  "TradeInRequestItem",
  "TransactionRequest",
  "TransactionResponse",
  "TransferCoOwnerPointsPayload",
  "TransferCoOwnerPointsSource",
  "TreatmentPageTemplateSelection",
  "TrialAlreadyCompletedUpdateTrialOrderError",
  "TrialNotEligibleExpertCuratedItemEditError",
  "TrialOrderCostBreakdown",
  "TrialOrderItem",
  "TrialOrderNotFoundUpdateTrialOrderError",
  "TrialOrderUpdatePayload",
  "TriggerActionMeta",
  "TriggerBinaryCondition",
  "TriggerDelayDynamicInterval",
  "TriggerDelayFixedInterval",
  "TriggerDelayIntervalTreatment",
  "TriggerDelayWithExperiment",
  "TriggerDuration",
  "TriggerEdge",
  "TriggerEntityAttributeValue",
  "TriggerEntityDataModelPayload",
  "TriggerEntityFieldMeta",
  "TriggerEntityFieldReference",
  "TriggerEntityMeta",
  "TriggerEventActionPathNodeConfig",
  "TriggerEventMeta",
  "TriggerEventParameterReference",
  "TriggerExecutionPayload",
  "TriggerExecutionsPagination",
  "TriggerExperimentCondition",
  "TriggerExperimentUsage",
  "TriggerFieldOperator",
  "TriggerLiteralAttributes",
  "TriggerLiteralBooleanValue",
  "TriggerLiteralDateValue",
  "TriggerLiteralDecimalValue",
  "TriggerLiteralDurationValue",
  "TriggerLiteralIdListValue",
  "TriggerLiteralIdValue",
  "TriggerLiteralIntegerValue",
  "TriggerLiteralStringListValue",
  "TriggerLiteralStringValue",
  "TriggerLocalTime",
  "TriggerLocalTimeRange",
  "TriggerLogicalCondition",
  "TriggerNamedParameter",
  "TriggerNodeExecution",
  "TriggerParameterMeta",
  "TriggerParent",
  "TriggerSuggestedActionReference",
  "TriggerTemplateTypeReference",
  "TriggerValidationErrors",
  "TriggerWaitEvent",
  "TriggersConnection",
  "UnableToCancelMerchantOrderCreateOrderCancellationError",
  "UnableToCreateAttachmentFulfillmentRequestProcessingError",
  "UnableToCreateFulfillmentEmburseCard",
  "UnableToGenerateConsumerProductReviewLMSummaryGenerateError",
  "UnableToGenerateConsumerProductReviewLMThemeSentimentGenerateError",
  "UnableToPlaceOrder",
  "UnableToSetCatalogIdWithSellable",
  "UnarchiveGigRequestPayload",
  "UnarchiveGigSubmissionAsNewRevisionPayload",
  "UnclaimRevisionPayload",
  "UndefinedAttributeUpsertSellableLMRatingError",
  "UndefinedCategoryAttributeSellableLMRatingGenerateError",
  "UnexpectedDetailTypeAddGiftCardWishlistItemToOrderError",
  "UnexpectedDetailTypeAddSellableWishlistItemToOrderError",
  "UnregisterFirebaseCloudMessagingTokenPayload",
  "UnregisterWebPushSubscriptionPayload",
  "UnsaveSellableForReviewPayload",
  "UnsaveSellablesForReviewPayload",
  "UnshippedPurchaseOrderStats",
  "UnsubscribeEmailInvalidTokenError",
  "UnsubscribeEmailPayload",
  "UnsupportedNoteStateExpertCuratedItemNoteEditError",
  "UntagLeadPayload",
  "UpdateAdHocSellablePricePayload",
  "UpdateAddressPreferencesPayload",
  "UpdateAffiliateAdGroupHasBeenUsedError",
  "UpdateAffiliateAdGroupInvalidStateError",
  "UpdateAffiliateAdGroupLengthExceedsLimitError",
  "UpdateAffiliateAdGroupNotInDraftStateError",
  "UpdateAffiliateAdGroupPayload",
  "UpdateAffiliateAdInvalidStateError",
  "UpdateAffiliateAdLengthExceedsLimitError",
  "UpdateAffiliateAdNotInDraftStateError",
  "UpdateAffiliateAdPayload",
  "UpdateAffiliateCampaignHasBeenUsedError",
  "UpdateAffiliateCampaignLengthExceedsLimitError",
  "UpdateAffiliateCampaignNotInDraftStateError",
  "UpdateAffiliateCampaignRevisionPayload",
  "UpdateAffiliateCampaignSourceNotPublishError",
  "UpdateAffiliateLengthExceedsLimitError",
  "UpdateAffiliatePayload",
  "UpdateAffiliateSlugExistsError",
  "UpdateAffiliateSourceAlreadyExistError",
  "UpdateAffiliateSourceHasBeenUsedError",
  "UpdateAffiliateSourceNotInDraftStateError",
  "UpdateAffiliateSourceRevisionPayload",
  "UpdateAlreadyExistsExpertCuratedItemEditError",
  "UpdateApprovalRequestSubmitDraftPayload",
  "UpdateAttributeSchemaRevisionPayload",
  "UpdateBrandPayload",
  "UpdateBrandProfileRevisionPayload",
  "UpdateBusinessUserPayload",
  "UpdateBusinessUserTeamPayload",
  "UpdateCampaignAudienceIntegrationPayload",
  "UpdateCampaignAudiencePayload",
  "UpdateCampaignPayload",
  "UpdateCategoryAssetRevisionPayload",
  "UpdateCategoryAttributeChangeRevisionHasSameAttributeSchemaAlreadyError",
  "UpdateCategoryAttributeChangeRevisionNotInDraftStateError",
  "UpdateCategoryAttributeChangeRevisionPublishError",
  "UpdateCategoryChangeRevisionNotInDraftStateError",
  "UpdateCategoryChangeRevisionPayload",
  "UpdateCategoryExpertPerformanceSettingRevisionPayload",
  "UpdateCategoryMarginOverridePayload",
  "UpdateCategoryNavigationGroupRevisionPayload",
  "UpdateCategoryNavigationRevisionPayload",
  "UpdateCategoryNavigationSectionItemPayload",
  "UpdateCategoryNavigationSectionPayload",
  "UpdateCategoryRequest",
  "UpdateConfigPayload",
  "UpdateConnectPartnerTransactionPayload",
  "UpdateConsumerExpertReviewPayload",
  "UpdateConsumerProductReviewPayload",
  "UpdateContentSelectionRevisionEngagementDuplicatedError",
  "UpdateContentSelectionRevisionInvalidPathError",
  "UpdateContentSelectionRuleNotInDraftStateError",
  "UpdateContentSelectionRulePayload",
  "UpdateContentSelectionRuleRevisionPayload",
  "UpdateContentSelectionToolComplexExperiment",
  "UpdateContentSelectionToolCreateExperimentFailure",
  "UpdateContentSelectionToolErrorExperiment",
  "UpdateContentSelectionToolNotInDraftStateError",
  "UpdateContentSelectionToolRevisionPayload",
  "UpdateContentSelectionToolUpdateExperimentFailure",
  "UpdateContentSelectionToolUsagesPresent",
  "UpdateConversationStarterSetRevisionNotAllowedStateError",
  "UpdateConversationStarterSetRevisionPayload",
  "UpdateCuratedDistributionCenterNotCuratedError",
  "UpdateCuratedDistributionCenterPayload",
  "UpdateCustomerCallResponseRequestStatusPayload",
  "UpdateDemoProductRequestGenericError",
  "UpdateDemoProductRequestPayload",
  "UpdateDraftArticleRevisionPayload",
  "UpdateDraftSellableEditorialTopListRevisionPayload",
  "UpdateEmailTemplateContentVersionConflictError",
  "UpdateEmailTemplateInvalidAddBlockLocationError",
  "UpdateEmailTemplateInvalidAddContentLocationError",
  "UpdateEmailTemplateInvalidCopyBlockOperationError",
  "UpdateEmailTemplateInvalidDeleteBlockOperationError",
  "UpdateEmailTemplateInvalidMoveBlockOperationError",
  "UpdateEmailTemplateNotInDraftStateError",
  "UpdateEmailTemplateRevisionPayload",
  "UpdateEmailTemplateTemplateTypeAlreadySetError",
  "UpdateEmailTemplateUnsupportedOperationError",
  "UpdateExperimentMetricRevisionNotInDraftStateError",
  "UpdateExperimentMetricRevisionPayload",
  "UpdateExperimentRevisionPayload",
  "UpdateExperimentRevisionRulePayload",
  "UpdateExperimentRevisionTreatmentPayload",
  "UpdateExpertAdvancementRulePayload",
  "UpdateExpertApplicationDeferredPayload",
  "UpdateExpertApplicationPayload",
  "UpdateExpertApplicationTaskStatusPayload",
  "UpdateExpertBulkUpdateTaskPayload",
  "UpdateExpertCategoryCommunicationPayload",
  "UpdateExpertCategoryLeadSettingPayload",
  "UpdateExpertCuratedCustomSellablePayload",
  "UpdateExpertCuratedItemFulfillmentNotesPayload",
  "UpdateExpertCuratedItemNoteAttributesPayload",
  "UpdateExpertCuratedItemNotePayload",
  "UpdateExpertCuratedItemPayload",
  "UpdateExpertCuratedItemRankPayload",
  "UpdateExpertCuratedOrderTermsPayload",
  "UpdateExpertCuratedOrderVariationOptionsPayload",
  "UpdateExpertEmailTemplatePayload",
  "UpdateExpertHiringClassPayload",
  "UpdateExpertHiringCohortPayload",
  "UpdateExpertLeadCampaignConditionNotMetError",
  "UpdateExpertLeadCampaignNotInEditableStateError",
  "UpdateExpertLeadCampaignPayload",
  "UpdateExpertPersonnelNotePayload",
  "UpdateExpertProfilePayload",
  "UpdateExpertReferrerPayload",
  "UpdateExpertSavedLeadSearchPayload",
  "UpdateExpertSellableCoRecommendationReactionPayload",
  "UpdateExpertSpecialCareTagPayload",
  "UpdateExpertTagPayload",
  "UpdateExpertTestedProductConditionNotMetError",
  "UpdateExpertTestedProductNotInEditableStateError",
  "UpdateExpertTestedProductPayload",
  "UpdateExternalAdCampaignCategoryRulePayload",
  "UpdateExternalAdCampaignPayload",
  "UpdateExternalAdCategoryRuleStateNotAllowedStateError",
  "UpdateExternalAdGroupPayload",
  "UpdateFedExBillRevisionExistDiffDataError",
  "UpdateFedExBillRevisionInvalidBilledBaseRate",
  "UpdateFedExBillRevisionInvalidBilledTotalRate",
  "UpdateFedExBillRevisionInvalidBilledWeight",
  "UpdateFedExBillRevisionInvalidDistributionCenter",
  "UpdateFedExBillRevisionNotInDraftStateError",
  "UpdateFedExBillRevisionNotInPublishedStateError",
  "UpdateFedExBillRevisionNotInTaskProcessingError",
  "UpdateFedExBillRevisionPayload",
  "UpdateFedExBillRevisionTaskOverlappedError",
  "UpdateFedExRateCardRevisionEnvelopeAlreadyExistsError",
  "UpdateFedExRateCardRevisionInvalidRoundingPrecisionError",
  "UpdateFedExRateCardRevisionInvalidZoneRateTypeError",
  "UpdateFedExRateCardRevisionLineItemWeightOverlapError",
  "UpdateFedExRateCardRevisionNoLineItemFoundError",
  "UpdateFedExRateCardRevisionNotInDraftStateError",
  "UpdateFedExRateCardRevisionOverlappedEffectiveDateRangeError",
  "UpdateFedExRateCardRevisionPayload",
  "UpdateFedExRateCardRevisionServiceTypeNotSupportedError",
  "UpdateFedExSurchargeRevisionInvalidRateValueError",
  "UpdateFedExSurchargeRevisionInvalidZipCodeNumberError",
  "UpdateFedExSurchargeRevisionNotInDraftStateError",
  "UpdateFedExSurchargeRevisionOverlappedEffectiveDateRangeError",
  "UpdateFedExSurchargeRevisionPayload",
  "UpdateFedExZoneLocatorRevisionLineItemZipCodeOverlapError",
  "UpdateFedExZoneLocatorRevisionNoLineItemFoundError",
  "UpdateFedExZoneLocatorRevisionNotInDraftStateError",
  "UpdateFedExZoneLocatorRevisionPayload",
  "UpdateFeedContentBlocksPayload",
  "UpdateFlxpointOrderInvoiceInvalidPartnerAccountError",
  "UpdateFlxpointOrderInvoiceInvalidStateError",
  "UpdateFlxpointOrderInvoicePayload",
  "UpdateFulfillmentChannelPayload",
  "UpdateFulfillmentShipmentMethodPayload",
  "UpdateFulfillmentTicketPayload",
  "UpdateGiftCardOrderLineItemPayload",
  "UpdateGiftCardWishlistItemPayload",
  "UpdateGigApplicationPayload",
  "UpdateGigRequestPayload",
  "UpdateGigSubmissionRevisionPayload",
  "UpdateGigUserPayload",
  "UpdateGuestConversationSequencePayload",
  "UpdateInfluencerCuratedListPayload",
  "UpdateInfluencerProfilePayload",
  "UpdateInfluencerVanityIdPayload",
  "UpdateInventoryAdjustmentInvalidPurchaseOrderShipmentError",
  "UpdateInventoryAdjustmentPayload",
  "UpdateKnowledgeUserPayload",
  "UpdateLeadNotePayload",
  "UpdateLeadPayload",
  "UpdateLeadRPALExclusionOverrideRequestClosedError",
  "UpdateLeadRPALExclusionOverrideRequestPayload",
  "UpdateLeadSpecialCareTagPayload",
  "UpdateLeadTrainingScenarioPayload",
  "UpdateManualMerchantOrderPayload",
  "UpdateMediaAssetPayload",
  "UpdateMerchantOrderReturnPayload",
  "UpdateMerchantPayload",
  "UpdateMerchantRefundPayload",
  "UpdateMilestonePayPlanRevisionPayload",
  "UpdateNoteForSavedExpertCuratedItemPayload",
  "UpdateOperatorPayload",
  "UpdateOrderExpertContributionsPayload",
  "UpdateOrderPayload",
  "UpdateOrderRefundPayload",
  "UpdateOrderReturnRequestPayload",
  "UpdateOrderShippingAddressPayload",
  "UpdateOrderStatePayload",
  "UpdatePageTemplateAlreadyExistError",
  "UpdatePageTemplateInvalidAddBlockLocationError",
  "UpdatePageTemplateInvalidAddContentOperationError",
  "UpdatePageTemplateInvalidComponentParameterError",
  "UpdatePageTemplateInvalidCopyBlockOperationError",
  "UpdatePageTemplateInvalidDeleteBlockOperationError",
  "UpdatePageTemplateInvalidDeleteContentOperationError",
  "UpdatePageTemplateInvalidMoveBlockOperationError",
  "UpdatePageTemplateInvalidPromotionError",
  "UpdatePageTemplateNotInDraftStateError",
  "UpdatePageTemplateRevisionPayload",
  "UpdatePartReplacementRequestPayload",
  "UpdatePartnerAccountPayload",
  "UpdatePartnerDistributionCenterConfigRevisionDuplicatedNameError",
  "UpdatePartnerDistributionCenterConfigRevisionInvalidSLAValueError",
  "UpdatePartnerDistributionCenterConfigRevisionNotInDraftStateError",
  "UpdatePartnerDistributionCenterConfigRevisionNotReadyError",
  "UpdatePartnerDistributionCenterConfigRevisionPartnerAccountStateNotActiveError",
  "UpdatePartnerDistributionCenterConfigRevisionPayload",
  "UpdatePartnerDistributionCenterGenericError",
  "UpdatePartnerDistributionCenterPayload",
  "UpdatePartnerOrderCreditMemoPayload",
  "UpdatePartnerOrderInvoiceIncorrectCostShipmentsError",
  "UpdatePartnerOrderInvoiceIncorrectInvoicedQuantityLineItemsError",
  "UpdatePartnerOrderInvoiceInvalidPartnerAccountError",
  "UpdatePartnerOrderInvoiceInvalidStateError",
  "UpdatePartnerOrderInvoiceNoCommentForDifferentDropShipFeeError",
  "UpdatePartnerOrderInvoiceNoReasonForDifferentUnitCostError",
  "UpdatePartnerOrderInvoicePayload",
  "UpdatePartnerOrderPayload",
  "UpdatePartnerOrderReturnPayload",
  "UpdatePartnerReturnPayload",
  "UpdatePartnerUserPayload",
  "UpdatePayStructureAssignmentPayload",
  "UpdatePayStructureRevisionPayload",
  "UpdatePaymentManualRefundPayload",
  "UpdatePaymentSourcePayload",
  "UpdatePayoutPayload",
  "UpdatePayrollProcessorTypePayload",
  "UpdateProductBundleComponentPayload",
  "UpdateProductBundleRevisionPayload",
  "UpdateProductCatalogDraftPayload",
  "UpdateProductCatalogExportConfigInvalidAttributeNamesError",
  "UpdateProductCatalogExportConfigInvalidMatrixAttributeNamesError",
  "UpdateProductCatalogExportConfigNotInDraftStateError",
  "UpdateProductCatalogExportConfigRevisionPayload",
  "UpdateProductCatalogGroupDraftPayload",
  "UpdateProductCatalogLaunchSchedulePayload",
  "UpdateProductCatalogTemplateNotInDraftStateError",
  "UpdateProductCatalogTemplateRevisionPayload",
  "UpdateProductComparisonVideoPayload",
  "UpdateProductCustomizationFeatureGroupPayload",
  "UpdateProductCustomizationFeaturePayload",
  "UpdateProductCustomizationRevisionPayload",
  "UpdatePromotionPayload",
  "UpdatePromotionSelectionRuleInvalidPromotionError",
  "UpdatePromotionSelectionRuleNotInDraftStateError",
  "UpdatePromotionSelectionRuleRevisionPayload",
  "UpdatePromptTemplateRevisionPayload",
  "UpdatePublisherPayLineItemPayload",
  "UpdatePublisherRevisionNotInDraftStateError",
  "UpdatePublisherRevisionPayload",
  "UpdatePurchaseJointBusinessPlanCancelledLineItemError",
  "UpdatePurchaseJointBusinessPlanDuplicateLineItemError",
  "UpdatePurchaseJointBusinessPlanInvalidImportActionError",
  "UpdatePurchaseJointBusinessPlanInvalidModificationToAmountError",
  "UpdatePurchaseJointBusinessPlanInvalidProductCatalogError",
  "UpdatePurchaseJointBusinessPlanInvalidQuantityError",
  "UpdatePurchaseJointBusinessPlanInvalidStateError",
  "UpdatePurchaseJointBusinessPlanInvalidTotalAmountError",
  "UpdatePurchaseJointBusinessPlanInvalidUnitPriceDeductionError",
  "UpdatePurchaseJointBusinessPlanLineItemAlreadyCancelledError",
  "UpdatePurchaseJointBusinessPlanLineItemDuplicateProductCatalogError",
  "UpdatePurchaseJointBusinessPlanLineItemInvalidImportActionError",
  "UpdatePurchaseJointBusinessPlanLineItemInvalidPlanStateError",
  "UpdatePurchaseJointBusinessPlanLineItemInvalidProductCatalogError",
  "UpdatePurchaseJointBusinessPlanLineItemInvalidQuantityError",
  "UpdatePurchaseJointBusinessPlanLineItemInvalidUnitPriceDeductionError",
  "UpdatePurchaseJointBusinessPlanLineItemNotFoundError",
  "UpdatePurchaseJointBusinessPlanLineItemPayload",
  "UpdatePurchaseJointBusinessPlanNoActiveLineItemsError",
  "UpdatePurchaseJointBusinessPlanNoLineItemFoundError",
  "UpdatePurchaseJointBusinessPlanPayload",
  "UpdatePurchaseJointBusinessPlanTaskAlreadyProcessedError",
  "UpdatePurchaseJointBusinessPlanTaskStillProcessingError",
  "UpdatePurchaseOrderCancelledLineItemError",
  "UpdatePurchaseOrderCatalogNotPublishedError",
  "UpdatePurchaseOrderConfirmedQuantityGreaterThanRequestError",
  "UpdatePurchaseOrderDuplicatedFriendlyNameError",
  "UpdatePurchaseOrderEmptyAdditionalInvoiceSubReasonError",
  "UpdatePurchaseOrderInvalidAdditionalInvoiceIdError",
  "UpdatePurchaseOrderInvalidCancelByDateError",
  "UpdatePurchaseOrderInvalidConfirmedShipAtError",
  "UpdatePurchaseOrderInvalidDiscountError",
  "UpdatePurchaseOrderInvalidFriendlyNameError",
  "UpdatePurchaseOrderInvalidPurchaseRequestLineItemError",
  "UpdatePurchaseOrderInvalidUnitCostError",
  "UpdatePurchaseOrderInvoiceAlreadyConfirmedError",
  "UpdatePurchaseOrderInvoiceCancelledLineItemError",
  "UpdatePurchaseOrderInvoiceDuplicatedExternalInvoiceNumberError",
  "UpdatePurchaseOrderInvoiceEmptyAdditionalDiscountCommentsError",
  "UpdatePurchaseOrderInvoiceEmptyCommentsError",
  "UpdatePurchaseOrderInvoiceEmptyOtherCostCommentsError",
  "UpdatePurchaseOrderInvoiceInvalidAdditionalDiscountError",
  "UpdatePurchaseOrderInvoiceInvalidAdjustedQuantityError",
  "UpdatePurchaseOrderInvoiceInvalidDiscountError",
  "UpdatePurchaseOrderInvoiceInvalidInvoiceTypeError",
  "UpdatePurchaseOrderInvoiceInvalidInvoicedQuantityError",
  "UpdatePurchaseOrderInvoiceInvalidLineItemIdError",
  "UpdatePurchaseOrderInvoiceInvalidOtherCostError",
  "UpdatePurchaseOrderInvoiceInvalidStateError",
  "UpdatePurchaseOrderInvoiceInvalidUnitCostError",
  "UpdatePurchaseOrderInvoiceInvoiceLineItemAlreadyCreatedError",
  "UpdatePurchaseOrderInvoiceInvoicedAtNotSetError",
  "UpdatePurchaseOrderInvoiceInvoicedQuantityNotMatchError",
  "UpdatePurchaseOrderInvoiceMissingLineItemError",
  "UpdatePurchaseOrderInvoiceNoAdjustmentFoundError",
  "UpdatePurchaseOrderInvoiceNoExternalInvoiceItemFoundError",
  "UpdatePurchaseOrderInvoiceNoLineItemFoundError",
  "UpdatePurchaseOrderInvoicePayload",
  "UpdatePurchaseOrderInvoiceReceiptLineItemAlreadyCreatedInTheSameInvoiceError",
  "UpdatePurchaseOrderLineItemMissingFieldsError",
  "UpdatePurchaseOrderManagementExceptionAlreadyAcknowledgedError",
  "UpdatePurchaseOrderManagementExceptionAlreadyResolvedError",
  "UpdatePurchaseOrderManagementExceptionNoCommentEntryFoundError",
  "UpdatePurchaseOrderManagementExceptionPayload",
  "UpdatePurchaseOrderNetDownAlreadyConfirmedError",
  "UpdatePurchaseOrderNetDownCancelledLineItemError",
  "UpdatePurchaseOrderNetDownDuplicateLineItemError",
  "UpdatePurchaseOrderNetDownInvalidImportActionError",
  "UpdatePurchaseOrderNetDownInvalidProductCatalogError",
  "UpdatePurchaseOrderNetDownInvalidQuantityError",
  "UpdatePurchaseOrderNetDownInvalidStateError",
  "UpdatePurchaseOrderNetDownInvalidUnitPriceDeductionError",
  "UpdatePurchaseOrderNetDownNoActiveLineItemsError",
  "UpdatePurchaseOrderNetDownNoInventoryItemFoundError",
  "UpdatePurchaseOrderNetDownNoLineItemFoundError",
  "UpdatePurchaseOrderNetDownPayload",
  "UpdatePurchaseOrderNoActiveLineItemError",
  "UpdatePurchaseOrderNoLineItemFoundError",
  "UpdatePurchaseOrderNotInModifiableStateError",
  "UpdatePurchaseOrderNotInOpenStateError",
  "UpdatePurchaseOrderPayload",
  "UpdatePurchaseOrderPurchaseOrderIdMissingError",
  "UpdatePurchaseOrderPurchaseRequestLineItemAlreadyCreatedError",
  "UpdatePurchaseOrderReceiptAlreadyCancelledError",
  "UpdatePurchaseOrderReceiptAlreadyInboundedError",
  "UpdatePurchaseOrderReceiptCancelledLineItemError",
  "UpdatePurchaseOrderReceiptInvalidDamagedQuantityError",
  "UpdatePurchaseOrderReceiptInvalidInboundedAtError",
  "UpdatePurchaseOrderReceiptInvalidReceivedQuantityError",
  "UpdatePurchaseOrderReceiptInvalidStateError",
  "UpdatePurchaseOrderReceiptNoLineItemFoundError",
  "UpdatePurchaseOrderReceiptPayload",
  "UpdatePurchaseOrderReceiptShipmentLineItemAlreadyCreatedInTheSameReceiptError",
  "UpdatePurchaseOrderRevisionCancelledLineItemError",
  "UpdatePurchaseOrderRevisionDuplicateLineItemError",
  "UpdatePurchaseOrderRevisionDuplicatedFriendlyNameError",
  "UpdatePurchaseOrderRevisionHasRemainUnresolvedFeedbackError",
  "UpdatePurchaseOrderRevisionInValidProductCatalogIdError",
  "UpdatePurchaseOrderRevisionInValidQuantityError",
  "UpdatePurchaseOrderRevisionInvalidActionError",
  "UpdatePurchaseOrderRevisionInvalidCancelByDateError",
  "UpdatePurchaseOrderRevisionInvalidDiscountError",
  "UpdatePurchaseOrderRevisionInvalidDistributionCenterError",
  "UpdatePurchaseOrderRevisionInvalidFeedbackActionError",
  "UpdatePurchaseOrderRevisionInvalidFriendlyNameError",
  "UpdatePurchaseOrderRevisionInvalidProductCatalogError",
  "UpdatePurchaseOrderRevisionInvalidUnitCostError",
  "UpdatePurchaseOrderRevisionNoAddActionInConfirmStateError",
  "UpdatePurchaseOrderRevisionNoCancelActionInConfirmStateError",
  "UpdatePurchaseOrderRevisionNoLineItemFoundError",
  "UpdatePurchaseOrderRevisionNotInAwaitingAckStateError",
  "UpdatePurchaseOrderRevisionNotInDraftStateError",
  "UpdatePurchaseOrderRevisionNotInModifiableStateError",
  "UpdatePurchaseOrderRevisionNotInModifiableStateForPartnerError",
  "UpdatePurchaseOrderRevisionNotInSubmitForReviewStateError",
  "UpdatePurchaseOrderRevisionNotPublishedError",
  "UpdatePurchaseOrderRevisionPayload",
  "UpdatePurchaseOrderRevisionPublishError",
  "UpdatePurchaseOrderRevisionPurchaseOrderRevisionIdMissingError",
  "UpdatePurchaseOrderRevisionShipStartAtLaterThanEndAtError",
  "UpdatePurchaseOrderRevisionUnableToCancelError",
  "UpdatePurchaseOrderRevisionUnableToCloseError",
  "UpdatePurchaseOrderRevisionUnableToRevertCloseError",
  "UpdatePurchaseOrderRevisionUnknownError",
  "UpdatePurchaseOrderRevisionValidationError",
  "UpdatePurchaseOrderShipmentRevisionAlreadyDeliveredError",
  "UpdatePurchaseOrderShipmentRevisionAlreadyReceivedError",
  "UpdatePurchaseOrderShipmentRevisionCancelledLineItemError",
  "UpdatePurchaseOrderShipmentRevisionDuplicateProductCatalogIdError",
  "UpdatePurchaseOrderShipmentRevisionDuplicatedAdvanceShipNoticeNumberError",
  "UpdatePurchaseOrderShipmentRevisionGenericError",
  "UpdatePurchaseOrderShipmentRevisionInvalidActualShipAtError",
  "UpdatePurchaseOrderShipmentRevisionInvalidDeliveredAtError",
  "UpdatePurchaseOrderShipmentRevisionInvalidEstimatedFreightCostError",
  "UpdatePurchaseOrderShipmentRevisionInvalidPurchaseOrderLineItemError",
  "UpdatePurchaseOrderShipmentRevisionInvalidReceivedAtError",
  "UpdatePurchaseOrderShipmentRevisionInvalidShipmentStateError",
  "UpdatePurchaseOrderShipmentRevisionInvalidShippedQuantityError",
  "UpdatePurchaseOrderShipmentRevisionInvalidStateError",
  "UpdatePurchaseOrderShipmentRevisionLineItemOverQuantityError",
  "UpdatePurchaseOrderShipmentRevisionMustArchiveThroughApprovalError",
  "UpdatePurchaseOrderShipmentRevisionNoLineItemFoundError",
  "UpdatePurchaseOrderShipmentRevisionNotOtherCarrierError",
  "UpdatePurchaseOrderShipmentRevisionNotPublishedError",
  "UpdatePurchaseOrderShipmentRevisionNotSameDCError",
  "UpdatePurchaseOrderShipmentRevisionPOLineItemAlreadyCreatedInTheSameShipmentError",
  "UpdatePurchaseOrderShipmentRevisionPayload",
  "UpdatePurchaseOrderShipmentRevisionPublishError",
  "UpdatePurchaseOrderUnableToCancelError",
  "UpdatePurchasePromotionCreditInvalidStateError",
  "UpdatePurchasePromotionCreditPayload",
  "UpdatePurchaseRequestCancelledLineItemError",
  "UpdatePurchaseRequestCuratedBillingEntityError",
  "UpdatePurchaseRequestDuplicateLineItemError",
  "UpdatePurchaseRequestInvalidDiscountError",
  "UpdatePurchaseRequestInvalidDistributionCenterError",
  "UpdatePurchaseRequestInvalidImportActionError",
  "UpdatePurchaseRequestInvalidProductCatalogError",
  "UpdatePurchaseRequestInvalidQuantityError",
  "UpdatePurchaseRequestInvalidRequestShipAtError",
  "UpdatePurchaseRequestInvalidUnitCostError",
  "UpdatePurchaseRequestNoLineItemFoundError",
  "UpdatePurchaseRequestNotInDraftStateError",
  "UpdatePurchaseRequestRevisionPayload",
  "UpdatePurchaseRequestUnknownError",
  "UpdateQuantityForOrderPayload",
  "UpdateRawSellableSourcePayload",
  "UpdateRawSellableStandardizationRulePayload",
  "UpdateRecommendedListDefinitionPayload",
  "UpdateRegistryOrderPayload",
  "UpdateRevenueBonusPlanPayload",
  "UpdateRewardCreditGrantTemplateRevisionPayload",
  "UpdateSavedCurationCuratedItemPayload",
  "UpdateSavedExpertCuratedItemsPayload",
  "UpdateSchedulingDemandForecastPayload",
  "UpdateSchedulingDepartmentPayload",
  "UpdateSchedulingTypePayload",
  "UpdateSchedulingUserPayload",
  "UpdateSearchQueryRewriteRulePayload",
  "UpdateSellableCustomOfferRulePayload",
  "UpdateSellableEditorReviewRevisionPayload",
  "UpdateSellableEditorialReviewInvalidMoveBlockOperationError",
  "UpdateSellableEditorialReviewInvalidMoveItemOperationError",
  "UpdateSellableEditorialReviewNotInDraftStateError",
  "UpdateSellableExpertOwnerReviewRevisionPayload",
  "UpdateSellableExpertiseRequestPayload",
  "UpdateSellableExpertiseRuleAttributeValuesPayload",
  "UpdateSellableExpertiseRuleMappingPayload",
  "UpdateSellableExpertiseRulePayload",
  "UpdateSellablePayload",
  "UpdateSellableQuestionPayload",
  "UpdateSellableSearchPageRuleFacetAllowAndBlockListedError",
  "UpdateSellableSearchPageRuleNotAllowedStateError",
  "UpdateSellableSearchPageRuleRevisionPayload",
  "UpdateSellableSearchPageSitemapRuleNotAllowedStateError",
  "UpdateSellableSearchPageSitemapRuleRevisionPayload",
  "UpdateSellableTopListPayload",
  "UpdateSellableWishlistItemPayload",
  "UpdateServiceLocationExpertCuratedOrderPayload",
  "UpdateShiftAssignmentRuleSetPayload",
  "UpdateShiftPayload",
  "UpdateShipmentPricingModelPayload",
  "UpdateShippingMethodPayload",
  "UpdateSuggestedActionRevisionPayload",
  "UpdateSuggestedMessageByExpertPayload",
  "UpdateSuggestedMessageRevisionPayload",
  "UpdateSupportConversationPayload",
  "UpdateTemplateChannelPayload",
  "UpdateTemplateDataSourceRevisionPayload",
  "UpdateTemplateFragmentPayload",
  "UpdateTemplatePayload",
  "UpdateTemplateTypePayload",
  "UpdateTemplateTypeReferencePayload",
  "UpdateTenantRevisionNotInDraftStateError",
  "UpdateTenantRevisionPayload",
  "UpdateTextLinkifyRuleInvalidStateError",
  "UpdateTextLinkifyRulePayload",
  "UpdateTicketPayload",
  "UpdateTradeInRequestPayload",
  "UpdateTriggerPayload",
  "UpdateUpsRateCardRevisionEnvelopeAlreadyExistsError",
  "UpdateUpsRateCardRevisionInvalidRoundingPrecisionError",
  "UpdateUpsRateCardRevisionLineItemWeightOverlapError",
  "UpdateUpsRateCardRevisionNoLineItemFoundError",
  "UpdateUpsRateCardRevisionNotInDraftStateError",
  "UpdateUpsRateCardRevisionOverlappedEffectiveDateRangeError",
  "UpdateUpsRateCardRevisionPayload",
  "UpdateUpsSurchargeRevisionInvalidRateValueError",
  "UpdateUpsSurchargeRevisionInvalidZipCodeNumberError",
  "UpdateUpsSurchargeRevisionNotInDraftStateError",
  "UpdateUpsSurchargeRevisionOverlappedEffectiveDateRangeError",
  "UpdateUpsSurchargeRevisionPayload",
  "UpdateUpsZoneLocatorRevisionLineItemZipCodeOverlapError",
  "UpdateUpsZoneLocatorRevisionNoLineItemFoundError",
  "UpdateUpsZoneLocatorRevisionNotInDraftStateError",
  "UpdateUpsZoneLocatorRevisionPayload",
  "UpdateUserNeedAttributesPayload",
  "UpdateUserProfileAttributesPayload",
  "UpdateUserProfilePayload",
  "UpdateUserSourcePayload",
  "UpdateUserSourceToReferralResult",
  "UpdateVendorAgreementRevisionPayload",
  "UpdateVideoContentRevisionPayload",
  "UpdateVirtualCategoryAlreadyExistError",
  "UpdateVirtualCategoryDuplicatePluralDisplayNameError",
  "UpdateVirtualCategoryDuplicateSingularDisplayNameError",
  "UpdateVirtualCategoryDuplicateSlugError",
  "UpdateVirtualCategoryParentNodeNotFoundError",
  "UpdateVirtualCategoryPayload",
  "UpdateVirtualCategoryTreeRevisionCannotMoveError",
  "UpdateVirtualCategoryTreeRevisionNotInDraftStateError",
  "UpdateVirtualCategoryTreeRevisionPayload",
  "UpdateWarrantyClaimRequestPayload",
  "UpdateWishlistPayload",
  "UpdateWishlistShippingAddressPayload",
  "UpdatedCuratedItemsRichCard",
  "UpdatedFulfillmentRequestPayload",
  "UploadBrandsPayload",
  "UploadCampaignAudiencePayload",
  "UploadConnectPartnerTransactionPayload",
  "UploadConnectPartnerTransactionRefundPayload",
  "UploadExpertDiscountCashPayload",
  "UploadExpertRankOverridePayload",
  "UploadFeaturedSellableContentPayload",
  "UploadFulfillmentCostVerificationPayload",
  "UploadGigRequestsPayload",
  "UploadInvoicedShippingCostPayload",
  "UploadManualGLAdjustmentsPayload",
  "UploadMediaNoFileError",
  "UploadMediaPayload",
  "UploadMerchantsPayload",
  "UploadPayableActivityEntryPayload",
  "UploadProductCostCorrectionPayload",
  "UploadSearchQueriesPayload",
  "UploadSellableDeactivationsPayload",
  "UploadSellableExpertPerformanceSettingPayload",
  "UploadSellableInventoryPayload",
  "UploadSellablePricePayload",
  "UploadSellableUpdatesPayload",
  "UploadSellablesPayload",
  "UpsAccessPointAvailability",
  "UpsAccessPointAvailabilityDetails",
  "UpsAccessPointLocationInformation",
  "UpsAccessPointStatus",
  "UpsAccessPointUnavailableReason",
  "UpsAccessorial",
  "UpsAddressKeyFormat",
  "UpsAlert",
  "UpsAlertDetail",
  "UpsAvailableLocationAttributes",
  "UpsBusinessClassification",
  "UpsBusinessClassificationList",
  "UpsDateTime",
  "UpsDayOfWeekStandardHours",
  "UpsDirectToMobileAddress",
  "UpsDirectToMobileCod",
  "UpsDirectToMobileDimensions",
  "UpsDirectToMobileDropOffDocument",
  "UpsDirectToMobileMobileInfo",
  "UpsDirectToMobileReferenceNumbers",
  "UpsDirectToMobileResponse",
  "UpsDirectToMobileWeight",
  "UpsDirectToMobileWeightSection",
  "UpsDisclaimer",
  "UpsDistance",
  "UpsDistanceUnitOfMeasurement",
  "UpsDropLocation",
  "UpsElementIdentifier",
  "UpsElementLevelInformation",
  "UpsEstimatedArrival",
  "UpsGeoCode",
  "UpsGeoCodeCandidate",
  "UpsIVR",
  "UpsImageFormat",
  "UpsItemizedCharge",
  "UpsLocalizedInstruction",
  "UpsLocationAttributeOptionCode",
  "UpsLocationAttributeOptionType",
  "UpsLocationSortCode",
  "UpsMonetaryAmount",
  "UpsNegotiatedCharges",
  "UpsNegotiatedRateCharges",
  "UpsOperatingHours",
  "UpsPackageRate",
  "UpsPickup",
  "UpsPickupDetails",
  "UpsPrivateNetwork",
  "UpsPrivateNetworkList",
  "UpsPromotionInformation",
  "UpsRateAndTransitTimesPayload",
  "UpsRateCardLineItem",
  "UpsRateCardRevisionEditInvalidEffectiveTimeError",
  "UpsRateCardRevisionEditInvalidWeightError",
  "UpsRateCardRevisionPagination",
  "UpsRateResponse",
  "UpsRateResponseContent",
  "UpsRatedModifier",
  "UpsRatedPackage",
  "UpsRatedShipment",
  "UpsResponseStatus",
  "UpsSearchLocationApiErrorError",
  "UpsSearchLocationBadRequestError",
  "UpsSearchLocationFailureError",
  "UpsSearchLocationResponse",
  "UpsSearchLocationResult",
  "UpsSearchLocationServiceUnavailableError",
  "UpsSearchLocationUnauthorizedError",
  "UpsServiceCode",
  "UpsServiceContainer",
  "UpsServiceOffering",
  "UpsServiceOfferingList",
  "UpsServiceShipmentRateResult",
  "UpsServiceSummary",
  "UpsServiceZone",
  "UpsShipmentCharges",
  "UpsShipmentPackageResults",
  "UpsShipmentResults",
  "UpsShippingLabel",
  "UpsSpecialInstruction",
  "UpsStandardHours",
  "UpsSurchargeRateDetails",
  "UpsSurchargeRevisionEditInvalidEffectiveTimeError",
  "UpsSurchargeRevisionPagination",
  "UpsTaxCharge",
  "UpsTimeInTransit",
  "UpsTransitTimeDetail",
  "UpsTransportationPickupSchedule",
  "UpsUnitOfMeasurement",
  "UpsWeight",
  "UpsZoneLocatorLineItem",
  "UpsZoneLocatorRevisionEditInvalidEffectiveTimeError",
  "UpsZoneLocatorRevisionEditInvalidZipCodeNumberError",
  "UpsZoneLocatorRevisionEditZipCodeOverlapError",
  "UpsZoneLocatorRevisionPagination",
  "UpsZoneRate",
  "UpsertActionIntoPromotionPayload",
  "UpsertCategoryInventoryPropertiesMongoUpsertError",
  "UpsertCategoryInventoryPropertiesPayload",
  "UpsertEnumEntriesIntoAttributeSchemaRevisionPayload",
  "UpsertExpertCuratedLeadItineraryPayload",
  "UpsertItemsInExpertCuratedOrderPayload",
  "UpsertLegsIntoFlightPlanPayload",
  "UpsertMetadataIntoAttributeSchemaRevisionPayload",
  "UpsertProductInventoryPropertiesMongoUpsertError",
  "UpsertProductInventoryPropertiesPayload",
  "UpsertRuleIntoPromotionPayload",
  "UpsertSellableLMRatingPayload",
  "UpsertSimpleTagsForFeedPostPayload",
  "UpsertValidatorIntoAttributeSchemaRevisionPayload",
  "UsageLimitExceededRewardCreditClaimError",
  "UsagesPresentArchivePathSchemaRevisionError",
  "UserCartChangedPayload",
  "UserCreationFailedCreatePartnerUserError",
  "UserEmailNotFoundCreatePartnerUserError",
  "UserExistsCreateExpertAffiliateOutreachError",
  "UserJourneyLeadStage",
  "UserJourneyOrderStage",
  "UserLocation",
  "UserMilestoneFinishRedirectPathNodeFlowViewModelSuccess",
  "UserNeedArchivedChildCategoryNeedExists",
  "UserNeedArchivedExpired",
  "UserNeedArchivedNoLongerNeeded",
  "UserNeedArchivedNotReadyToBuy",
  "UserNeedArchivedPurchasedOnCurated",
  "UserNeedArchivedPurchasedSomewhereElse",
  "UserNeedArchivedSplitIntoSeparateNeeds",
  "UserNeedNote",
  "UserNeedOrArchiveError",
  "UserNeedOrCreateError",
  "UserNeedOrEditAttributeError",
  "UserProfileAggregatedAttributesView",
  "UserProfileOrEditAttributeError",
  "UserRegistrationPathNodeConfig",
  "UserScenario",
  "UserSource",
  "UserSourceAttribute",
  "UserUsageLimitNotEligibleForPromotionReason",
  "UserWeeklyMaxShift",
  "UsersPagination",
  "ValidatePageTemplatePayload",
  "ValidateSearchQueryRewriteRuleMappingsPayload",
  "ValidateSmsPromotionRegistrationPayload",
  "ValidateTriggerPayload",
  "ValidationErrorCreateCategoryAssetRevisionError",
  "ValidationErrorUpdateCategoryAssetRevisionError",
  "VariationDimensionSellableRemoveAttributeError",
  "VariationMatrix",
  "VariationMatrixDimension",
  "VariationMatrixPriceRange",
  "VariationMatrixVector",
  "VendorAgreementRevisionArchived",
  "VendorAgreementRevisionHistory",
  "VendorAgreementRevisionPendingSignature",
  "VendorAgreementRevisionPublished",
  "VendorChargeBackManual",
  "VendorGuide",
  "VendorTerms",
  "VerifyHandleChallengePayload",
  "VerifyPaymentTransactionPayload",
  "VerifyPendingAccessContactPayload",
  "VerifyResetPasswordChallengePayload",
  "VersusRequestDetail",
  "VersusSubmissionRevisionDetail",
  "VideoContentRevisionPagination",
  "VideoGalleryPayload",
  "VideoMediaUploadFormatPathQuestionPathNodeFlowViewModel",
  "VideoStreamMediaFileMetadata",
  "VideoTranscriptScoringTableEntry",
  "VideoUrlDuplicatedProductComparisonVideoError",
  "VideoUrlIsNotSharedProductComparisonVideoError",
  "VirtualCategoryTreeRevisionPagination",
  "VirtualCategoryTypeAheadPayload",
  "VirtualCategoryTypeAheadResultInEditMode",
  "VisitPathGraphFlowPayload",
  "WakeupLeadsSnoozePayload",
  "Ware2GoOrderInfo",
  "WarehouseInventoryItem",
  "WarehouseOrderLineReceiptCOGS",
  "WarehouseOrderPagination",
  "WarehouseOrderShipment",
  "WarrantyAlreadyInCartAddWarrantyToOrderError",
  "WarrantyClaimRequestItem",
  "WarrantyPolicy",
  "WeeklyLimitExceededGigClaimError",
  "WireTransferPaymentEntity",
  "WireTransferPaymentEntityBeneficiaryAddress",
  "WishlistItemBundleComponent",
  "WishlistItemGiftCardDetail",
  "WishlistItemPagination",
  "WishlistItemProductBundleDetail",
  "WishlistItemPurchase",
  "WishlistItemSellableDetail",
  "WishlistPagination",
  "WishlistProductBundleSellableSelection",
  "WithdrawApplicationPayload",
  "WithdrawGigApplicationPayload",
  "WithdrawGigSubmissionRevisionPayload",
  "WithdrawInfluenceEarningPayload",
  "WrongEntityTypeCheckExerciseStatusError",
  "WrongRoleExpertHiringClassAddApplicationError",
  "WrongTypeUpdateGigApplicationError",
  "YearMonth",
  "YouTubeVideo",
  "YouTubeVideoEmbedMediaDetail",
  "YouTubeVideoExternalVideoDetail"
]